import { useBoundStore } from 'useStore';
import { useUsersData } from './Queries';
import FilterChip from '../../Main/Toolbar/FilterChip';

export default function OwnerFilterChip() {
	const selectedOwner = useBoundStore((state) => state.forecastSelectedOwner);
	const setSelectedOwner = useBoundStore(
		(state) => state.setForecastSelectedOwner
	);
	const usersQuery = useUsersData();
	const owner = usersQuery.data?.find((m) => m.id === selectedOwner);

	if (selectedOwner === 0) return null;

	return (
		<FilterChip
			filterName="Owner"
			title={`${owner?.name} ${owner?.lastname}`}
			onDelete={() => setSelectedOwner(0)}
		/>
	);
}
