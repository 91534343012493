// import { PDFDocument } from 'pdf-lib';

export async function sendZPLtoZebraPrinter({ ZPLCommand, fileName }) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "ZPLCommand": ZPLCommand,
      "fileName": fileName
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    const response = await (await fetch("http://localhost:7625/Printer/PrintZPLInZebra", requestOptions)).json()
    return response;
  } catch (error) {
    return { isError: true, errorMessage: "Ocurrió un error al intentar conectar con el servicio de impresión local, por favor verificar que el servicio ZPLApi está corriendo en el equipo.", isPrinted: false }
  }
}