import { Skeleton, Stack } from '@mui/material';
import FlowTable from './FlowTable';

import { useForecastFlow } from 'contexts/ForecastFlowContext';
import CreateFlowSection from './CreateFlowSection';
import { useEffect } from 'react';

export default function FlowTab() {
	const { data, loading, fetchForecastFlowData } = useForecastFlow();

	useEffect(() => {
		fetchForecastFlowData();
	}, [fetchForecastFlowData]);

	return loading && !data ? (
		<Stack spacing={4}>
			<Stack
				spacing={4}
				direction="row"
				sx={{
					overflowX: 'auto',
				}}
			>
				<Skeleton
					variant="rectangular"
					width="50%"
					height={150}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width="50%"
					height={150}
					sx={{
						borderRadius: '5px',
					}}
				/>
			</Stack>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={'calc(100vh - 350px)'}
				sx={{
					borderRadius: '5px',
				}}
			/>
		</Stack>
	) : (
		<Stack>
			<CreateFlowSection />
			{data && <FlowTable data={data} loading={loading} />}
		</Stack>
	);
}
