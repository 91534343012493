// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import jwt_decode from "jwt-decode";


// ----------------------------------------------------------------------

const initialState = {
    error: null,

    //hay que declarar el estado inicial
    permisos: [],
    ingresoAutorizado: [],
    sourcingAutorizado: [],
    inboundAutorizado:[],
    tarifonAutorizado:[],
    masterTablesAutorizado:[],
    masterTablesL1Autorizado:[],
    masterTablesNCM:[],
};

const slice = createSlice({
    name: 'permisos',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET Inbound
        getPermisosSuccess(state, action) {
            state.permisos = action.payload;
        },

        getIngresoAutorizadoSuccess(state, action) {
            state.ingresoAutorizado = action.payload;
        },

        getSourcingAutorizado(state, action) {
            state.sourcingAutorizado = action.payload;
        },

        getInboundAutorizado(state, action) {
            state.inboundAutorizado = action.payload;
        },

        getTarifonAutorizado(state, action) {
            state.tarifonAutorizado = action.payload;
        }, 

        getmasterTablesAutorizado(state, action) {
            state.masterTablesAutorizado = action.payload;
        }, 

        getmasterTables_L1Autorizado(state, action) {
            state.masterTablesL1Autorizado = action.payload;
        },

        getmasterTables_NCM(state, action) {
            state.masterTablesNCM = action.payload;
        },
        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getPermisos(accessToken) {
    return async (dispatch) => {
        try {
            const response = jwt_decode(accessToken);
            const permisos = response.permissions;
            dispatch(slice.actions.getPermisosSuccess(permisos));
            console.log('Get Permisos :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizado() {
    return async (dispatch) => {
        try {
            const response = ['CEO', 'Gerencia', 'Lider', 'Sourcing', 'Comex','Operario'];
            dispatch(slice.actions.getIngresoAutorizadoSuccess(response));
            console.log('Get IngresoAutorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizadoSourcing() {
    return async (dispatch) => {
        try {
            const response = ['Sourcing'];
            dispatch(slice.actions.getSourcingAutorizado(response));
            console.log('Get Sourcing Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizadoInbound() {
    return async (dispatch) => {
        try {
            const response = ['Comex'];
            dispatch(slice.actions.getInboundAutorizado(response));
            console.log('Get Inbond Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizadoTarifon() {
    return async (dispatch) => {
        try {
            const response = ['tarifas:read'];
            dispatch(slice.actions.getTarifonAutorizado(response));
            console.log('Get Tarifon Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizadoMasterTables() {
    return async (dispatch) => {
        try {
            const response = ['abm:read'];
            dispatch(slice.actions.getmasterTablesAutorizado(response));
            console.log('Get MasterTables Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizadoMasterTables_L1() {
    return async (dispatch) => {
        try {
            const response = ['abm:read', 'abmL1:read'];
            dispatch(slice.actions.getmasterTables_L1Autorizado(response));
            console.log('Get MasterTables L1 Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getIngresoAutorizado_NCM() {
    return async (dispatch) => {
        try {
            const response = ['ncm:read'];
            dispatch(slice.actions.getmasterTables_NCM(response));
            console.log('Get NCM Autorizado :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};




