import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import HistoryTable from './HistoryTable';

import ErrorPage from 'views/pages/maintenance/ErrorPage';
import { useForecastHistoryData } from './Queries';
import Toolbar from './Toolbar';

export default function HistoryView() {
	const forecastHistoryQuery = useForecastHistoryData();

	if (forecastHistoryQuery.status === 'pending') {
		return <Skeletons />;
	}

	if (forecastHistoryQuery.status === 'error') {
		return <ErrorPage />;
	}

	return (
		forecastHistoryQuery.data && (
			<Stack spacing="24px" sx={{ pt: '12px' }}>
				<Toolbar count={forecastHistoryQuery.data?.count} />

				<Box>
					{forecastHistoryQuery.data?.items &&
						forecastHistoryQuery.data?.count >= 0 && (
							<HistoryTable
								data={forecastHistoryQuery.data?.items}
								count={forecastHistoryQuery.data?.count}
								loading={
									forecastHistoryQuery.status === 'pending' ||
									forecastHistoryQuery.fetchStatus === 'fetching'
								}
							/>
						)}
				</Box>
			</Stack>
		)
	);
}

function Skeletons() {
	return (
		<Stack spacing={4}>
			<Stack
				spacing={4}
				direction="row"
				sx={{
					overflowX: 'auto',
				}}
			>
				<Skeleton
					variant="rectangular"
					width={150}
					height={40}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={40}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={40}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={40}
					sx={{
						borderRadius: '5px',
					}}
				/>
			</Stack>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={'calc(100vh - 312px)'}
				sx={{
					borderRadius: '5px',
				}}
			/>
		</Stack>
	);
}
