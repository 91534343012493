// LISTED 21_7_2023 12:30

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconApps, IconBasket, IconCalendar, IconLayoutKanban, IconMail, IconMessages, IconNfc, IconUserCheck } from '@tabler/icons';

// importacion de iconos adicionales de iconos

import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';


//import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="COSTOS" />,
    icon: icons.IconApps,
    type: 'group',
    children: [

        //MEXICO
        // SOUCING
        {
            id: 'simulador',
            title: <FormattedMessage id="sourcing" />,
            // type: 'item',
            type: 'collapse',
            // url:  '/simulador/simulador',
            icon: SummarizeOutlinedIcon,
            children: [
                // tabla COMEXFWD
                {
                    id: 'simuladorPO',
                    title: <FormattedMessage id="simuladorPO"/>,
                    type: 'item',
                    // icon: DirectionsBoatFilledOutlinedIcon,
                    url:  '/simulador/PurcheOrder',
                    breadcrumbs: false                  
                },
                {
                    id: 'simuladorBC',
                    title: <FormattedMessage id="simuladorBC"/>,
                    type: 'item',
                    // icon: DirectionsBoatFilledOutlinedIcon,
                    url:  '/simulador/BusinessCase',
                    breadcrumbs: false                  
                },
                

                
            ]
        },
        {
            id: 'ESTIMADOR',
            title: <FormattedMessage id="inboundMex" />,
            // url: '/inbound/inbound',
            type: 'collapse',
            // url: '/UnderConstruction',  // RUTA QUE DESVIA PARA MOSTRAR ALERTA DE CONSTRUCCION
            icon: SummarizeOutlinedIcon,
            breadcrumbs: false,
            children:[
                {
                    id: 'estimate-list',
                    title: <FormattedMessage id="estimate-list" />,
                    type: 'item',
                    icon: DirectionsBoatFilledOutlinedIcon,
                    // url: '/UnderConstruction',  // RUTA QUE DESVIA PARA MOSTRAR ALERTA DE CONSTRUCCION
                    url: '/inbound/inbound',
                    breadcrumbs: false
                },

                {
                    id: 'estimate-list-VS',
                    title: <FormattedMessage id="estimate-list-VS" />,
                    type: 'item',
                    icon: DirectionsBoatFilledOutlinedIcon,
                    // url: '/UnderConstruction',  // RUTA QUE DESVIA PARA MOSTRAR ALERTA DE CONSTRUCCION
                    url: '/inboundVS/inboundVS',
                    breadcrumbs: false
                },

            ]
        },
    ]
};

export default application;