import { Box } from '@mui/material';

export default function LabelTag({ label }) {
	const colors =
		label === 'npi'
			? {
					backgroundColor: '#35488733',
					color: '#354887',
			  }
			: label === 'ini'
			? {
					backgroundColor: '#2E7D3233',
					color: '#1B5E20',
			  }
			: label === 'maturity'
			? {
					backgroundColor: '#EF6C004D',
					color: '#C15C08',
			  }
			: {
					backgroundColor: '#D32F2F4D',
					color: '#C62828',
			  };

	return (
		<Box
			sx={{
				padding: '0px 4px',
				borderRadius: '4px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				fontSize: '11px',
				textTransform: 'uppercase',
				fontWeight: 400,
				lineHeight: '16px',
				letterSpacing: '0.17px',
				...colors,
			}}
		>
			{label}
		</Box>
	);
}
