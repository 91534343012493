import { Box, Typography } from '@mui/material';

export default function EventTag({ event }) {
	const color =
		event?.season === 't1'
			? { text: '#FFFFFF', background: '#2C3F7D', circle: '#8893B8' }
			: { text: '#354887', background: '#EBEBEB', circle: '#354887' };

	return (
		<Box
			sx={{
				borderRadius: '16px',
				padding: '2px 6px',
				display: 'flex',
				alignItems: 'center',
				gap: '4px',
				backgroundColor: color.background,
				width: 'fit-content',
			}}
		>
			<Box
				sx={{
					width: '15px',
					height: '15px',
					borderRadius: '50%',
					color: 'white',
					backgroundColor: color.circle,
					fontSize: '9px',
					fontWeight: 600,
					fontFamily: 'Roboto',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{event?.season?.toUpperCase()}
			</Box>
			<Typography
				sx={{
					fontSize: '11px',
					fontWeight: 400,
					fontFamily: 'Roboto',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					textTransform: 'capitalize',
					color: color.text,
				}}
			>
				{event?.name}
			</Typography>
		</Box>
	);
}
