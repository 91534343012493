import { Stack } from '@mui/material';
import HistoryFiltersOptions from './HistoryFiltersOptions';
import HistoryCount from './HistoryCount';
import HistoryDatePicker from './HistoryDatePicker';
import HistorySearchBar from './HistorySearchBar';

export default function HistoryTableFilters({ count }) {
	return (
		<Stack
			direction="row"
			gap={2}
			alignItems="center"
			flexWrap="wrap"
			sx={{
				padding: '0 24px',
			}}
		>
			{count && <HistoryCount count={count} />}

			<HistoryFiltersOptions />

			<HistoryDatePicker />

			<HistorySearchBar />
		</Stack>
	);
}
