import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import InventoryTab from '../Inventory/InventoryTab';
import EventsTab from '../Events/EventsTab';
import LifeCycleTab from '../LifeCycle/LifeCycleTab';
import EventsProvider from 'contexts/EventsContext';
import EventsFiltersProvider from 'contexts/EventsFiltersContext';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function SettingsMainComponent() {
	const [value, setValue] = useState(1);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					variant="fullWidth"
				>
					<Tab label="INVENTARIO" {...a11yProps(0)} />
					<Tab label="EVENTOS" {...a11yProps(1)} />
					<Tab label="CICLO DE VIDA" {...a11yProps(2)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<InventoryTab />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<EventsProvider>
					<EventsFiltersProvider>
						<EventsTab />
					</EventsFiltersProvider>
				</EventsProvider>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				<LifeCycleTab />
			</CustomTabPanel>
		</Box>
	);
}
