import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, Box, Stack } from '@mui/material';
import EventTag from './EventTag';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		placement="bottom-start"
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		maxWidth: '400px',

		borderRadius: '0px',
	},
}));

function EventsCard({ events }) {
	return (
		<Stack>
			{events.map((event) => (
				<EventTag key={event.name} event={event} />
			))}
		</Stack>
	);
}

export default function MonthEvents({ events }) {
	if (events.length > 1) {
		return (
			<LightTooltip
				title={<EventsCard events={events.slice(1)} />}
				placement="top"
			>
				<Box>
					<EventTag event={events[0]} />
				</Box>
			</LightTooltip>
		);
	}

	return <EventTag event={events[0]} />;
}
