import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

const fetchUsers = async (accessToken) => {
	const res = await axios.get(`${process.env.REACT_APP_API_URL}/Maestro/user`, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			accept: '*/*',
		},
	});

	return res;
};

export default function useUsersData() {
	const { accessToken } = useAuth();

	const usersQuery = useQuery({
		queryKey: ['users'],
		queryFn: () => fetchUsers(accessToken),
		select: (data) => {
			return data?.data;
		},
		staleTime: Infinity,
		retry: false,
	});

	return usersQuery;
}
