// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Stack, Typography, useMediaQuery } from '@mui/material';
// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import CountrySection from './MainCountry';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import GlobalQuote from './GlobalQuote';
import { useEffect } from 'react';
import { useGlobalQuote } from 'contexts/GlobalQuoteContext';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
	const theme = useTheme();
	const { getQuote } = useGlobalQuote();
	const { user } = useAuth();

	const STAGE = process.env.REACT_APP_INSTANCIA;
	// console.log(STAGE);
	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const { layout } = useConfig();

	useEffect(() => {
		getQuote();
	}, [getQuote]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				width: '100%',
				backgroundColor: 'white',
			}}
		>
			{/* logo & toggler button */}
			<Stack
				direction="row"
				alignItems="center"
				gap={3}
				sx={{
					width: 'fit-content',
				}}
			>
				<Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
					<LogoSection />
				</Box>

				<Avatar
					variant="rounded"
					sx={{
						...theme.typography.commonAvatar,
						...theme.typography.mediumAvatar,
						overflow: 'hidden',
						transition: 'all .2s ease-in-out',
						background:
							theme.palette.mode === 'dark'
								? theme.palette.dark.main
								: theme.palette.secondary.light,
						color:
							theme.palette.mode === 'dark'
								? theme.palette.secondary.main
								: theme.palette.secondary.dark,
						'&:hover': {
							background:
								theme.palette.mode === 'dark'
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
							color:
								theme.palette.mode === 'dark'
									? theme.palette.secondary.light
									: theme.palette.secondary.light,
						},
					}}
					onClick={() => dispatch(openDrawer(!drawerOpen))}
					color="inherit"
				>
					<IconMenu2 stroke={1.5} size="20px" />
				</Avatar>
			</Stack>

			{/* header search */}
			{/* <SearchSection /> */}
			{/* <Box sx={{ flexGrow: 1 }} /> */}
			{/* <Box sx={{ flexGrow: 1 }} /> */}

			{/* mega-menu */}
			{/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

			<Typography
				variant="h1"
				sx={{
					color: process.env.REACT_APP_INSTANCIA === 'STAGE' ? 'red' : 'white',
					textAlign: 'center',
					fontSize: {
						xs: '16px',
						sm: '20px',
						md: '28px',
					},
				}}
			>
				{process.env.REACT_APP_INSTANCIA === 'STAGE' ? 'STAGE' : 'PROD'}
			</Typography>

			<Stack direction="row" alignItems="center" spacing={2}>
				{user?.permissions?.includes('Global:Cotizacion') && <GlobalQuote />}

				{/* MAIN COUNTRY */}
				<CountrySection />

				{/* live customization & localization */}
				{/* <Box sx={{ display: { xs: "none", sm: "block", border: "0px solid red"}}} className="localizationSection">
        <LocalizationSection />
      </Box> */}

				{/* notification & profile */}
				{/* <NotificationSection /> */}
				<ProfileSection />
			</Stack>

			{/* mobile header */}
			{/* <Box
				sx={{ display: { xs: 'block', sm: 'none' } }}
				className="languageButton"
			>
				<MobileSection />
			</Box> */}
		</Stack>
	);
};

export default Header;
