import { Box, Tab, Tabs } from '@mui/material';
import HistoryView from './History/HistoryView';
import { useState } from 'react';

export default function ForecastAdminPanel() {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: '#FFFFFF',
				padding: '20px',
				borderRadius: '5px',
			}}
		>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					variant="fullWidth"
				>
					<Tab label="CAMBIOS GENERALES" {...a11yProps(0)} />
					<Tab label="ÚLTIMOS CAMBIOS" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<HistoryView />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<HistoryView />
			</CustomTabPanel>
		</Box>
	);
}

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
