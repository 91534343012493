// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { sendZPLtoZebraPrinter } from 'views/Components/PickingNPacking/Packing/Printer';

// ----------------------------------------------------------------------

const initialState = {
	error: null,
	salesOrders: [],
	salesOrdersTotal: 0,
	salesOrdersCross: 0,
	salesOrdersSelf: 0,
	salesOrdersFinished: [],
	salesOrdersFinishedTotal: 0,
	salesOrdersFinishedCross: 0,
	salesOrdersFinishedSelf: 0,
	assignments: [],
	assignmentStatus: [],
	shippingCompanies: [],
	totalAssignments: 0,
	assignmentsByID: [],
	picking: [],
	pickingByID: [],
	updateTable: 0,
	done: [],
};

const slice = createSlice({
	name: 'pickingAndPaking',
	initialState,
	reducers: {
		// HAS ERROR
		hasError(state, action) {
			state.error = action.payload;
		},

		updateTable(state, action) {
			state.updateTable = action
		},

		// Get Pedidos
		GetSalesOrders(state, action) {
			state.salesOrders = action.payload.items;
			state.salesOrdersTotal = action.payload.totalSalesOrders;
			state.salesOrdersCross = action.payload.salesOrdersCross;
			state.salesOrdersSelf = action.payload.salesOrdersSelf;
		},

		// Get Pedidos
		GetFinishedSalesOrders(state, action) {
			state.salesOrdersFinished = action.payload.items;
			state.salesOrdersFinishedTotal = action.payload.totalSalesOrders;
			state.salesOrdersFinishedCross = action.payload.salesOrdersCross;
			state.salesOrdersFinishedSelf = action.payload.salesOrdersSelf;
		},

		// GET ASIGNACIONES STATES
		getAssignmentStatus(state, action) {
			state.assignmentStatus = action.payload;
		},

		// GET ASIGNACIONES STATES
		getShippingCompanies(state, action) {
			// console.log(action.payload)
			state.shippingCompanies = action.payload;
		},

		// GET ASIGNACIONES
		getAssignments(state, action) {
			state.assignments = action.payload.items;
			state.totalAssignments = action.payload.totalAssignments;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function updateUnAssignedSO(jsonData) {

	dispatch(
		slice.actions.GetSalesOrders({
			items: jsonData.items,
			totalSalesOrders: jsonData.totalCount,
		})
	);
}

export function updateTables() {
	return async () => {
		dispatch(
			slice.actions.updateTable(Math.random())
		);
	};
}


export async function getFinishedSalesOrders(
	accessToken,
	orderId,
	limit = 50,
	offset = 0,
	search = null,
	country = null,
	dataSet = 0,
	carrier = 0,
	searchDate = null
) {
	try {
		let countryVal = (country == "ARGENTINA" ? "AR" : (country == "BRASIL" ? "BR" : (country == "MEXICO" ? "MX" : (country == "COLOMBIA" ? "CO" : "AR"))))
		let url = `/PickinNpacking/GetFinishedOrders`;

		if (
			(orderId !== null && orderId !== '') ||
			(limit !== null && limit !== '') ||
			(offset !== null && offset !== '') ||
			(search !== null && search !== '') ||
			(country !== null && country !== '') ||
			(dataSet !== null && dataSet !== '') ||
			(carrier !== null && carrier !== '') ||
			(searchDate !== null && searchDate !== '')
		) {
			url += '?';
		}

		// Crear un objeto de parámetros basado en los valores proporcionados
		if (orderId !== null && orderId !== '') url += `&orderid=${orderId}`;
		if (limit !== null && limit !== '') url += `&Limit=${limit}`;
		if (offset !== null && offset !== '') url += `&OffSet=${offset}`;
		if (search !== null && search !== '') url += `&search=${search}`;
		if (country !== null && country !== '') url += `&countryName=${countryVal}`;
		if (dataSet !== null && dataSet !== '') url += `&dataSet=${dataSet}`;
		if (carrier !== null && carrier !== '') url += `&carrier=${carrier}`;
		if (searchDate !== null && searchDate !== '') url += `&lastUpdate=${searchDate}`;

		//   const response = await fetch(`${this.baseUrl}/${this.rutaTabla}`, {
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.status != 200) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const jsonData = await response.data;
		dispatch(
			slice.actions.GetFinishedSalesOrders({
				items: jsonData.items,
				totalSalesOrders: jsonData.totalCount,
				salesOrdersCross: jsonData.totalCountCross,
				salesOrdersSelf: jsonData.totalCountSelf,
			})
		);
	} catch (error) {
		// console.log('error', error);
		dispatch(slice.actions.hasError(error));
	}
}

export async function getUnAssignedSalesOrders(
	accessToken,
	orderId,
	limit = 50,
	offset = 0,
	search = null,
	country = null,
	shippingMethod = null,
	cached = true,
	status = 0
) {
	try {
		let countryVal = (country == "ARGENTINA" ? "AR" : (country == "BRASIL" ? "BR" : (country == "MEXICO" ? "MX" : (country == "COLOMBIA" ? "CO" : "AR"))))

		let url = `/PickinNpacking/GetUnAssignedOrders`;

		if (
			(orderId !== null && orderId !== '') ||
			(limit !== null && limit !== '') ||
			(offset !== null && offset !== '') ||
			(search !== null && search !== '') ||
			(country !== null && country !== '') ||
			(shippingMethod !== null && shippingMethod !== '')
				(cached !== null && cached !== '')
				(status !== null && status !== '')
		) {
			url += '?';
		}

		// Crear un objeto de parámetros basado en los valores proporcionados
		if (orderId !== null && orderId !== '') url += `&orderid=${orderId}`;
		if (limit !== null && limit !== '') url += `&Limit=${limit}`;
		if (offset !== null && offset !== '') url += `&OffSet=${offset}`;
		if (search !== null && search !== '') url += `&search=${search}`;
		if (country !== null && country !== '') url += `&countryName=${countryVal}`;
		if (shippingMethod !== null && shippingMethod !== '') url += `&shippingMethod=${shippingMethod}`;
		if (cached !== null && cached !== '') url += `&cached=${cached}`;
		if (status !== null && status !== '') url += `&status=${status}`;

		//   const response = await fetch(`${this.baseUrl}/${this.rutaTabla}`, {
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.status != 200) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const jsonData = await response.data;

		dispatch(
			slice.actions.GetSalesOrders({
				items: jsonData.items,
				totalSalesOrders: jsonData.totalCount,
				salesOrdersCross: jsonData.totalCountCross,
				salesOrdersSelf: jsonData.totalCountSelf,
			})
		);
	} catch (error) {
		// console.log('error', error);
		dispatch(slice.actions.hasError(error));
	}
}

export function getAssignments(
	accessToken,
	assignmentId = null,
	limit = 50,
	offset = 0,
	search = null,
	country = 7,
	userName = null
) {
	return async () => {
		try {

			let url = `/PickinNpacking/GetAssignments`;

			if (
				(assignmentId !== null && assignmentId !== '') ||
				(limit !== null && limit !== '') ||
				(offset !== null && offset !== '') ||
				(search !== null && search !== '') ||
				(country !== null && country !== '')
					(userName !== null && userName !== '')
			) {
				url += '?';
			}
			if (assignmentId !== null && assignmentId !== '') url += `&assignmentId=${assignmentId}`;
			if (limit !== null && limit !== '') url += `&Limit=${limit}`;
			if (offset !== null && offset !== '') url += `&OffSet=${offset}`;
			if (search !== null && search !== '') url += `&search=${search}`;
			if (country !== null && country !== '') url += `&country=${country}`;
			if (userName !== null && userName !== '') url += `&userName=${userName}`;

			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (response.status != 200) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const jsonData = await response.data;

			dispatch(
				slice.actions.getAssignments({
					items: jsonData.items,
					totalAssignments: jsonData.totalCount,
				})
			);
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getAssignmentsAdmin(
	accessToken,
	assignmentId = null,
	limit = 5,
	offset = 0,
	search = null,
	country = null,
	userName = null
) {
	return async () => {
		try {

			let url = `/PickinNpacking/GetAssignmentsAdmin`;

			if (
				(assignmentId !== null && assignmentId !== '') ||
				(limit !== null && limit !== '') ||
				(offset !== null && offset !== '') ||
				(search !== null && search !== '') ||
				(country !== null && country !== '')
					(userName !== null && userName !== '')
			) {
				url += '?';
			}
			if (assignmentId !== null && assignmentId !== '') url += `&assignmentId=${assignmentId}`;
			if (limit !== null && limit !== '') url += `&Limit=${limit}`;
			if (offset !== null && offset !== '') url += `&OffSet=${offset}`;
			if (search !== null && search !== '') url += `&search=${search}`;
			if (country !== null && country !== '') url += `&country=${country}`;
			if (userName !== null && userName !== '') url += `&userName=${userName}`;

			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (response.status != 200) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const jsonData = await response.data;

			dispatch(
				slice.actions.getAssignments({
					items: jsonData.items,
					totalAssignments: jsonData.totalCount,
				})
			);
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getAssignmentStatus(accessToken) {
	return async () => {
		try {
			const response = await axios.get(
				`/PickinNpacking/GetAssignmentStates`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
			);
			dispatch(slice.actions.getAssignmentStatus(response.items));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getShippingCompanies(accessToken, countryId = 7) {
	return async () => {
		try {
			const response = await axios.get(
				`/PickinNpacking/GetShippingCompanies?country=${countryId}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
			);
			dispatch(slice.actions.getShippingCompanies(response.data.items));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function setAssignment(data, accessToken, showmessage = null) {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				'/PickinNpacking/SetOrdersAssignment',
				data,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			if (showmessage != null) {
				showmessage(response.data.message, response.data.OK);
			}
			dispatch(slice.actions.getAssignments(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function patchAsignaciones(data, accessToken) {
	return async (dispatch) => {
		try {
			// console.log('Data desde redux :', data);
			const response = await axios.patch(
				'/PickinNpacking/UpdateOrdersAssignment',
				data,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export async function PrintOrderLabel(data, accessToken) {
	try {
		// console.log('Data desde redux :', data);
		const response = await axios.patch(
			'/PickinNpacking/PrintOrderLabel',
			data,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		if (response?.data?.error) {
			// console.log(response?.data?.trackDebug)
			return { isError: true, errorMessage: response?.data?.message, isPrinted: response?.data?.isPrinted }
		} else {
			return await sendZPLtoZebraPrinter(response.data)
		}
	} catch (error) {
		// dispatch(slice.actions.hasError(error));
		return { isError: true, errorMessage: "Ocurrió un error al intentar conectar con el servicio de impresión de Mercado Libre, por favor verificar que tienes conexión a internet.", isPrinted: false }
	}
}

export function cancelAssignment(data, accessToken, showmessage = null) {
	return async (dispatch) => {
		try {
			const response = await axios.patch(
				'/PickinNpacking/CancelOrdersAssignment',
				data,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			if (showmessage != null) {
				showmessage(response.data.message, response.data.OK);
			}
			return { error: false }
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			return { error: true }
		}
	};
}