import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import FlowTab from './Flow/FlowTab';
import HistoryTab from './History/HistoryTab';
import ForecastFlowProvider from 'contexts/ForecastFlowContext';
import ForecastHistoryFiltersProvider from 'contexts/ForecastHistoryFiltersContext';
import ForecastHistoryProvider from 'contexts/ForecastHistoryContext';
import ForecastAlertProvider from 'contexts/ForecastAlertContext';
import ForecastAlert from '../ForecastAlert';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function ForecastAdminPanel() {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<ForecastAlertProvider>
			<ForecastAlert />
			<Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						variant="fullWidth"
					>
						<Tab label="HISTORIAL DE CAMBIOS" {...a11yProps(0)} />
						<Tab label="FLUJO DE APROBACIONES" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
					<ForecastHistoryFiltersProvider>
						<ForecastHistoryProvider>
							<HistoryTab />
						</ForecastHistoryProvider>
					</ForecastHistoryFiltersProvider>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<ForecastFlowProvider>
						<FlowTab />
					</ForecastFlowProvider>
				</CustomTabPanel>
			</Box>
		</ForecastAlertProvider>
	);
}
