import { memo } from 'react';
import HistoryDatePicker from './HistoryDatePicker';
import HistoryFiltersOptions from './HistoryFiltersOptions';
import HistorySearchBar from './HistorySearchBar';

const HistoryTableFilters = memo(() => {
	return (
		<>
			<HistoryFiltersOptions />

			<HistoryDatePicker />

			<HistorySearchBar />
		</>
	);
});

export default HistoryTableFilters;
