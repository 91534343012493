import { Box } from '@mui/material';

export default function QuarterTag({ quarter }) {
	const colors =
		quarter === 'q1'
			? {
					backgroundColor: '#FEC53D4d',
					color: '#FEC53D',
			  }
			: quarter === 'q2'
			? {
					backgroundColor: '#55db984d',
					color: '#55db98',
			  }
			: quarter === 'q3'
			? {
					backgroundColor: '#ffa27e4d',
					color: '#ffa27e',
			  }
			: {
					backgroundColor: '#5773cd4d',
					color: '#5773cd',
			  };

	return (
		<Box
			sx={{
				width: '40px',
				height: '40px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '8px',
				textTransform: 'uppercase',
				fontSize: '16px',
				fontWeight: 600,
				fontFamily: 'Roboto',
				lineHeight: '24px',
				letterSpacing: '0.20px',
				...colors,
			}}
		>
			{quarter}
		</Box>
	);
}
