import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatearNumeroConComas } from './utils';

export default function ColumnTSU(props) {
	const { row, month } = props;
	const yoy = useMemo(() => {
		let ytoy = 0;
		const prevYear = row?.data ? row?.data[month]?.aproprev : null;
		if (prevYear) {
			let defaultData;
			if (props?.fromPending) {
				defaultData = row?.data[month]?.pend
					? row?.data[month]?.pend
					: row?.data[month]?.def;
			} else if (props?.fromReject) {
				defaultData = row?.data[month]?.reject
					? row?.data[month]?.reject
					: row?.data[month]?.def;
			} else {
				defaultData = row?.data[month]?.apro
					? row?.data[month]?.apro
					: row?.data[month]?.def;
			}

			ytoy = (defaultData.tsu - prevYear.tsu) / prevYear.tsu;
			return ytoy * 100;
		}

		return ytoy;
	}, [row, month, props?.fromPending, props?.fromReject]);

	return (
		<Stack
			spacing="4px"
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
			}}
		>
			<Typography>{props.value}</Typography>

			<Stack direction="row" spacing={1} alignItems="center">
				<Typography
					sx={{
						color: '#00000061',
						fontWeight: 400,
					}}
				>
					YoY
				</Typography>

				<Stack direction="row" alignItems="center">
					<Typography
						sx={{
							color: yoy >= 0 ? '#1B5E20' : '#D32F2F',
							fontWeight: 400,
						}}
					>
						{formatearNumeroConComas(yoy)}%
					</Typography>
					{yoy >= 0 ? (
						<ArrowUpwardIcon
							sx={{
								color: '#1B5E20',
								fontSize: '16px',
							}}
						/>
					) : (
						<ArrowDownwardIcon
							sx={{
								color: '#D32F2F',
								fontSize: '16px',
							}}
						/>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
}
