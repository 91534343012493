// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

const icons = {
	IconDashboard,
	IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Picking_and_packing = {
	id: 'PyP',
	title: <FormattedMessage id="pyp" />,
	icon: icons.IconDashboard,
	type: 'group',
	children: [
		{
			id: 'SalesOrders',
			title: <FormattedMessage id="SalesOrders" />,
			type: 'item',
			url: '/pyp/salesorders',
			icon: SalesOrderIcon,
			breadcrumbs: false,
		},
		{
			id: 'Shipping',
			title: <FormattedMessage id="Shipping" />,
			type: 'item',
			url: '/pyp/shipping',
			icon: ShippingIcon,
			breadcrumbs: false,
		},
	],
};

export default Picking_and_packing;

function SalesOrderIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>sales orders</title>
			<path
				d="M4.00005 20V7.1L2.05005 2.85L3.85005 2L6.20005 7.05H17.8L20.15 2L21.9501 2.85L20 7.1V20H4.00005ZM10 13H14C14.2834 13 14.521 12.904 14.713 12.712C14.905 12.52 15.0007 12.2827 15 12C14.9994 11.7173 14.9034 11.48 14.712 11.288C14.5207 11.096 14.2834 11 14 11H10C9.71672 11 9.47938 11.096 9.28805 11.288C9.09672 11.48 9.00072 11.7173 9.00005 12C8.99938 12.2827 9.09538 12.5203 9.28805 12.713C9.48072 12.9057 9.71805 13.0013 10 13Z"
				fill="currentColor"
			/>
		</svg>
	);
}
function ShippingIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>shipping</title>
			<path
				d="M6 20C5.16667 20 4.45833 19.7083 3.875 19.125C3.29167 18.5417 3 17.8333 3 17H1V6C1 5.45 1.196 4.97933 1.588 4.588C1.98 4.19667 2.45067 4.00067 3 4H17V8H20L23 12V17H21C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17H9C9 17.8333 8.70833 18.5417 8.125 19.125C7.54167 19.7083 6.83333 20 6 20ZM6 18C6.28333 18 6.521 17.904 6.713 17.712C6.905 17.52 7.00067 17.2827 7 17C6.99933 16.7173 6.90333 16.48 6.712 16.288C6.52067 16.096 6.28333 16 6 16C5.71667 16 5.47933 16.096 5.288 16.288C5.09667 16.48 5.00067 16.7173 5 17C4.99933 17.2827 5.09533 17.5203 5.288 17.713C5.48067 17.9057 5.718 18.0013 6 18ZM18 18C18.2833 18 18.521 17.904 18.713 17.712C18.905 17.52 19.0007 17.2827 19 17C18.9993 16.7173 18.9033 16.48 18.712 16.288C18.5207 16.096 18.2833 16 18 16C17.7167 16 17.4793 16.096 17.288 16.288C17.0967 16.48 17.0007 16.7173 17 17C16.9993 17.2827 17.0953 17.5203 17.288 17.713C17.4807 17.9057 17.718 18.0013 18 18ZM17 13H21.25L19 10H17V13Z"
				fill="currentColor"
			/>
		</svg>
	);
}
