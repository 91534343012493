import { useEffect, useState } from 'react';
import ErrorBoundary from 'views/Components/ErrorHandler/ErrorBoundary';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';

import ThemeCustomization from 'themes';

// auth provider
import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import { GlobalQuoteProvider } from 'contexts/GlobalQuoteContext';

// State management provider
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
		},
	},
});

// ==============================|| APP ||============================== //
// Comentario de pruebas
const App = () => {
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO); // aplicamos variable de entorno de MUI PRO
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// dispatch(getMenu()).then(() => {
		//     setLoading(true);
		// });

		setLoading(true);
	}, []);

	if (!loading) return <Loader />;

	return (
		<ErrorBoundary>
			<ThemeCustomization>
				<RTLLayout>
					<Locales>
						<NavigationScroll>
							<AuthProvider>
								<GlobalQuoteProvider>
									<QueryClientProvider client={queryClient}>
										<>
											<Routes />
											{/* SE HABILITA PARA QUE FUNCIONEN LOS SNACKBAR en la APP */}
											<Snackbar maxSnack={3} />
										</>
										{process.env.REACT_APP_INSTANCIA === 'STAGE' && (
											<ReactQueryDevtools initialIsOpen={false} />
										)}
									</QueryClientProvider>
								</GlobalQuoteProvider>
							</AuthProvider>
						</NavigationScroll>
					</Locales>
				</RTLLayout>
			</ThemeCustomization>
		</ErrorBoundary>
	);
};

export default App;
