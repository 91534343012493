import { Box, Skeleton, Stack } from '@mui/material';
import HistoryTable from './HistoryTable';

import { useForecastHistory } from 'contexts/ForecastHistoryContext';
import HistoryTableFilters from './HistoryTableFilters';

export default function HistoryTab() {
	const { data, count, loading } = useForecastHistory();

	return loading && !data ? (
		<Stack spacing={4}>
			<Stack
				spacing={4}
				direction="row"
				sx={{
					overflowX: 'auto',
				}}
			>
				<Skeleton
					variant="rectangular"
					width={150}
					height={80}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={80}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={80}
					sx={{
						borderRadius: '5px',
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={150}
					height={80}
					sx={{
						borderRadius: '5px',
					}}
				/>
			</Stack>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={'calc(100vh - 350px)'}
				sx={{
					borderRadius: '5px',
				}}
			/>
		</Stack>
	) : (
		<Stack spacing="24px">
			<HistoryTableFilters count={count} />
			<Box>
				{data && count && (
					<HistoryTable data={data} count={count} loading={loading} />
				)}
			</Box>
		</Stack>
	);
}
