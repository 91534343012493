// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';


const icons = {
    IconDashboard,
    IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Picking_and_packing = {
    id: 'PyP',
    title: <FormattedMessage id="pyp" />,
    icon: icons.IconDashboard,
    type: 'group',
    children:[
        {
            id: 'SalesOrders',
            title: <FormattedMessage id="SalesOrders" />,
            type: 'item',
            url: '/pyp/salesorders',
            icon: PointOfSaleOutlinedIcon,
            breadcrumbs: false
        },     
        {
            id: 'Shipping',
            title: <FormattedMessage id="Shipping" />,
            type: 'item',
            url: '/pyp/shipping',
            icon: LocalShippingOutlinedIcon,
            breadcrumbs: false
        },    
    ]
};

export default Picking_and_packing;
