import styled from '@emotion/styled';
import {
	Box,
	Button,
	Checkbox,
	ClickAwayListener,
	Collapse,
	Divider,
	FormControlLabel,
	FormGroup,
	List,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography,
	tooltipClasses,
} from '@mui/material';
import { Stack } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useForecastHistoryFilters } from 'contexts/ForecastHistoryFiltersContext';

const status = [
	{
		id: 2,
		name: 'Aprobada',
	},
	{
		id: 3,
		name: 'Rechazada',
	},
];

const approvalTypes = [
	{
		id: 1,
		name: 'Manual',
		type: false,
	},
	{
		id: 2,
		name: 'Automática',
		type: true,
	},
];

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		placement="bottom-start"
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		maxWidth: '400px',

		borderRadius: '0px',
	},
}));

function Options({ closeTooltip }) {
	const [open, setOpen] = useState(null);
	const {
		owners,
		selectedOwner,
		selectedStatus,
		setSelectedOwner,
		setSelectedStatus,
		selectedApprovalType,
		setSelectedApprovalType,
		motives,
		selectedMotive,
		setSelectedMotive,
	} = useForecastHistoryFilters();
	const [filterOwner, setFilterOwner] = useState(selectedOwner);
	const [filterStatus, setFilterStatus] = useState(selectedStatus);
	const [filterMotive, setFilterMotive] = useState(selectedMotive);
	const [filterApprovalType, setFilterApprovalType] =
		useState(selectedApprovalType);

	const handleClick = (filter) => {
		setOpen((prev) => (prev === filter ? null : filter));
	};

	const handleClearAndApplyFilters = () => {
		setSelectedOwner(-1);
		setSelectedStatus(-1);
		setSelectedMotive(-1);
		setSelectedApprovalType('IGNORE');
		closeTooltip();
		setOpen(null);
	};
	const handleClearFilters = () => {
		setFilterOwner(-1);
		setFilterStatus(-1);
		setFilterMotive(-1);
		setFilterApprovalType('IGNORE');
	};

	const handleApplyFilters = () => {
		setSelectedOwner(filterOwner);
		setSelectedStatus(filterStatus);
		setSelectedMotive(filterMotive);
		setSelectedApprovalType(filterApprovalType);
		closeTooltip();
		setOpen(null);
	};

	return (
		<List
			sx={{ width: '340px', maxWidth: 340, bgcolor: 'background.paper' }}
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<Stack
					direction="row"
					spacing={3}
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography
						sx={{
							pl: 2,
							color: 'rgba(0, 0, 0, 0.87)',
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontSize: '16px',
							lineHeight: '24px',
							fontWeight: '700',
							letterSpacing: '0.15px',
						}}
					>
						Filtrar por:
					</Typography>
					<Button
						color="secondary"
						onClick={
							selectedOwner !== -1 ||
							selectedStatus !== -1 ||
							selectedApprovalType !== 'IGNORE' ||
							selectedMotive !== -1
								? handleClearAndApplyFilters
								: handleClearFilters
						}
						disabled={
							filterOwner === -1 &&
							filterStatus === -1 &&
							filterApprovalType === 'IGNORE' &&
							filterMotive === -1
						}
					>
						Limpiar filtros
					</Button>
				</Stack>
			}
		>
			<Divider />

			<ListItemButton onClick={() => handleClick('status')}>
				<ListItemText primary="Estado" />
				{open === 'status' ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open === 'status'} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<Box
						sx={{
							maxHeight: '200px',
							overflowY: 'auto',
						}}
					>
						<FormGroup sx={{ pl: 2 }}>
							{status.map((s) => (
								<FormControlLabel
									key={s.id}
									control={
										<Checkbox
											color="secondary"
											checked={filterStatus === s.id}
											onChange={() => {
												setFilterStatus((prev) => {
													if (prev === s.id) {
														return -1;
													}
													return s.id;
												});
											}}
										/>
									}
									label={s.name}
								/>
							))}
						</FormGroup>
					</Box>
				</List>
			</Collapse>

			<Divider />

			<ListItemButton onClick={() => handleClick('approval-type')}>
				<ListItemText primary="Tipo de aprobación" />
				{open === 'approval-type' ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open === 'approval-type'} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<Box
						sx={{
							maxHeight: '200px',
							overflowY: 'auto',
						}}
					>
						<FormGroup sx={{ pl: 2 }}>
							{approvalTypes.map((a) => (
								<FormControlLabel
									key={a.id}
									control={
										<Checkbox
											color="secondary"
											checked={filterApprovalType === a.type}
											onChange={() => {
												setFilterApprovalType((prev) => {
													if (prev === a.type) {
														return null;
													}
													return a.type;
												});
											}}
										/>
									}
									label={a.name}
								/>
							))}
						</FormGroup>
					</Box>
				</List>
			</Collapse>

			<Divider />

			<ListItemButton onClick={() => handleClick('owner')}>
				<ListItemText primary="Category Manager" />
				{open === 'owner' ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open === 'owner'} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<Box
						sx={{
							maxHeight: '200px',
							overflowY: 'auto',
						}}
					>
						<FormGroup sx={{ pl: 2 }}>
							{owners.map((owner) => (
								<FormControlLabel
									key={owner.id}
									control={
										<Checkbox
											color="secondary"
											checked={filterOwner === owner.id}
											onChange={() => {
												setFilterOwner((prev) => {
													if (prev === owner.id) {
														return -1;
													}

													return owner.id;
												});
											}}
										/>
									}
									label={`${owner.name} ${owner.lastname}`}
								/>
							))}
						</FormGroup>
					</Box>
				</List>
			</Collapse>

			<Divider />

			<ListItemButton onClick={() => handleClick('motive')}>
				<ListItemText primary="Motivos" />
				{open === 'motive' ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open === 'motive'} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<Box
						sx={{
							maxHeight: '200px',
							overflowY: 'auto',
						}}
					>
						<FormGroup sx={{ pl: 2 }}>
							{motives.map((motive) => (
								<FormControlLabel
									key={motive.id}
									control={
										<Checkbox
											color="secondary"
											checked={motive.id === filterMotive}
											onChange={() => {
												setFilterMotive((prev) => {
													if (prev === motive.id) {
														return -1;
													}

													return motive.id;
												});
											}}
										/>
									}
									label={motive.motivo_str}
								/>
							))}
						</FormGroup>
					</Box>
				</List>
			</Collapse>

			<Divider />

			<Button
				color="secondary"
				onClick={handleApplyFilters}
				sx={{
					mt: 2,
				}}
				disabled={
					filterOwner === selectedOwner &&
					filterStatus === selectedStatus &&
					filterApprovalType === selectedApprovalType &&
					filterMotive === selectedMotive
				}
			>
				Aplicar filtros
			</Button>
		</List>
	);
}

export default function HistoryFiltersOptions() {
	// const {
	// 	selectedOwners,
	// 	selectedDomains,
	// 	selectedMarketplaces,
	// 	selectedCategories,
	// } = useForecastFilters();
	const [open, setOpen] = useState(false);

	// const appliedFilters =
	// 	selectedOwners.length +
	// 	selectedDomains.length +
	// 	selectedMarketplaces.length +
	// 	selectedCategories.length;

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleToggleTooltip = () => {
		setOpen((prev) => !prev);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<LightTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={<Options closeTooltip={handleTooltipClose} />}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							padding: '12px',
							width: '182px',
							borderRadius: '4px',
							border: '1px solid rgba(0, 0, 0, 0.23)',
							cursor: 'pointer',
						}}
						onClick={handleToggleTooltip}
					>
						<Typography
							sx={{
								color: '#00000099',
								fontSize: '16px',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							{/* {appliedFilters > 0
								? `Filtros (${appliedFilters})`
								: 'Filtrar por'} */}
							Filtrar por
						</Typography>
						<FilterListIcon
							sx={{
								fill: '#0000008F',
								width: '24px',
								height: '24px',
							}}
						/>
					</Stack>
				</LightTooltip>
			</div>
		</ClickAwayListener>
	);
}
