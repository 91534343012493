import { createContext, useCallback, useContext, useState } from 'react';
import { AppGlobalContext } from './GlobalContext';
import useAuth from 'hooks/useAuth';
import axios from 'axios';

const GlobalQuoteContext = createContext(null);

export function GlobalQuoteProvider({ children }) {
	const [retry, setRetry] = useState(true);
	const [loading, setLoading] = useState(true);
	const [quote, setQuote] = useState(null);
	const { mainCountry } = useContext(AppGlobalContext);
	const { accessToken } = useAuth();

	const getQuote = useCallback(async () => {
		try {
			setLoading(true);
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/Maestro/exchange_rate/-1/${mainCountry.pais}/IGNORE`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			setQuote(response.data[0]);
			console.log('Data fetched:', response.data);
			setRetry(false);
		} catch (error) {
			console.error('Request failed:', error);
			if (!retry) {
				console.log('Retrying request...');
				setRetry(true);
			}
		} finally {
			setLoading(false);
		}
	}, [accessToken, mainCountry.pais, retry]);

	return (
		<GlobalQuoteContext.Provider value={{ loading, quote, getQuote }}>
			{children}
		</GlobalQuoteContext.Provider>
	);
}

export const useGlobalQuote = () => {
	const context = useContext(GlobalQuoteContext);
	if (!context) {
		throw new Error('useGlobalQuote must be used within a GlobalQuoteProvider');
	}
	return context;
};
