import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { useEventsFilters } from 'contexts/EventsFiltersContext';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function DateRangeFilter() {
	const { dateRange, setDateRange } = useEventsFilters();

	const handleChange = (newValue) => {
		console.log(newValue);
		if (!newValue) return;
		setDateRange((prev) =>
			newValue.map((value) => (value ? value.format('YYYY-MM-DD') : value))
		);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateRangePicker
				label="YYYY-MM-DD"
				value={dateRange}
				onChange={handleChange}
				renderInput={(startProps, endProps) => (
					<Stack direction="row" gap="8px" flexWrap="wrap">
						<TextField
							{...startProps}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CalendarMonthIcon fontSize="small" />
									</InputAdornment>
								),
							}}
						/>

						<TextField
							{...endProps}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CalendarMonthIcon fontSize="small" />
									</InputAdornment>
								),
							}}
						/>
					</Stack>
				)}
			/>
		</LocalizationProvider>
	);
}
