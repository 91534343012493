import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link } from "@mui/material";

// project imports
import { DASHBOARD_PATH } from "config";
// import LogoDisbyte from "../../../assets/images/disbyte/Logo_Disbyte_DOS.png";
import LogoDisbyte from "../../../assets/images/disbyte/Logo.png";

// import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <>
    <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="berry logo">
      {/* <Logo /> */}
      <img height="33px" src={LogoDisbyte} />
    </Link>
  </>
);

export default LogoSection;
