import React from 'react';
import ForecastAdminPanel from 'views/Components/Forecast/Admin/ForecastAdminPanel';

const ForecastAdminPanelPage = (props) => {
	return (
		<div style={mainDiv}>
			<ForecastAdminPanel />
		</div>
	);
};

export default ForecastAdminPanelPage;

const mainDiv = {
	height: '100%',
	border: '0px solid black',
};
