export const INITIAL_COLUMNS_STATE = {
	thumbnail: true,
	sku: true,
	status: true,
	usdcost: true,
	localcost: true,
	pvp: true,
	pvpdisbyte: true,
	pvpbuyer: true,
	discount: true,
	margen: true,
	roi: true,
	usdcost_proyectado: true,
	localcost_proyectado: true,
	pvp_proyectado: true,
	pvpdisbyte_proyectado: true,
	// pvpbuyer_proyectado: true,
	discount_proyectado: true,
	margen_proyectado: true,
	roi_proyectado: true,
};

export const pricingStoreSlice = (set) => ({
	pricingOwners: [],
	pricingDomains: [],
	pricingSelectedOwners: [],
	pricingSelectedDomains: [],
	pricingColumns: INITIAL_COLUMNS_STATE,
	pricingSearch: '',
	pricingShowInactive: true,
	pricingShowFlag: false,
	pricingCostHistoryOpen: false,
	pricingCostHistoryItem: null,

	setPricingOwners: (owners) => set(() => ({ pricingOwners: owners })),
	setPricingDomains: (domains) => set(() => ({ pricingDomains: domains })),
	setPricingSelectedOwners: (selectedOwners) =>
		set(() => ({ pricingSelectedOwners: selectedOwners })),
	setPricingSelectedDomains: (selectedDomains) =>
		set(() => ({ pricingSelectedDomains: selectedDomains })),
	setPricingColumns: (columns) => set(() => ({ pricingColumns: columns })),
	setPricingSearch: (search) => set(() => ({ pricingSearch: search })),
	setPricingShowInactive: (showInactive) =>
		set(() => ({ pricingShowInactive: showInactive })),
	setPricingShowFlag: (showFlag) => set(() => ({ pricingShowFlag: showFlag })),
	setPricingCostHistoryOpen: (open) =>
		set(() => ({ pricingCostHistoryOpen: open })),
	setPricingCostHistoryItem: (item) =>
		set(() => ({ pricingCostHistoryItem: item })),
});
