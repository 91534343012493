import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

const fetchMotives = async (accessToken) => {
	const res = await axios.get(
		`${process.env.REACT_APP_API_URL}/forecast/motivo`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				accept: '*/*',
			},
		}
	);

	return res;
};

export default function useMotivesData() {
	const { accessToken } = useAuth();

	const motivesQuery = useQuery({
		queryKey: ['motives'],
		queryFn: () => fetchMotives(accessToken),
		select: (data) => {
			return data?.data;
		},
		staleTime: Infinity,
		retry: false,
	});

	return motivesQuery;
}
