const idToPermissions = {
	// No hace falta agregar permisos aqui, a menos que quiera restringirse un bloque completo del menu.
	ejemploID: 'permiso:permiso',
	application: '_Comex',
	// ...
};

const idToPermissionsChildren = {
	/* DASHBOARD */
	// "home": "abm:read",

	/* MEXICO */
	simulador: 'Sourcing',
	simuladorPO: 'Sourcing',
	simuladorBC: 'Sourcing',

	/* settings */
	settings: 'SalesTracker:view',

	/* SALES TRACKING */
	'sales-tracking': 'SalesTracker:view',

	/* PRICING */
	'pricing-list': 'page:pricing',
	'pricing-list2': 'page:pricing',

	/* PRODUCTOS VS */
	products: 'abm:read',

	/* TABLAS MAESTRAS */
	TARIFAS: 'tarifas:read',
	NCM: 'abm:read',

	/* SERVICE OPERATION - TALLER*/
	ServiceOperationAdd: 'ServiceOp:viewAdmin',
	ServiceOperationAdmin: 'ServiceOp:viewAdmin',
	ServiceOperationTaller: 'ServiceOp:view',
	ServiceOperationPanelAdmin: 'ServiceOp:viewAdmin',
	ForecastAdmin: 'Forecast:viewAdmin',

	// FORECAST
	forecast: 'Forecast:view',
	forecastAdmin: 'Forecast:viewAdmin',

	// Pickin and packing
	SalesOrders: 'PyP:view',
	Shipping: 'PyP:view',

	// Landed cost
	winners: 'Winner:view',
	dailyCost: 'Winner:view',
	loadEntry: 'Winner:view',
	loadEntries: 'Winner:view',

	//SalesTracking
	salesTracking: 'SalesTracker:view',
};

const idToPermissionsGrandchild = {
	/* MEXICO -> INBOUND */
	'estimate-list': 'Comex',
	'estimate-list-VS': 'Comex',

	/*TABLAS MAESTRAS -> CONSTANTES*/
	'pais-region': 'abml1:read',
	carga: 'abml1:read',
	canal: 'abm:read',
	impuestos: 'abm:read',

	/* TABLAS MAESTRAS -> PROVEEDORES*/
	banco: 'abm:read',
	fwdtte: 'abm:read',
	terminal: 'abm:read',
	custodia: 'abm:read',
	flete: 'abm:read',
	truck: 'abm:read',
	despachante: 'abm:read',
	deposito: 'abm:read',
	GestDigitalDoc: 'abm:read',
	poliza: 'abm:read',
	proveedoresOem: 'abm:read',

	/* TABLAS MAESTRAS -> POSICION ARANCELARIA*/
	NCM_MEX: 'abm:read',
	NCM_ARG: 'abm:read',
	// ...

	// Pickin and packing
	PyP: 'PyP:view',
	'PyP-SalesOrders': 'PyP:view',
	'PyP-Assignments': 'PyP:view',
	'PyP-Picking': 'PyP:view',
	'PyP-Packed': 'PyP:view',
	'PyP-Shipped': 'PyP:view',
};

function filtrarChildrenVacios(objetos) {
	return objetos
		.map((objeto) => {
			// Verificar y filtrar recursivamente los children, si existen
			if (objeto.children && objeto.children.length > 0) {
				objeto.children = filtrarChildrenVacios(objeto.children);
			}
			return objeto;
		})
		.filter((objeto) => {
			// Mantener el objeto si no tiene children o si children no está vacío
			return !objeto.children || objeto.children.length > 0;
		});
}

export function filterItemsByPermissions(items, permissions) {
	try {
		// console.log("items", items)
		// Función para filtrar recursivamente los objetos y sus children
		function filterRecursive(item) {
			// Verificar si el ID del item tiene un permiso asociado y si ese permiso no está en la lista de permisos
			if (
				idToPermissions[item.id] &&
				!permissions.includes(idToPermissions[item.id])
			) {
				return false; // No tiene permiso, quitar el item
			}

			// Verificar si el path del item tiene un permiso asociado y si ese permiso no está en la lista de permisos
			if (
				idToPermissionsChildren[item.id] &&
				!permissions.includes(idToPermissionsChildren[item.id])
			) {
				return false; // No tiene permiso, quitar el item
			}

			// Si el item tiene children, aplicar la función filterRecursive a cada uno de ellos
			if (item.children) {
				item.children = item.children
					.map((child) => {
						// Verificar permisos para los nietos
						if (child.children) {
							child.children = child.children.filter((grandchild) => {
								return (
									!idToPermissionsGrandchild[grandchild.id] ||
									permissions.includes(idToPermissionsGrandchild[grandchild.id])
								);
							});
						}
						return filterRecursive(child);
					})
					.filter((child) => child !== false);
			}

			return item; // El item tiene permiso, mantenerlo
		}

		// Aplicar la función filterRecursive a cada item en el array principal
		let itemResult = items
			.map(filterRecursive)
			.filter((item) => item !== false);

		let res = filtrarChildrenVacios(itemResult);

		return res;
	} catch (error) {
		console.log('contextError', error);
	}
}
