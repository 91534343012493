import { Stack } from '@mui/material';
import HistoryCount from './HistoryCount';
import OwnerFilterChip from './OwnerFilterChip';
import HistoryTableFilters from './HistoryTableFilters';

export default function Toolbar({ count }) {
	return (
		<Stack spacing={2}>
			<Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
				{count >= 0 && <HistoryCount count={count} />}

				<HistoryTableFilters />
			</Stack>

			<OwnerFilterChip />
		</Stack>
	);
}
