import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEventsFilters } from 'contexts/EventsFiltersContext';

export default function SearchBar() {
	const { setSearch, search } = useEventsFilters();

	return (
		<TextField
			placeholder="Buscar campañas..."
			label="Buscar"
			value={search}
			onChange={(e) => setSearch(e.target.value)}
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					borderRadius: '5px',

					border: '1px solid #0000003B',
				},
				'& .MuiInputBase-input': {
					backgroundColor: '#FFFFFF',
					padding: '11px 12px',
				},
				'& .MuiInputBase-root': {
					backgroundColor: '#FFFFFF',
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" />
					</InputAdornment>
				),
			}}
		/>
	);
}
