import { createContext, useState, useEffect } from 'react';
import jwt from 'jsonwebtoken';

// memory space created
export const AppGlobalContext = createContext();

export const countries = [
	{ id: 7, pais: 'ARGENTINA' },
	{ id: 1, pais: 'BRASIL' },
	{ id: 5, pais: 'MEXICO' },
	{ id: 4, pais: 'COLOMBIA' },
];

export const GlobalContext = ({ children }) => {
	const [mainCountry, setMainCountry] = useState(null);

	const setCountry = (countryObject) => {
		try {
			let data = jwt.sign(
				countryObject,
				process.env.REACT_APP_JWTKEY_ACCESSDENIED
			);
			localStorage.setItem('mainCountry', data);
			setMainCountry(countryObject);
		} catch (error) {
			console.error('unable to set new mainCountry');
			defaultCountry();
		}
	};

	const defaultCountry = () => {
		try {
			let data = jwt.sign(
				{ id: 7, pais: 'ARGENTINA' },
				process.env.REACT_APP_JWTKEY_ACCESSDENIED
			);
			localStorage.setItem('mainCountry', data);
			setMainCountry({ id: 7, pais: 'ARGENTINA' });
		} catch (error) {
			console.error('unable to set mainCountry');
		}
	};

	useEffect(() => {
		//Mount:
		try {
			let localStorageCountry = localStorage.getItem('mainCountry');
			if (localStorageCountry) {
				let decoded = jwt.verify(
					localStorageCountry,
					process.env.REACT_APP_JWTKEY_ACCESSDENIED
				);
				// AGREGAR FUNCION PARA VALIDAR ESTRUCTURA DEL DECODED
				setMainCountry(decoded);
			} else {
				defaultCountry();
			}
		} catch (error) {
			console.error('unable to read mainCountry');
			defaultCountry();
		}
		return () => {
			//Unmount
		};
	}, []);

	return (
		<AppGlobalContext.Provider
			value={{
				mainCountry,
				defaultCountry,
				setCountry,
			}}
		>
			{children}
		</AppGlobalContext.Provider>
	);
};
