import React from "react";
import Error from "views/pages/maintenance/Error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // log the error or perform any necessary actions
  }

  render() {
    if (this.state.hasError) {
      // fallback UI
      return <Error/>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
