// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';


// ----------------------------------------------------------------------

const initialState = {
    error: null,
    // orders: [],

    //hay que declarar el estado inicial
    virtualSeller: [],
    virtualSellerPorPais: [],
    virtualSellerPorPais_COL: [],
};

const slice = createSlice({
    name: 'virtualSeller',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET Inbound
        getVirtualSellerSuccess(state, action) {
            state.virtualSeller = action.payload;
        },

        // GET Inbound
        getVirtualSellerPorPaisSuccess(state, action) {
            state.virtualSellerPorPais = action.payload;
        },

        getVirtualSellerPorPaisSuccess_COL(state, action){
            state.virtualSellerPorPais_COL = action.payload;
        }

        // GET ORDERS
        // getOrdersSuccess(state, action) {
        //     state.orders = action.payload;
        // },

        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getVirtualSeller(accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.fetchDataVS(accessToken); 
            dispatch(slice.actions.getVirtualSellerSuccess(response));
            console.log('Get virtualSeller :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getVirtualSellerPorPais(idPais, accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.fetchDataVS(idPais, accessToken); 

            //primero cambio los valores quitando el ascento a mexico para poder usarlo en el filtro
            let respueseFiltrada = response
            .map(vs => ({
                ...vs,
                vs_pais: vs.vs_pais === 'México' ? 'Mexico' : vs.vs_pais == '' ? 'Sin Especificar' : vs.vs_pais,
                vs_condicion: vs.imported ? 'Importado' : vs.tieneProd ? 'Apto Importacion' : 'Sin Producto', //aca pregunto para generar el filtro de condicion para importacion
            }))
            .filter(vs => vs.vs_pais) // aqui quito los paises que son null o vacios ''
            
            dispatch(slice.actions.getVirtualSellerPorPaisSuccess(respueseFiltrada));
            console.log('Get virtualSeller Pais MEX :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getVirtualSellerPorPais_COLOMBIA(idPais, accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.fetchDataVS(idPais, accessToken); 

            //primero cambio los valores quitando el ascento a mexico para poder usarlo en el filtro
            let respueseFiltrada = response
            .map(vs => ({
                ...vs,
                vs_pais: vs.vs_pais === 'México' ? 'Mexico' : vs.vs_pais == '' ? 'Sin Especificar' : vs.vs_pais,
                vs_condicion: vs.imported ? 'Importado' : vs.tieneProd ? 'Apto Importacion' : 'Sin Producto', //aca pregunto para generar el filtro de condicion para importacion
            }))
            .filter(vs => vs.vs_pais) // aqui quito los paises que son null o vacios ''
            
            dispatch(slice.actions.getVirtualSellerPorPaisSuccess_COL(respueseFiltrada));
            console.log('Get virtualSeller Pais COL :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// export function getOrders() {
//     return async (dispatch) => {
//         try {
//             const response = await axios.get('/api/inbound/order/list');
//             dispatch(slice.actions.getOrdersSuccess(response.data.orders));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }


