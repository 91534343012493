import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: '#616161E5',
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#616161E5',
	},
}));

export default function InfoTooltip({ title }) {
	return (
		<BootstrapTooltip title={title} placement="top">
			<Button
				sx={{
					minWidth: 'fit-content',
					padding: '0',
					paddingBottom: '2px',
				}}
			>
				<InfoOutlinedIcon
					sx={{
						color: '#00000061',
						height: '15px',
						width: '15px',
					}}
				/>
			</Button>
		</BootstrapTooltip>
	);
}
