// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const icons = {
	IconDashboard,
	IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Settings = {
	id: 'Settings',
	title: <FormattedMessage id="settings" />,
	icon: icons.IconDashboard,
	type: 'group',
	children: [
		{
			id: 'settings',
			title: <FormattedMessage id="settings" />,
			type: 'item',
			url: '/settings',
			icon: LocalMallIcon,
			breadcrumbs: false,
		},
	],
};

export default Settings;
