// LISTED 11/7/2023 13:15PM  
//
export const NcmHelper = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    rutaTablaArg: 'NCM',
    rutaTablaMex: 'NCM_Mex',
    rutaTablaCol: 'NCM_Col',
    constructor(baseUrl){
        this.baseUrl = baseUrl;
    },

    //Consulta a la API
    fetchData: async function (accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaArg}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
              });
            const jsonData = await response.json();
            const jsonDataStatus = await response;
            // return [jsonData, jsonDataStatus];
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    //CRUD 
    // Crear un registro en la tabla
    createData: async function (newData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaArg}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newData),
            });
            const jsonData = await response.json();
            console.log('Helper', newData)
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Leer un registro de la tabla por ID
    readDataById: async function(id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaArg}/${id}`);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Actualizar un registro de la tabla por ID
    updateDataById: async function (code, updatedData, accessToken) {
        try {
            if(updatedData.vc===null)
            {
                updatedData.vc="";
            }
            if(updatedData.bit_bk===null)
            {
                updatedData.bit_bk="";
            }
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaArg}/${code}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });
            console.log(updatedData);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Eliminar un registro de la tabla por ID
    deleteDataByCode: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaArg}/${id}`, {
                method: 'DELETE',
            });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },

    // MEXICO
    //Consulta a la API
    fetchDataMex: async function (accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaMex}`, {
                headers:{
                    Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                }
            },);
            let jsonData = await response.json();
            // jsonData = jsonData.filter(data => data.code !== 'NO DEFINIDO')
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },

    fetchDataCol: async function (accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaCol}`, {
                headers:{
                    Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                }
            },);
            let jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    //CRUD 
    // Crear un registro en MEXICO
    createDataMex: async function (newData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaMex}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newData),
            });
            const jsonData = await response.json();
            console.log('Helper', newData)
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Leer un registro de la tabla por ID MEXICO
    readDataByIdMex: async function(id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaMex}/${id}`);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Actualizar un registro de la tabla por ID MEXICO
    updateDataByIdMex: async function (code, updatedData, accessToken) {
        try {
            if(updatedData.vc===null)
            {
                updatedData.vc="";
            }
            if(updatedData.bit_bk===null)
            {
                updatedData.bit_bk="";
            }
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaMex}/${code}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });
            console.log(updatedData);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Eliminar un registro de la tabla por ID
    deleteDataByIdMex: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaMex}/${id}`, {
                method: 'DELETE',
                headers:{
                    Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
            });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },


        //CRUD 
    // Crear un registro en COLOMBIA
    createDataCol: async function (newData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaCol}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newData),
            });
            const jsonData = await response.json();
            console.log('Helper', newData)
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Leer un registro de la tabla por ID COLOMBIA
    readDataByIdCol: async function(id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaCol}/${id}`);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Actualizar un registro de la tabla por ID MEXICO
    updateDataByIdCol: async function (code, updatedData, accessToken) {
        try {
            if(updatedData.vc===null)
            {
                updatedData.vc="";
            }
            if(updatedData.bit_bk===null)
            {
                updatedData.bit_bk="";
            }
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaCol}/${code}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });
            console.log(updatedData);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Eliminar un registro de la tabla por ID
    deleteDataByIdCol: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTablaCol}/${id}`, {
                method: 'DELETE',
                headers:{
                    Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
            });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },

}