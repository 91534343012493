import { FetchService } from "utils/FetchService";


// este objeto esta hecho para el deatils y su validacion, xq puede pasar que no tenga proveedor el sku
// por lo tanto debo de darle uno como "Sin Data", y de esta manera lo capturo como error asi lo cargan a mano
const proveedorBase = {
  description : "Sin Data",
  id : "Sin Data",
  vs_proveekey : "Sin Data",
}

export const ProveedoresOemHelper = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  rutaTabla: "Proveedor",
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  },

  // METODO POR GUSTAVO
  fetchData: async function (accessToken) {
    try {
      const response = await FetchService.Get(this.rutaTabla, accessToken);
      // console.log("PoveedoresOemHelper.fetchData::response", response);
      response.push(proveedorBase) //agrego el proveedor base
      // console.log('Proveedores', response);
      return response;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },

  fetchDataPais: async function (accessToken) {
    try {
      const response = await FetchService.Get(`${this.rutaTabla}/vista`, accessToken);
      // console.log("ProveedoresOemHelper.fetchData::response", response);

      return response;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },

  //CRUD
  // Crear un registro en la tabla
  createData: async function (newData, accessToken) {
    try {
      const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${newData.description}/${newData.vs_proveekey}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization : `Bearer ${accessToken}`,
        },
        body: JSON.stringify(newData),
      });
      const jsonData = await response.json();
      console.log("Helper", newData);
      return jsonData;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },
  // Leer un registro de la tabla por ID
  readDataById: async function (id, accessToken) {
    try {
      const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization : `Bearer ${accessToken}`,
        },
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },
  // Actualizar un registro de la tabla por ID
  updateDataById: async function (id, updatedData, accessToken) {
    try {
      const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization : `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedData),
      });
      console.log(updatedData);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },
  // Eliminar un registro de la tabla por ID
  deleteDataById: async function (id, accessToken) {
    try {
      const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization : `Bearer ${accessToken}`,
        },
      });
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  },
};
