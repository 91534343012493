import { Box, Divider, Stack, Typography } from '@mui/material';
import CreateFlowForm from './CreateFlowForm';

export default function CreateFlowSection() {
	return (
		<Stack>
			<Box
				sx={{
					padding: '16px 24px',
				}}
			>
				<Typography
					sx={{
						fontSize: '20px',
						fontWeight: '500',
						fontFamily: 'Roboto',
						color: 'rgba(0, 0, 0, 0.87)',
						lineHeight: '32px',
						letterSpacing: '0.15px',
					}}
				>
					Flujo de aprobaciones
				</Typography>
			</Box>
			<Divider />

			<CreateFlowForm />
		</Stack>
	);
}
