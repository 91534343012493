import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

export default function CreateEventModal({ fetchEvents }) {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleOpen}>+ Nuevo Evento</Button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Crear Evento</DialogTitle>
				<DialogContent>
					<CreateEventForm onClose={handleClose} fetchEvents={fetchEvents} />
				</DialogContent>
			</Dialog>
		</>
	);
}

const EventSchema = Yup.object().shape({
	tier: Yup.number().required('El tier es requerido'),
	event: Yup.string().required('El evento es requerido'),
});

function CreateEventForm({ campaign, onClose }) {
	const formik = useFormik({
		initialValues: {
			tier: '',
			event: '',
		},
		validationSchema: EventSchema,
		onSubmit: (values) => {
			if (campaign) {
				// edit campaign
			} else {
				// create campaign
			}
			onClose();
		},
	});

	return (
		<Box
			component="form"
			onSubmit={formik.handleSubmit}
			noValidate
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<FormControl fullWidth>
				<Typography
					component="label"
					htmlFor="tier"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Tipo de evento
				</Typography>
				<Select
					value={formik.values.tier}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					name="tier"
					id="tier"
					displayEmpty
					error={formik.touched.tier && Boolean(formik.errors.tier)}
				>
					<MenuItem value="" disabled>
						<Typography
							sx={{
								color: '#00000099',
							}}
						>
							-Elegir tipo de evento-
						</Typography>
					</MenuItem>
					<MenuItem value={1}>Tier 1</MenuItem>
					<MenuItem value={2}>Tier 2</MenuItem>
					<MenuItem value={3}>Tier 3</MenuItem>
					<MenuItem value={3}>Tier 4</MenuItem>
				</Select>
			</FormControl>

			<FormControl fullWidth>
				<Typography
					component="label"
					htmlFor="event"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Evento
				</Typography>
				<TextField
					value={formik.values.event}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.event && Boolean(formik.errors.event)}
					name="event"
					id="event"
					displayEmpty
				/>
			</FormControl>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button onClick={onClose} variant="outlined">
					Cancelar
				</Button>
				<Button type="submit" variant="contained">
					Guardar
				</Button>
			</Stack>
		</Box>
	);
}
