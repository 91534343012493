import React, { useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { Box, ButtonBase } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { generateCalendarDays } from './utils';
import DayCard from './DayCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function CalendarCarousel({ events }) {
	const daysArray = useMemo(() => generateCalendarDays(), []);
	const today = new Date().toISOString().split('T')[0];

	const sliderRef = useRef(null);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 7,
		slidesToScroll: 7,
		initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1480,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 6,
					infinite: true,
					dots: false,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
				},
			},
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
					infinite: true,
					dots: false,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
				},
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 780,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 440,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: daysArray.findIndex((day) => day.date === today) - 1,
					infinite: true,
					dots: false,
				},
			},
		],
	};

	return (
		<Box sx={{ width: '98%', margin: '0 auto' }} className="slider-container">
			<Slider {...settings} ref={sliderRef}>
				{daysArray.map((day) => (
					<DayCard key={day.date} day={day} events={events} />
				))}
			</Slider>
		</Box>
	);
}

function NextArrow(props) {
	return (
		<ButtonBase
			onClick={props.onClick}
			sx={{
				position: 'absolute',
				top: '50%',
				display: 'block',
				width: '20px',
				height: '20px',
				padding: 0,
				'-webkit-transform': 'translate(0, -50%)',
				'-ms-transform': 'translate(0, -50%)',
				transform: 'translate(0, -50%)',
				right: '-25px',
			}}
		>
			<ArrowForwardIosIcon />
		</ButtonBase>
	);
}

function PrevArrow(props) {
	return (
		<ButtonBase
			onClick={props.onClick}
			sx={{
				position: 'absolute',
				top: '50%',
				display: 'block',
				width: '20px',
				height: '20px',
				padding: 0,
				'-webkit-transform': 'translate(0, -50%)',
				'-ms-transform': 'translate(0, -50%)',
				transform: 'translate(0, -50%)',
				left: '-25px',
			}}
		>
			<ArrowBackIosNewIcon />
		</ButtonBase>
	);
}
