import {
	Box,
	Button,
	ButtonBase,
	Checkbox,
	Drawer,
	FormControlLabel,
	InputAdornment,
	List,
	ListItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

export default function SelectCategoriesDrawer({
	categories,
	pickedCategories,
	setCategories,
	disabled,
}) {
	const [open, setOpen] = useState(false);
	const [selectedCategories, setSelectedCategories] =
		useState(pickedCategories);
	const [searchField, setSearchField] = useState('');

	const filteredCategories = categories.filter((category) =>
		category.toLowerCase().includes(searchField.toLowerCase())
	);

	const handleSelectCategory = (category) => {
		if (selectedCategories.includes(category)) {
			setSelectedCategories(selectedCategories.filter((c) => c !== category));
		} else {
			setSelectedCategories([...selectedCategories, category]);
		}
	};

	const handleSave = () => {
		setCategories(selectedCategories);
		setOpen(false);
	};

	return (
		<>
			<Box>
				<ButtonBase
					onClick={() => setOpen(true)}
					disabled={disabled}
					sx={{
						color: disabled ? '#6c6c6c' : '#354887',
					}}
				>
					+ Seleccionar
				</ButtonBase>
			</Box>

			<Drawer
				anchor="right"
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					zIndex: (theme) => theme.zIndex.modal + 1,
				}}
			>
				<Stack
					sx={{
						width: '350px',
						backgroundColor: '#eef2f6',
						height: '100%',
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
						sx={{
							padding: '10px',
							paddingTop: '30px',
							backgroundColor: '#fff',
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							sx={{
								backgroundColor: '#fff',
							}}
						>
							<ButtonBase onClick={() => setOpen(false)}>
								<ArrowBackIosNewIcon
									sx={{
										width: '15px',
										height: '15px',
									}}
								/>
							</ButtonBase>
							<Typography
								variant="h2"
								sx={{
									fontSize: '20px',
									fontWeight: 'bold',
								}}
							>
								Categorías
							</Typography>
						</Stack>
						<Button variant="contained" onClick={handleSave}>
							Guardar
						</Button>
					</Stack>

					<Box
						sx={{
							padding: '20px',
						}}
					>
						<Box
							sx={{
								backgroundColor: '#fff',
								borderRadius: '5px',
							}}
						>
							<Stack sx={{ padding: '20px 20px 0 20px' }}>
								<SearchBar
									searchField={searchField}
									setSearchField={setSearchField}
								/>
								<Typography
									sx={{
										fontSize: '12px',
										fontWeight: 400,
										marginBottom: '8px',
										fontFamily: 'Roboto',
										color: '#00000099',
									}}
								>
									Seleccioná las categorias a las que querés aplicar este evento
								</Typography>
							</Stack>
							<List>
								{filteredCategories.map((category) => (
									<ListItem key={category}>
										<FormControlLabel
											control={
												<Checkbox
													checked={selectedCategories.includes(category)}
													onChange={() => handleSelectCategory(category)}
												/>
											}
											label={category}
										/>
									</ListItem>
								))}
							</List>
						</Box>
					</Box>
				</Stack>
			</Drawer>
		</>
	);
}

function SearchBar({ searchField, setSearchField }) {
	return (
		<TextField
			placeholder="Categoría"
			label="Buscar"
			value={searchField}
			onChange={(e) => setSearchField(e.target.value)}
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					borderRadius: '5px',

					border: '1px solid #0000003B',
				},
				'& .MuiInputBase-input': {
					backgroundColor: '#FFFFFF',
				},
				'& .MuiInputBase-root': {
					backgroundColor: '#FFFFFF',
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" />
					</InputAdornment>
				),
			}}
		/>
	);
}
