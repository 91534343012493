import { Button, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function FilterChip({ filterName, title, onDelete, disabled }) {
	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			sx={{
				backgroundColor: '#EDEDFC',
				borderRadius: '4px',
				padding: '2px 8px',
				width: 'fit-content',
			}}
		>
			<Typography
				sx={{
					fontSize: '14px',
					color: 'grey',
					fontWeight: 400,
					fontFamily: 'Roboto, sans-serif',
				}}
			>
				{filterName}:
			</Typography>
			<Typography
				sx={{
					fontSize: '14px',
					color: '#151357',
					fontWeight: 500,
					fontFamily: 'Roboto, sans-serif',
				}}
			>
				{title}
			</Typography>
			<Button
				onClick={onDelete}
				sx={{
					minWidth: 'fit-content',
					padding: 0,
				}}
				disabled={disabled}
			>
				<CloseIcon
					sx={{
						color: 'grey',
						width: '20px',
						height: '20px',
					}}
				/>
			</Button>
		</Stack>
	);
}
