import { FetchService } from "utils/FetchService";

export const CargaHelper = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    rutaTabla: 'Carga',
    constructor(baseUrl){
        this.baseUrl = baseUrl;
    },
    fetchData: async function (accessToken) {
        try {
            const response = await FetchService.Get(this.rutaTabla, accessToken);
            // console.log('CargaHelper.fetchData::response', response);
            //quito las cargas que no son necesarias o no estaran activas
            let cargaFiltrada = response.filter(c => c.description != '2*20ST' && c.description != '2*40ST' && c.description != '2*40HQ');
    
            return cargaFiltrada;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    }, 

    //Consulta a la API
    DetalleContenedor: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
              });
            const jsonData = await response.json();

            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    //CRUD CANAL 
    // Crear un registro en la tabla
    createData: async function (newData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(newData),
            });
            const jsonData = await response.json();
            console.log('Helper', newData)
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Leer un registro de la tabla por ID
    readDataById: async function(id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
              });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Actualizar un registro de la tabla por ID
    updateDataById: async function (id, updatedData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedData),
            });
            console.log(updatedData);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Eliminar un registro de la tabla por ID
    deleteDataById: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                method: 'DELETE',
                headers:{
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },

}