// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { ProductosHelper } from 'helpers/Costos/ProductosHelper';
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    orders: [],

    //hay que declarar el estado inicial
    productos: [],
};

const slice = createSlice({
    name: 'productos',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET productos
        getProductosSuccess(state, action) {
            state.productos = action.payload;
        },

        // has error
        hasError(state, action) {
            state.error = action.payload;
        },
        
        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getProductosVS(accessToken) {
    return async (dispatch) => {
        try {
            let response = await ProductosHelper.fetchDataVsProducto(accessToken); 
            //aqui agrego el atributo de empresaFiltro para poder filtrar luego en vista
            response = response.map((producto) => ({
                ...producto,
                empresaFiltro: producto.empresa === 'D' ? 'Disbyte' : producto.empresa === 'M' ? 'MatMax' : 'Sin Data',
            }));
            dispatch(slice.actions.getProductosSuccess(response));
            console.log('Get Productos :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getOrders() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/inbound/order/list');
            dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

