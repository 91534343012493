import dashboard from './dashboard';
import application from './application';
import masterTables from './masterTables';
import Productos from './Productos';
import Pricing from './Pricing';
import ServiceOperation from './ServiceOperation';
import Picking_and_packing from './picking_and_packing';

import pages from './pages';

// ELEMENTOS SECUNDARIOS
import elements from './elements';
import utilities from './utilities';
import support from './support';
import other from './other';
import Forecast from './Forecast';
import LandedCost from './LandedCost';
import SalesTracking from './SalesTracking';
import Settings from './Settings';

// ==============================|| MENU ITEMS ||============================== //
//aqui exporto en menuItems los items en forma de objeto para armar la barra navegadora
const menuItems = {
	items: [
		// dashboard,
		// simulador,
		// application, //costos
		Pricing,
		// Productos,
		// masterTables,
		ServiceOperation,
		LandedCost,
		Picking_and_packing,
		Forecast,
		SalesTracking,
		Settings,
		//pages,
		// elements,
		// utilities,
		// support,
		// other
	],
};

export default menuItems;
