import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import useCountriesData from './countriesQuery';
import { useContext } from 'react';
import { AppGlobalContext } from 'contexts/GlobalContext';

const fetchSkus = async (accessToken, countryId) => {
	const res = await axios.get(
		`${process.env.REACT_APP_API_URL}/Maestro/sku/${countryId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				accept: '*/*',
			},
		}
	);

	return res;
};

export default function useSkusData() {
	const { mainCountry } = useContext(AppGlobalContext);
	const { accessToken } = useAuth();
	const countriesQuery = useCountriesData();
	const countryId = countriesQuery.data?.find(
		(y) => y.name.toLowerCase() === mainCountry.pais.toLowerCase()
	)?.id;

	const skusQuery = useQuery({
		queryKey: ['skus'],
		queryFn: () => fetchSkus(accessToken, countryId),
		select: (data) => {
			return data?.data;
		},
		staleTime: Infinity,
		retry: false,
		enabled: !!countryId,
	});

	return skusQuery;
}
