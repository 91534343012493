import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useBoundStore } from 'useStore';

export default function HistorySearchBar() {
	const setSearch = useBoundStore((state) => state.setForecastSearch);
	// const setPaginationModel = useBoundStore(
	// 	(state) => state.setForecastPaginationModel
	// );
	// const paginationModel = useBoundStore(
	// 	(state) => state.forecastPaginationModel
	// );
	const [searchField, setSearchField] = useState('');
	const debouncedSearchField = useDebounce(searchField, 500);

	useEffect(() => {
		setSearch(debouncedSearchField);
	}, [debouncedSearchField, setSearch]);

	return (
		<TextField
			placeholder="SKU"
			label="Buscar"
			value={searchField}
			onChange={(e) => setSearchField(e.target.value)}
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					borderRadius: '5px',

					border: '1px solid #0000003B',
				},
				'& .MuiInputBase-input': {
					backgroundColor: '#FFFFFF',
				},
				'& .MuiInputBase-root': {
					backgroundColor: '#FFFFFF',
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" />
					</InputAdornment>
				),
			}}
		/>
	);
}
