import { memo, useEffect, useState, useContext } from 'react';
import Auth0Context from 'contexts/Auth0Context';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import { Menu } from 'menu-items/widget';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { useAccessTokenJWT } from 'helpers/useAccessTokenJWT';
import { useSelector } from 'store';
import { getPermisos } from 'store/slices/Permisos';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //


function filtrarChildrenVacios(objetos) {
    return objetos
      .map(objeto => {
        // Verificar y filtrar recursivamente los children, si existen
        if (objeto.children && objeto.children.length > 0) {
          objeto.children = filtrarChildrenVacios(objeto.children);
        }
        return objeto;
      })
      .filter(objeto => {
        // Mantener el objeto si no tiene children o si children no está vacío
        return !objeto.children || objeto.children.length > 0;
      });
  }
const idToPermissions = {
    // "Pricing": "page:pricing",
    // "TEST": "page:test",
    // ... 
  };
  
  const idToPermissionsChildren = {
    /* OPCIONES MEXICO */
    "simulador": "Sourcing",

    /* OPCIONES PRICING */
    "pricing-list": "page:pricing",

    /* OPCIONES TABLAS MAESTRAS */
    "TARIFAS": "tarifas:read",

    // ... 
  };

  const idToPermissionsGrandchild = {
    /* OPCIONES MEXICO */
    "estimate-list": "Comex",
    "estimate-list-Prima": "Comex",
    "estimate-list-VS": "Comex",

    /* OPCIONES TABLAS MAESTRAS CONSTANTES*/
    "pais-region": "abml1:read",
    "carga": "abml1:read",
    "canal": "abm:read",
    "impuestos": "abm:read",

    /* OPCIONES TABLAS MAESTRAS PROVEEDORES*/
    "banco":"abm:read",
    "fwdtte":"abm:read",
    "terminal":"abm:read",
    "custodia":"abm:read",
    "flete":"abm:read",
    "truck":"abm:read",
    "despachante":"abm:read",
    "deposito":"abm:read",
    "GestDigitalDoc":"abm:read",
    "poliza":"abm:read",
    "proveedoresOem":"abm:read",

    /* OPCIONES TABLAS MAESTRAS POSICION ARANCELARIA*/
    "NCM_MEX":"abm:read",
    "NCM_ARG":"abm:read",
    // ...
  };
  
  //VIEJA FUNCION CON INCLUDES()
  // function filterItemsByPermissions(items, permissions) {

  //   console.log("items", items)
  //   // Función para filtrar recursivamente los objetos y sus children
  //   function filterRecursive(item) {
  //     // Verificar si el ID del item tiene un permiso asociado y si ese permiso no está en la lista de permisos
  //     if (idToPermissions[item.id] && !permissions.includes(idToPermissions[item.id])) {
  //       return false; // No tiene permiso, quitar el item
  //     }
  
  //     // Verificar si el path del item tiene un permiso asociado y si ese permiso no está en la lista de permisos
  //     if (idToPermissionsChildren[item.id] && !permissions.includes(idToPermissionsChildren[item.id])) {
  //       return false; // No tiene permiso, quitar el item
  //     }
  
  //     // Si el item tiene children, aplicar la función filterRecursive a cada uno de ellos
  //     if (item.children) {
  //       item.children = item.children.map(child => {
  //         // Verificar permisos para los nietos
  //         if (child.children) {
  //           child.children = child.children.filter(grandchild => {
  //             return !idToPermissionsGrandchild[grandchild.id] || permissions.includes(idToPermissionsGrandchild[grandchild.id]);
  //           });
  //         }
  //         return filterRecursive(child);
  //       }).filter(child => child !== false);
  //     }
  
  //     return item; // El item tiene permiso, mantenerlo
  //   }
  
  //   // Aplicar la función filterRecursive a cada item en el array principal
  //   let itemResult = items.map(filterRecursive).filter(item => item !== false);

  //   let res = filtrarChildrenVacios(itemResult);

  //   return res;
  // }

  //NUEVA COMPATIBLE CON NAVEGADORES
  function filterItemsByPermissions(items, permissions) {
    console.log("items", items);

    function filterRecursive(item) {
        if (idToPermissions[item.id] && permissions.indexOf(idToPermissions[item.id]) === -1) {
            return false;
        }

        if (idToPermissionsChildren[item.id] && permissions.indexOf(idToPermissionsChildren[item.id]) === -1) {
            return false;
        }

        if (item.children) {
            item.children = item.children.map(child => {
                if (child.children) {
                    child.children = child.children.filter(grandchild => {
                        return !idToPermissionsGrandchild[grandchild.id] || permissions.indexOf(idToPermissionsGrandchild[grandchild.id]) !== -1;
                    });
                }
                return filterRecursive(child);
            }).filter(child => child !== false);
        }

        return item;
    }

    let itemResult = items.map(filterRecursive).filter(item => item !== false);
    let res = filtrarChildrenVacios(itemResult);
    return res;
}


const MenuList = () => {
    
    const { contextMenu } = useContext(Auth0Context);
    // console.log("contextMenu", contextMenu)

    const permisosAuth = useAccessTokenJWT();
    const [permisos, setPermisos] = useState('Operario');
    useEffect(() => {
        setPermisos(permisosAuth);
        // menuItem.items = contextMenu.filter( item => !excludedIds.includes(item.id)); //AQUI RECORRO EL MENUITEM y muestro los q quedan disponibles segun permisos

        // menuItem.items = filterItemsByPermissions(menuItem.items, permisosAuth);
    }, [])

    const theme = useTheme();
    const { layout } = useConfig();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const getMenu = Menu();
    const handlerMenuItem = () => {
        const isFound = contextMenu.some((element) => {
            if (element.id === 'widget') {
                return true;
            }
            return false;
        });

        if (getMenu?.id !== undefined && !isFound) {
            contextMenu.splice(1, 0, getMenu);
        }
    };

    useEffect(() => {
       if(contextMenu){
         handlerMenuItem();
       }
        // eslint-disable-next-line
    }, []);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;


    if(contextMenu){
      let lastItemIndex = contextMenu.length - 1;
      let remItems = [];
      let lastItemId;
  
      if (lastItem && lastItem < contextMenu.length) {
          lastItemId = contextMenu[lastItem - 1].id;
          lastItemIndex = lastItem - 1;
          remItems = contextMenu.slice(lastItem - 1, contextMenu.length).map((item) => ({
              title: item.title,
              elements: item.children
          }));
      }
  
      const navItems = contextMenu.slice(0, lastItemIndex + 1).map((item) => {
          switch (item.type) {
              case 'group':
                  return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
              default:
                  return (
                      <Typography key={item.id} variant="h6" color="error" align="center">
                          {/* Menu Items Error */}
                      </Typography>
                  );
          }
      });
  
      return <>{navItems}</>;
    }else{
      return <>{contextMenu}</>;

    }

};

export default memo(MenuList);
