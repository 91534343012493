import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useBoundStore } from 'useStore';

export default function HistoryDatePicker() {
	const selectedDate = useBoundStore((state) => state.forecastSelectedDate);
	const setSelectedDate = useBoundStore(
		(state) => state.setForecastSelectedDate
	);

	const handleChange = (newValue) => {
		if (!newValue) return;
		setSelectedDate(newValue.format('YYYY-MM-DD'));
	};

	const onClear = () => {
		setSelectedDate('IGNORE');
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label="YYYY-MM-DD"
				inputFormat="YYYY-MM-DD"
				value={selectedDate === 'IGNORE' ? null : selectedDate}
				onChange={handleChange}
				renderInput={(params) => (
					<div style={{ position: 'relative', display: 'inline-block' }}>
						<TextField {...params} />
						{selectedDate !== 'IGNORE' && (
							<IconButton
								style={{
									position: 'absolute',
									top: '6px',
									margin: 'auto',
									right: '2rem',
								}}
								onClick={() => onClear()}
							>
								<ClearIcon />
							</IconButton>
						)}
					</div>
				)}
				slotProps={{ field: { clearable: true } }}
			/>
		</LocalizationProvider>
	);
}
