import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import Auth0Context from 'contexts/Auth0Context';
// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
// import { useAccessTokenJWT } from 'helpers/useAccessTokenJWT';

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
  
  const { contextRoutes } = useContext(Auth0Context);

  return useRoutes([LoginRoutes, AuthenticationRoutes, contextRoutes ? contextRoutes : MainRoutes]);

}