import {
	Button,
	Checkbox,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { useForecastAlert } from 'contexts/ForecastAlertContext';
import { useForecastFlow } from 'contexts/ForecastFlowContext';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import * as Yup from 'yup';

const CreateFlowSchema = Yup.object().shape({
	desde: Yup.string().required('Campo requerido'),
	hasta: Yup.string().required('Campo requerido'),
	estadofsd_id: Yup.string().required('Campo requerido'),
	applyto: Yup.array().of(Yup.string()).required('Campo requerido').min(1),
});

export default function CreateFlowForm() {
	const { employees: owners } = useForecastFlow();
	const { accessToken, user } = useAuth();
	const { fetchForecastFlowData } = useForecastFlow();
	const { setAlertState } = useForecastAlert();
	const formik = useFormik({
		initialValues: {
			desde: null,
			hasta: null,
			estadofsd_id: '',
			applyto: [],
		},
		validationSchema: CreateFlowSchema,
		onSubmit: async (values) => {
			try {
				const employee = await axios.get(
					`${process.env.REACT_APP_API_URL}/forecast/employeebymail/${user.email}`,

					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				);

				await axios.post(
					`${process.env.REACT_APP_API_URL}/forecast/autoaprochk`,
					{
						...values,
						detalle: 'Aprobación automática',
						creado: new Date().toISOString(),
						creador_id: employee.data.id,
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				);

				formik.resetForm();

				setAlertState({
					open: true,
					message: 'Los cambios ya fueron actualizados.',
					title: 'Flujo creado',
					severity: 'success',
				});

				await fetchForecastFlowData();
			} catch (error) {
				console.error('error', error);
				setAlertState({
					open: true,
					title: 'No se pudo crear el flujo',
					message: error?.response?.data?.includes('OVERLAPED')
						? 'Una o mas reglas se superponen con uno o mas de los empleados y periodo solicitado'
						: 'Algo salio mal creando el flujo',
					severity: 'error',
				});
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<Stack
				sx={{
					padding: '16px 24px',
				}}
			>
				<Grid
					container
					spacing={{
						xs: '25px',
						md: '100px',
					}}
					sx={{
						paddingBottom: '16px',
					}}
				>
					<Grid item xs={12} md={6}>
						<Grid container rowGap="24px">
							<Grid item xs={4}>
								<label htmlFor="desde">Desde</label>
							</Grid>
							<Grid item xs={8}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Desde"
										id="desde"
										name="desde"
										inputFormat="YYYY-MM-DD"
										value={formik.values.desde}
										onChange={(newValue) => {
											if (!newValue) return;

											formik.setFieldValue(
												'desde',
												newValue.format('YYYY-MM-DD')
											);
										}}
										disablePast
										onBlur={formik.handleBlur}
										renderInput={(params) => (
											<TextField
												{...params}
												error={
													formik.touched.desde && Boolean(formik.errors.desde)
												}
												fullWidth
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={4}>
								<label htmlFor="estadofsd_id">Acción</label>
							</Grid>
							<Grid item xs={8}>
								<FormControl fullWidth>
									<InputLabel id="estadofsd_id">Acción</InputLabel>
									<Select
										labelId="estadofsd_id"
										id="estadofsd_id"
										value={formik.values.estadofsd_id}
										label="Acción"
										onChange={(e) => {
											formik.setFieldValue('estadofsd_id', e.target.value);
										}}
										error={
											formik.touched.estadofsd_id &&
											Boolean(formik.errors.estadofsd_id)
										}
									>
										<MenuItem value={2}>Aprobar</MenuItem>
										<MenuItem value={3}>Rechazar</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container rowGap="24px">
							<Grid item xs={4}>
								<label htmlFor="hasta">Hasta</label>
							</Grid>
							<Grid item xs={8}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Hasta"
										id="hasta"
										name="hasta"
										inputFormat="YYYY-MM-DD"
										value={formik.values.hasta}
										onChange={(newValue) => {
											if (!newValue) return;

											formik.setFieldValue(
												'hasta',
												newValue.format('YYYY-MM-DD')
											);
										}}
										disablePast
										minDate={dayjs(formik.values.desde).add(1, 'day')}
										onBlur={formik.handleBlur}
										renderInput={(params) => (
											<TextField
												{...params}
												error={
													formik.touched.hasta && Boolean(formik.errors.hasta)
												}
												fullWidth
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={4}>
								<label htmlFor="applyto">Usuarios</label>
							</Grid>
							<Grid item xs={8}>
								<FormControl fullWidth>
									<InputLabel id="applyto">Usuarios</InputLabel>
									<Select
										labelId="applyto"
										multiple
										id="applyto"
										value={formik.values.applyto}
										label="Usuarios"
										onChange={(e) => {
											const value = e.target.value;
											formik.setFieldValue(
												'applyto',
												typeof value === 'string' ? value.split(',') : value
											);
										}}
										renderValue={(selected) =>
											selected
												.map((s) => owners.find((owner) => owner.id === s).name)
												.join(', ')
										}
										error={
											formik.touched.applyto && Boolean(formik.errors.applyto)
										}
									>
										{owners.map((owner) => (
											<MenuItem key={owner.id} value={owner.id}>
												<Checkbox
													checked={formik.values.applyto.indexOf(owner.id) > -1}
												/>
												<ListItemText primary={owner.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Divider />

				<Stack
					direction="row"
					spacing={2}
					justifyContent="flex-end"
					sx={{
						paddingTop: '16px',
					}}
				>
					<Button onClick={() => formik.resetForm()}>Cancelar</Button>
					<Button type="submit" variant="contained">
						Aceptar
					</Button>
				</Stack>
			</Stack>
		</form>
	);
}
