import { create } from 'zustand';
import { pricingStoreSlice } from 'views/Components/Pricing/v2/Main/pricingStoreSlice';
import { persist } from 'zustand/middleware';
import { forecastStoreSlice } from 'views/Components/Forecast/forecastStoreSlice';

export const useBoundStore = create(
	persist(
		(...a) => ({
			...pricingStoreSlice(...a),
			...forecastStoreSlice(...a),
		}),
		{
			name: 'store',
			partialize: (state) => ({
				pricingSelectedOwners: state.pricingSelectedOwners,
				pricingSelectedDomains: state.pricingSelectedDomains,
			}),
		}
	)
);
