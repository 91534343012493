import PropTypes from 'prop-types';
import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import jwt_decode from 'jwt-decode';
import { mainFilter } from './contexts-config/pathFilter';
import { filterItemsByPermissions } from './contexts-config/menuFilter';
import MainRoutes from 'routes/MainRoutes';
import menuItems from 'menu-items';

// third-party
import { Auth0Client } from '@auth0/auth0-spa-js';

// project imports
import Loader from 'ui-component/Loader';

import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import jwt from 'jsonwebtoken';
import { AppGlobalContext, countries } from './GlobalContext';

// constant
let auth0Client;

const initialState = {
	isLoggedIn: false,
	isInitialized: false,
	user: null,
	accessToken: null, // agrego el token al inicial state
};

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext(null);

export const Auth0Provider = ({ children }) => {
	const { mainCountry, setCountry } = useContext(AppGlobalContext);
	const [contextPermissions, setContextPermissions] = useState(null);
	const [contextRoutes, setContexRoutes] = useState(null);
	const [contextMenu, setContextMenu] = useState(null);

	const [state, dispatch] = useReducer(accountReducer, initialState);
	const [user, setUser] = useState({});

	useEffect(() => {
		const init = async () => {
			try {
				auth0Client = new Auth0Client({
					clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
					domain: process.env.REACT_APP_AUTH0_DOMAIN,
					authorizationParams: {
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
						redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL, // PARA PRODUCCION
						// redirect_uri: window.location.origin   // PARA TRABAJAR LOCAL
					},
				});

				await auth0Client.checkSession();
				const isLoggedIn = await auth0Client.isAuthenticated();

				if (isLoggedIn) {
					const user = await auth0Client.getUser();
					const accessToken = await auth0Client.getTokenSilently(); // Get the access token
					const tokenDesemcriptado = await jwt_decode(accessToken);
					const permisos = tokenDesemcriptado.permissions;

					setContextPermissions(permisos);

					const countryPermissions = permisos.filter((p) =>
						p.includes('Country')
					);

					if (countryPermissions.length > 0) {
						const isMainCountryInPermissions = countryPermissions.some(
							(c) => c.split(':')[1] === mainCountry.pais.toLowerCase()
						);

						if (!isMainCountryInPermissions) {
							setCountry(
								countries.find(
									(c) =>
										c.pais.toLowerCase() === countryPermissions[0].split(':')[1]
								)
							);
						}
					} else {
						setCountry(
							countries.find((c) => c.pais.toLowerCase() === 'argentina')
						);
					}

					const rutas = mainFilter(MainRoutes, permisos);
					setContexRoutes(rutas);

					const menu = filterItemsByPermissions(
						menuItems?.items ? menuItems?.items : [],
						permisos
					);

					// console.log("menu", menuItems)
					// console.log("menuF", menu)

					setContextMenu(menu);
					setUser(user);
					dispatch({
						type: LOGIN,
						payload: {
							isLoggedIn: true,
							user: {
								id: user?.sub,
								email: user?.email,
								// SE AGREGAN TODOS LOS DATOS NECESARIOS PARA TRABAJAR EL USUARIO
								roll: user,
								avatar: user?.picture,
								name: user?.name,
								tier: 'Premium',
								email_verified: user?.email_verified,
								family_name: user?.family_name,
								given_name: user?.given_name,
								nickname: user?.nickname,
								locale: user?.locale,
								permissions: permisos,
							},
							accessToken: accessToken, // Almacenar el token de acceso en el estado
						},
					});
				} else {
					dispatch({
						type: LOGOUT,
					});
				}
			} catch (err) {
				dispatch({
					type: LOGOUT,
				});
			}
		};

		init();
	}, []);

	const login = async (options) => {
		try {
			await auth0Client.loginWithPopup(options);
			const isLoggedIn = await auth0Client.isAuthenticated();
			if (isLoggedIn) {
				const user = await auth0Client.getUser();
				const accessToken = await auth0Client.getTokenSilently(); // optenemos el token
				const tokenDesemcriptado = await jwt_decode(accessToken);
				const permisos = tokenDesemcriptado.permissions;
				// console.log("permisos", permisos);
				setContextPermissions(permisos);

				const countryPermissions = permisos.filter((p) =>
					p.includes('Country')
				);

				if (countryPermissions.length > 0) {
					const isMainCountryInPermissions = countryPermissions.some(
						(c) => c.split(':')[1] === mainCountry.pais.toLowerCase()
					);

					if (!isMainCountryInPermissions) {
						setCountry(
							countries.find(
								(c) =>
									c.pais.toLowerCase() === countryPermissions[0].split(':')[1]
							)
						);
					}
				} else {
					setCountry(
						countries.find((c) => c.pais.toLowerCase() === 'argentina')
					);
				}

				const rutas = mainFilter(MainRoutes, permisos);
				setContexRoutes(rutas);

				const menu = filterItemsByPermissions(
					menuItems?.items ? menuItems?.items : [],
					permisos
				);
				setContextMenu(menu);

				// console.log("menuF", menuItems)
				setUser(user);

				dispatch({
					type: LOGIN,
					payload: {
						isLoggedIn: true,
						user: {
							id: user?.sub,
							email: user?.email,
							// SE AGREGAN TODOS LOS DATOS NECESARIOS PARA TRABAJAR EL USUARIO
							roll: user,
							avatar: user?.picture,
							name: user?.name,
							tier: 'Premium',
							email_verified: user?.email_verified,
							family_name: user?.family_name,
							given_name: user?.given_name,
							nickname: user?.nickname,
							locale: user?.locale,
							permissions: permisos,
						},
						accessToken: accessToken,
					},
				});
			}
		} catch (error) {
			const fechaActual = new Date();
			const fechaExpiracion = new Date(fechaActual.getTime() + 30 * 1000); // 30 segundos en milisegundos
			const token = await jwt.sign(
				{ exp: Math.floor(fechaExpiracion / 1000) },
				process.env.REACT_APP_JWTKEY_ACCESSDENIED
			);

			auth0Client.logout({
				logoutParams: {
					returnTo: `${process.env.REACT_APP_AUTHLOGOUT_RETURNTO}?tstamp=${token}`,
				},
			});
		}
	};

	const logout = async () => {
		// await auth0Client.logout();
		await auth0Client.logout({
			logoutParams: {
				returnTo: `${process.env.REACT_APP_USER_AUTHLOGOUT_RETURNTO}`,
			},
		});
		// dispatch({
		//     type: LOGOUT
		// });
	};

	const resetPassword = () => {};

	const updateProfile = () => {};

	if (state.isInitialized !== undefined && !state.isInitialized) {
		return <Loader />;
	}

	return (
		<Auth0Context.Provider
			value={{
				...state,
				login,
				logout,
				resetPassword,
				updateProfile,
				contextPermissions,
				contextMenu,
				contextRoutes,
			}}
		>
			{children}
		</Auth0Context.Provider>
	);
};

Auth0Provider.propTypes = {
	children: PropTypes.node,
};

export default Auth0Context;
