// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

const icons = {
	IconDashboard,
	IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const ServiceOperation = {
	id: 'ServiceOperation',
	title: <FormattedMessage id="service-operation" />,
	icon: icons.IconDashboard,
	type: 'group',
	children: [
		{
			id: 'ServiceOperationAdd',
			title: <FormattedMessage id="service-operation-ingreso" />,
			type: 'item',
			url: '/service-operation/ingresar',
			icon: AddProductIcon,
			breadcrumbs: false,
		},
		{
			id: 'ServiceOperationAdmin',
			title: <FormattedMessage id="service-operation-admin" />,
			type: 'item',
			url: '/service-operation/admin',
			icon: AdminIcon,
			breadcrumbs: false,
		},
		{
			id: 'ServiceOperationTaller',
			title: <FormattedMessage id="service-operation-taller" />,
			type: 'item',
			url: '/service-operation/services_operation',
			icon: ServiceIcon,
			breadcrumbs: false,
		},
		{
			id: 'ServiceOperationPanelAdmin',
			title: <FormattedMessage id="service-operation-panel-admin" />,
			type: 'item',
			url: '/service-operation/admin/panel',
			icon: AdminPanelIcon,
			breadcrumbs: false,
		},
		// OTRAS RUTAS
	],
};

export default ServiceOperation;

function AddProductIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>add product</title>

			<path
				d="M21.5625 7.5H17.4375V6.9375C17.4364 5.51902 16.8816 4.157 15.8914 3.14141C14.9011 2.12582 13.5535 1.53689 12.1355 1.5H11.8645C10.4465 1.53689 9.09893 2.12582 8.10865 3.14141C7.11836 4.157 6.56361 5.51902 6.5625 6.9375V7.5H2.4375C2.38777 7.5 2.34008 7.51975 2.30492 7.55492C2.26975 7.59008 2.25 7.63777 2.25 7.6875V21.75C2.25 21.9489 2.32902 22.1397 2.46967 22.2803C2.61032 22.421 2.80109 22.5 3 22.5H21C21.1989 22.5 21.3897 22.421 21.5303 22.2803C21.671 22.1397 21.75 21.9489 21.75 21.75V7.6875C21.75 7.63777 21.7302 7.59008 21.6951 7.55492C21.6599 7.51975 21.6122 7.5 21.5625 7.5ZM8.4375 6.98438C8.4375 5.02312 10.0036 3.39562 11.9648 3.375C12.4355 3.37085 12.9023 3.45991 13.3384 3.63704C13.7744 3.81417 14.1711 4.07587 14.5056 4.40704C14.84 4.73821 15.1056 5.13231 15.287 5.56662C15.4684 6.00092 15.562 6.46683 15.5625 6.9375V7.5H8.4375V6.98438ZM15.75 15.75H12.75V18.75H11.25V15.75H8.25V14.25H11.25V11.25H12.75V14.25H15.75V15.75Z"
				fill="currentColor"
			/>
		</svg>
	);
}

function AdminIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>admin</title>

			<path
				d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 4.9C12.5933 4.9 13.1734 5.07595 13.6667 5.40559C14.1601 5.73524 14.5446 6.20377 14.7716 6.75195C14.9987 7.30013 15.0581 7.90333 14.9424 8.48527C14.8266 9.06721 14.5409 9.60176 14.1213 10.0213C13.7018 10.4409 13.1672 10.7266 12.5853 10.8424C12.0033 10.9581 11.4001 10.8987 10.8519 10.6716C10.3038 10.4446 9.83524 10.0601 9.50559 9.56671C9.17595 9.07336 9 8.49334 9 7.9C9 7.10435 9.31607 6.34129 9.87868 5.77868C10.4413 5.21607 11.2044 4.9 12 4.9ZM12 12.8C14 12.8 18 13.89 18 15.88C17.3432 16.8701 16.4516 17.6823 15.4047 18.2442C14.3578 18.806 13.1881 19.1 12 19.1C10.8119 19.1 9.64218 18.806 8.59527 18.2442C7.54836 17.6823 6.65677 16.8701 6 15.88C6 13.89 10 12.8 12 12.8Z"
				fill="currentColor"
			/>
		</svg>
	);
}

function ServiceIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>service</title>

			<path
				d="M22.9 21.2L18.8 17.1C19.2 16.1 19 14.8 18.1 14C17.2 13.1 15.9 12.9 14.8 13.4L16.7 15.3L15.3 16.7L13.3 14.7C12.8 15.8 13 17.1 13.9 18.1C14.8 19 16 19.2 17 18.8L21.1 22.9C21.3 23.1 21.6 23.1 21.7 22.9L22.7 21.9C23 21.6 23 21.3 22.9 21.2ZM13 20H2V18C2 15.8 5.6 14 10 14C10.5 14 11 14 11.4 14.1C11.1 14.7 11 15.3 11 16C11 17.6 11.8 19.1 13 20ZM10 4C7.8 4 6 5.8 6 8C6 10.2 7.8 12 10 12C12.2 12 14 10.2 14 8C14 5.8 12.2 4 10 4Z"
				fill="currentColor"
			/>
		</svg>
	);
}

function AdminPanelIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>admin</title>

			<path
				d="M17 22C15.6167 22 14.4377 21.5123 13.463 20.537C12.4883 19.5617 12.0007 18.3827 12 17C11.9993 15.6173 12.487 14.4383 13.463 13.463C14.439 12.4877 15.618 12 17 12C18.382 12 19.5613 12.4877 20.538 13.463C21.5147 14.4383 22.002 15.6173 22 17C21.998 18.3827 21.5103 19.562 20.537 20.538C19.5637 21.514 18.3847 22.0013 17 22ZM12 22C9.68333 21.4167 7.77067 20.0873 6.262 18.012C4.75333 15.9367 3.99933 13.6327 4 11.1V5L12 2L20 5V10.675C19.5667 10.4583 19.079 10.2917 18.537 10.175C17.995 10.0583 17.4827 10 17 10C15.0667 10 13.4167 10.6833 12.05 12.05C10.6833 13.4167 10 15.0667 10 17C10 18.0333 10.196 18.9667 10.588 19.8C10.98 20.6333 11.4757 21.3583 12.075 21.975C12.0583 21.975 12.046 21.9793 12.038 21.988C12.03 21.9967 12.0173 22.0007 12 22ZM17 17C17.4167 17 17.771 16.8543 18.063 16.563C18.355 16.2717 18.5007 15.9173 18.5 15.5C18.4993 15.0827 18.3537 14.7287 18.063 14.438C17.7723 14.1473 17.418 14.0013 17 14C16.582 13.9987 16.228 14.1447 15.938 14.438C15.648 14.7313 15.502 15.0853 15.5 15.5C15.498 15.9147 15.644 16.269 15.938 16.563C16.232 16.857 16.586 17.0027 17 17ZM17 20C17.5167 20 17.9917 19.879 18.425 19.637C18.8583 19.395 19.2083 19.0743 19.475 18.675C19.1083 18.4583 18.7167 18.2917 18.3 18.175C17.8833 18.0583 17.45 18 17 18C16.55 18 16.1167 18.0583 15.7 18.175C15.2833 18.2917 14.8917 18.4583 14.525 18.675C14.7917 19.075 15.1417 19.396 15.575 19.638C16.0083 19.88 16.4833 20.0007 17 20Z"
				fill="currentColor"
			/>
		</svg>
	);
}
