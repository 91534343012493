import { Box, Stack } from '@mui/material';
import CalendarCarousel from './CalendarCarousel';
import EventsTable from './EventsTable';

import { useEvents } from 'contexts/EventsContext';
import Toolbar from './Toolbar';

export default function EventsTab() {
	const { data: events, loading, error } = useEvents();

	return loading ? (
		<div>Loading...</div>
	) : (
		<Stack spacing="24px">
			<CalendarCarousel events={events} />
			<Toolbar campaignsQuantity={events.length} />
			<Box>{events && <EventsTable events={events} />}</Box>
		</Stack>
	);
}
