export const forecastStoreSlice = (set) => ({
	forecastSelectedOwner: 0,
	forecastSelectedDate: 'IGNORE',
	forecastSearch: '',
	forecastPaginationModel: {
		pageSize: 25,
		page: 0,
	},

	setForecastSelectedOwner: (selectedOwner) =>
		set(() => ({ forecastSelectedOwner: selectedOwner })),
	setForecastSelectedDate: (selectedDate) =>
		set(() => ({ forecastSelectedDate: selectedDate })),
	setForecastSearch: (search) => set(() => ({ forecastSearch: search })),
	setForecastPaginationModel: (paginationModel) =>
		set(() => ({ forecastPaginationModel: paginationModel })),
});
