// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConstructionIcon from '@mui/icons-material/Construction';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const ServiceOperation = {
    id: 'ServiceOperation',
    title: <FormattedMessage id="service-operation" />,
    icon: icons.IconDashboard,
    type: 'group',
    children:[
        {
            id: 'ServiceOperationAdd',
            title: <FormattedMessage id="service-operation-ingreso" />,
            type: 'item',
            url: '/service-operation/ingresar',
            icon: LocalMallIcon,
            breadcrumbs: false
        },
        {
            id: 'ServiceOperationAdmin',
            title: <FormattedMessage id="service-operation-admin" />,
            type: 'item',
            url: '/service-operation/admin',
            icon: AccountCircleIcon,
            breadcrumbs: false
        },
        {
            id: 'ServiceOperationTaller',
            title: <FormattedMessage id="service-operation-taller" />,
            type: 'item',
            url: '/service-operation/services_operation',
            icon: ConstructionIcon,
            breadcrumbs: false
        },
        {
            id: 'ServiceOperationPanelAdmin',
            title: <FormattedMessage id="service-operation-panel-admin" />,
            type: 'item',
            url: '/service-operation/admin/panel',
            icon: AdminPanelSettingsIcon,
            breadcrumbs: false
        },
        // OTRAS RUTAS
    ]
};

export default ServiceOperation;
