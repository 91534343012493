import { FormattedMessage } from "react-intl";
import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AddchartIcon from "@mui/icons-material/Addchart";

const icons = {
  IconDashboard,
  IconDeviceAnalytics,
};

const LandedCost = {
  id: "costs",
  title: <FormattedMessage id="landed-cost" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "winners",
      title: <FormattedMessage id="landed-cost-winners" />,
      type: "item",
      url: "/landed-cost/Winners",
      icon: ImportContactsIcon,
      breadcrumbs: false,
    },
    {
      id: "dailyCost",
      title: <FormattedMessage id="landed-cost-daily-cost" />,
      type: "item",
      url: "/landed-cost/DailyCost",
      icon: ImportContactsIcon,
      breadcrumbs: false,
    },
    {
      id: "loadEntry",
      title: <FormattedMessage id="landed-cost-load-entry" />,
      type: "item",
      url: "/landed-cost/LoadEntry",
      icon: AddchartIcon,
      breadcrumbs: false,
    },
    {
      id: "loadEntries",
      title: <FormattedMessage id="landed-cost-load-entries" />,
      type: "item",
      url: "/landed-cost/LoadEntries",
      icon: AddchartIcon,
      breadcrumbs: false,
    },
  ],
};

export default LandedCost;
