import useEmployeesData from './employeesQuery';
import useCountriesData from './countriesQuery';
import useUsersData from './usersQuery';
import useSkusData from './skusQuery';
import useForecastHistoryData from './forecastHistoryQuery';
import useMotivesData from './motivesQuery';

export {
	useEmployeesData,
	useCountriesData,
	useUsersData,
	useSkusData,
	useForecastHistoryData,
	useMotivesData,
};
