import { Stack, Typography } from '@mui/material';

export default function HistoryCount({ count }) {
	return (
		<Stack
			direction="row"
			columnGap={2}
			rowGap={1}
			alignItems="center"
			flexWrap="wrap"
		>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color: 'rgba(0, 0, 0, 0.60)',
				}}
			>
				Total de cambios: {count}
			</Typography>
		</Stack>
	);
}
