import { Box } from '@mui/material';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import ActionField from './ActionField';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForecastFlow } from 'contexts/ForecastFlowContext';
import { useForecastAlert } from 'contexts/ForecastAlertContext';

export default function FlowTable({ data, loading }) {
	const { deleteForecastFlow } = useForecastFlow();
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0,
	});
	const { setAlertState } = useForecastAlert();

	const deleteFlow = useCallback(
		(id) => async () => {
			await deleteForecastFlow(id);
			setAlertState({
				open: true,
				message: 'Los cambios ya fueron actualizados.',
				title: 'Flujo eliminado',
				severity: 'success',
			});
		},
		[deleteForecastFlow, setAlertState]
	);

	const columns = useMemo(
		() => [
			{
				field: 'desde',
				headerName: 'Desde',
				headerClassName: 'header border-bottom',
				minWidth: 90,
				flex: 1,
				resizable: false,
				sortable: false,
				headerAlign: 'center',
				align: 'center',
				valueFormatter: (value) => {
					if (value == null) {
						return '';
					}
					return new Date(value).toLocaleDateString();
				},
			},
			{
				field: 'hasta',
				headerName: 'Hasta',
				headerClassName: 'header border-bottom',
				minWidth: 90,
				flex: 1,
				resizable: false,
				sortable: false,
				headerAlign: 'center',
				align: 'center',
				valueFormatter: (value) => {
					if (value == null) {
						return '';
					}
					return new Date(value).toLocaleDateString();
				},
			},
			{
				field: 'estadofsd_id',
				headerName: 'Acción',
				headerClassName: 'header border-bottom',
				renderCell: (params) => {
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<ActionField action={params.value} />
						</Box>
					);
				},
				minWidth: 90,
				flex: 1,
				resizable: false,
				sortable: false,
				headerAlign: 'center',
				align: 'center',
			},
			{
				field: 'users',
				headerName: 'Usuarios',
				headerClassName: 'header border-bottom',
				valueFormatter: (value) => {
					if (value == null) {
						return '';
					}
					return value.join(', ');
				},
				minWidth: 90,
				flex: 1,
				resizable: false,
				sortable: false,
				headerAlign: 'center',
				align: 'center',
			},
			{
				field: 'actions',
				type: 'actions',
				headerClassName: 'header border-bottom',
				minWidth: 90,
				flex: 1,
				getActions: (params) => [
					<GridActionsCellItem
						key="delete"
						icon={<DeleteIcon />}
						label="Delete"
						onClick={deleteFlow(params.id)}
					/>,
				],
			},
		],
		[deleteFlow]
	);

	return (
		<DataGridPro
			loading={loading}
			rows={data}
			getRowId={(row) => row.id}
			columns={columns}
			pageSize={5}
			rowsPerPageOptions={[5]}
			getDetailPanelHeight={() => 'auto'}
			disableColumnMenu
			pagination
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			pageSizeOptions={[5, 10, 25]}
			disableRowSelectionOnClick
			disableVirtualization
			sx={{
				'& .MuiDataGrid-cell': {
					borderBottom: '1px solid #E0E0E0',
				},

				'& .border-right': {
					borderRight: '1px solid #E0E0E0',
				},
				'& .border-bottom': {
					borderBottom: '1px solid #E0E0E0',
				},
				'& .MuiDataGrid-columnHeader--emptyGroup': {
					borderBottom: '1px solid #E0E0E0',
				},
				'& .header': {
					backgroundColor: '#f5f5f5',
				},

				'& .MuiDataGrid-scrollbar--vertical': {
					display: 'none',
				},
				'& .MuiDataGrid-filler': {
					height: 'fit-content !important',
				},
				'& .MuiDataGrid-main': {
					paddingBottom: '16px !important',
				},
				'& .MuiToolbar-root': {
					padding: '0 !important',
				},

				height: 'calc(100vh - 340px)',
				// height: '100%',
			}}
		/>
	);
}
