import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { useBoundStore } from 'useStore';
import useCountriesData from './countriesQuery';
import { useCallback, useContext } from 'react';
import { AppGlobalContext } from 'contexts/GlobalContext';
import useEmployeesData from './employeesQuery';
import useSkusData from './skusQuery';
import useMotivesData from './motivesQuery';

const fetchForecastHistory = async ({
	accessToken,
	selectedDate,
	countryId,
	selectedOwner,
	page,
	pageSize,
	search,
}) => {
	const res = await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/forecast/historico?countryid=${countryId}&pagesize=${pageSize}&pagenum=${page}&creadorid_maestro=${selectedOwner}${
			selectedDate === 'IGNORE' ? '' : `&fecha=${selectedDate}`
		}${search === '' ? '' : `&productname=${search}`}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				accept: '*/*',
			},
		}
	);

	return res;
};

export default function useForecastHistoryData() {
	const { mainCountry } = useContext(AppGlobalContext);
	const { accessToken } = useAuth();
	const paginationModel = useBoundStore(
		(state) => state.forecastPaginationModel
	);
	const countriesQuery = useCountriesData();
	const employeesQuery = useEmployeesData();
	const skusQuery = useSkusData();
	const motivesQuery = useMotivesData();

	const countryId = countriesQuery.data?.find(
		(y) => y.name.toLowerCase() === mainCountry.pais.toLowerCase()
	)?.id;

	const selectedOwner = useBoundStore((state) => state.forecastSelectedOwner);
	const selectedDate = useBoundStore((state) => state.forecastSelectedDate);
	const search = useBoundStore((state) => state.forecastSearch);

	const complementHistoryData = useCallback(
		(data) => {
			const items =
				data?.data?.data?.map((item) => ({
					...item,
					sku: getSkuName(skusQuery?.data, item?.product_id),
					category_manager: getCategoryManagerName(
						employeesQuery?.data,
						item?.cm_creador_id
					),
					motive: getMotive(
						motivesQuery?.data,
						item?.years_month?.[0]?.months?.[0]?.data?.motivo_id
					),
					id: `${item?.hdate}-${item?.country_id}-${item?.product_id}-${item?.cm_creador_id}-${item?.marketplace_id}`,
				})) || [];
			const count = data?.data?.count;

			return { items, count };
		},
		[employeesQuery.data, skusQuery.data, motivesQuery.data]
	);

	const forecastHistoryQuery = useQuery({
		queryKey: [
			'forecastHistory',
			{
				page: paginationModel.page,
				pageSize: paginationModel.pageSize,
				countryId,
				selectedOwner,
				selectedDate,
				search,
			},
		],
		queryFn: () =>
			fetchForecastHistory({
				accessToken,
				page: paginationModel.page,
				pageSize: paginationModel.pageSize,
				countryId,
				selectedOwner,
				selectedDate,
				search,
			}),
		select: complementHistoryData,
		staleTime: Infinity,
		retry: false,
		enabled:
			countriesQuery.status === 'success' &&
			employeesQuery.status === 'success' &&
			skusQuery.status === 'success' &&
			motivesQuery.status === 'success',
		placeholderData: (previousData) => {
			return previousData;
		},
	});

	return forecastHistoryQuery;
}

function getSkuName(skus, skuId) {
	return skus?.find((sku) => sku?.id === skuId)?.sku;
}

function getCategoryManagerName(employees, cmId) {
	return employees?.find((category) => category?.id === cmId)?.name;
}

function getMotive(motives, motiveId) {
	return motives?.find((m) => m?.id === motiveId)?.motivo_str;
}
