import styled from '@emotion/styled';
import {
	Box,
	Button,
	Checkbox,
	ClickAwayListener,
	Collapse,
	Divider,
	FormControlLabel,
	FormGroup,
	List,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography,
	tooltipClasses,
} from '@mui/material';
import { Stack } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useBoundStore } from 'useStore';
import { useUsersData } from './Queries';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		placement="bottom-start"
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		maxWidth: '400px',

		borderRadius: '0px',
	},
}));

function Options({ closeTooltip }) {
	const [open, setOpen] = useState(null);
	const selectedOwner = useBoundStore((state) => state.forecastSelectedOwner);
	const setSelectedOwner = useBoundStore(
		(state) => state.setForecastSelectedOwner
	);
	const usersQuery = useUsersData();

	const owners = usersQuery.data;

	const handleClick = (filter) => {
		setOpen((prev) => (prev === filter ? null : filter));
	};

	const handleClearAndApplyFilters = () => {
		setSelectedOwner(0);
		closeTooltip();
		setOpen(null);
	};

	return (
		<List
			sx={{ width: '340px', maxWidth: 340, bgcolor: 'background.paper' }}
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<Stack
					direction="row"
					spacing={3}
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography
						sx={{
							pl: 2,
							color: 'rgba(0, 0, 0, 0.87)',
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontSize: '16px',
							lineHeight: '24px',
							fontWeight: '700',
							letterSpacing: '0.15px',
						}}
					>
						Filtrar por:
					</Typography>
					<Button
						color="secondary"
						onClick={handleClearAndApplyFilters}
						disabled={selectedOwner === 0}
					>
						Limpiar filtros
					</Button>
				</Stack>
			}
		>
			<Divider />

			{owners && (
				<>
					<ListItemButton onClick={() => handleClick('owner')}>
						<ListItemText primary="Category Manager" />
						{open === 'owner' ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={open === 'owner'} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<Box
								sx={{
									maxHeight: '200px',
									overflowY: 'auto',
								}}
							>
								<FormGroup sx={{ pl: 2 }}>
									{owners.map((owner) => (
										<FormControlLabel
											key={owner.id}
											control={
												<Checkbox
													color="secondary"
													checked={selectedOwner === owner.id}
													onChange={() =>
														setSelectedOwner(
															selectedOwner === owner.id ? 0 : owner.id
														)
													}
												/>
											}
											label={`${owner.name} ${owner.lastname}`}
										/>
									))}
								</FormGroup>
							</Box>
						</List>
					</Collapse>
				</>
			)}

			<Divider />

			<Button
				color="secondary"
				onClick={closeTooltip}
				sx={{
					mt: 2,
				}}
			>
				Cerrar
			</Button>
		</List>
	);
}

export default function HistoryFiltersOptions() {
	const [open, setOpen] = useState(false);

	useUsersData();

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleToggleTooltip = () => {
		setOpen((prev) => !prev);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<LightTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={<Options closeTooltip={handleTooltipClose} />}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							padding: '12px',
							width: '182px',
							borderRadius: '4px',
							border: '1px solid rgba(0, 0, 0, 0.23)',
							cursor: 'pointer',
						}}
						onClick={handleToggleTooltip}
					>
						<Typography
							sx={{
								color: '#00000099',
								fontSize: '16px',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							Filtrar por
						</Typography>
						<FilterListIcon
							sx={{
								fill: '#0000008F',
								width: '24px',
								height: '24px',
							}}
						/>
					</Stack>
				</LightTooltip>
			</div>
		</ClickAwayListener>
	);
}
