import { Typography } from '@mui/material';

export default function StatusField({ status }) {
	const statusName = status === 2 ? 'Aprobado' : 'Rechazado';
	const statusStyles =
		status === 2
			? {
					color: '#1B5E20',
					backgroundColor: '#E1FCEF',
			  }
			: {
					color: '#C62828',
					backgroundColor: '#FBD6D6',
			  };
	return (
		<Typography
			sx={{
				...statusStyles,
				borderRadius: '4px',
				padding: '0px 4px',
				textAlign: 'center',
				fontSize: '12px',
				fontWeight: '400',
				lineHeight: '17px',
				letterSpacing: '0.17px',
				fontFamily: 'Roboto',
			}}
		>
			{statusName}
		</Typography>
	);
}
