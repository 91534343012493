import { ButtonBase } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditCampaignModal from './CreateEditCampaignModal';
import { useState } from 'react';

export default function EditCampaign({ campaign }) {
	const [open, setOpen] = useState(false);
	console.log('campaign', campaign);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<ButtonBase onClick={handleOpen} variant="contained">
				<EditIcon sx={{ color: '#354887' }} />
			</ButtonBase>
			<CreateEditCampaignModal
				open={open}
				onClose={handleClose}
				campaign={campaign}
			/>
		</>
	);
}
