// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Pricing = {
    id: 'Pricing',
    title: <FormattedMessage id="language-pricing" />,
    icon: icons.IconDashboard,
    type: 'group',
    children:[
        // {
        //     id: 'pricing-list',
        //     title: <FormattedMessage id="Pricing Tool" />,
        //     type: 'item',
        //     url: '/pricing/pricing-tool',
        //     icon: LocalMallIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'pricing-list2',
            title: <FormattedMessage id="Pricing Tool" />,
            type: 'item',
            url: '/pricing/pricing-tool',
            icon: LocalMallIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'pricing-list2',
        //     title: <FormattedMessage id="Pricing Grid" />,
        //     type: 'item',
        //     url: '/pricing/pricing-tool-grid',
        //     icon: LocalMallIcon,
        //     breadcrumbs: false
        // },
    ]
};

export default Pricing;
