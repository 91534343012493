// third-party
import { FormattedMessage } from 'react-intl';

import ExtensionIcon from '@mui/icons-material/Extension';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Forecast = {
	id: 'Forecast',
	title: <FormattedMessage id="Forecast" />,
	icon: ExtensionIcon,
	type: 'group',
	children: [
		{
			id: 'forecast',
			title: <FormattedMessage id="Forecast" />,
			type: 'item',
			url: '/forecast',
			icon: CalendarIcon,
			breadcrumbs: false,
		},
		{
			id: 'forecastAdmin',
			title: <FormattedMessage id="Admin" />,
			type: 'item',
			url: '/forecast-admin',
			icon: AdminIcon,
			breadcrumbs: false,
		},
	],
};

export default Forecast;

function AdminIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>admin</title>

			<path
				d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 4.9C12.5933 4.9 13.1734 5.07595 13.6667 5.40559C14.1601 5.73524 14.5446 6.20377 14.7716 6.75195C14.9987 7.30013 15.0581 7.90333 14.9424 8.48527C14.8266 9.06721 14.5409 9.60176 14.1213 10.0213C13.7018 10.4409 13.1672 10.7266 12.5853 10.8424C12.0033 10.9581 11.4001 10.8987 10.8519 10.6716C10.3038 10.4446 9.83524 10.0601 9.50559 9.56671C9.17595 9.07336 9 8.49334 9 7.9C9 7.10435 9.31607 6.34129 9.87868 5.77868C10.4413 5.21607 11.2044 4.9 12 4.9ZM12 12.8C14 12.8 18 13.89 18 15.88C17.3432 16.8701 16.4516 17.6823 15.4047 18.2442C14.3578 18.806 13.1881 19.1 12 19.1C10.8119 19.1 9.64218 18.806 8.59527 18.2442C7.54836 17.6823 6.65677 16.8701 6 15.88C6 13.89 10 12.8 12 12.8Z"
				fill="currentColor"
			/>
		</svg>
	);
}

function CalendarIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>calendar</title>

			<mask
				id="mask0_295_2162"
				style={{
					maskType: 'luminance',
				}}
				maskUnits="userSpaceOnUse"
				x="1"
				y="1"
				width="22"
				height="21"
			>
				<path
					d="M2.5 9.5H21.5V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H3.5C3.23478 21 2.98043 20.8946 2.79289 20.7071C2.60536 20.5196 2.5 20.2652 2.5 20V9.5Z"
					fill="white"
					stroke="white"
					strokeWidth="2"
					strokeLinejoin="round"
				/>
				<path
					d="M2.5 4.5C2.5 4.23478 2.60536 3.98043 2.79289 3.79289C2.98043 3.60536 3.23478 3.5 3.5 3.5H20.5C20.7652 3.5 21.0196 3.60536 21.2071 3.79289C21.3946 3.98043 21.5 4.23478 21.5 4.5V9.5H2.5V4.5Z"
					stroke="white"
					strokeWidth="2"
					strokeLinejoin="round"
				/>
				<path
					d="M8 2V6M16 2V6"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14 17H17M7 17H10M14 13H17M7 13H10"
					stroke="black"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</mask>
			<g mask="url(#mask0_295_2162)">
				<path d="M0 0H24V24H0V0Z" fill="currentColor" />
			</g>
		</svg>
	);
}
