import { FetchService } from "utils/FetchService";

export const TarifasMexHelper = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    rutaTabla: 'Tarifas',
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    },

    // METODO POR GUSTAVO
    fetchData: async function (accessToken) {
        try {
            const response = await FetchService.Get(this.rutaTabla, accessToken);
            // console.log('TarifasBancosHelper.fetchData::response', response);

            return response;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },

    fetchDataDistinctByCountryRegion: async function (paisRegion,accessToken) {
        try {
            const response = await FetchService.Get(`${this.rutaTabla}/listquotedfwd/${paisRegion}`, accessToken);
            // console.log('TarifasBancosHelper.fetchData::response', response);

            return response;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },




    //CRUD 
    // Crear un registro en la tabla
    createData: async function (newData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(newData),
            });
            const jsonData = await response.json();
            console.log('Helper Create', newData)
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Leer un registro de la tabla por ID
    readDataById: async function (id) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Actualizar un registro de la tabla por ID
    updateDataById: async function (id, updatedData, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedData),
            });
            console.log(updatedData);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            return null;
        }
    },
    // Eliminar un registro de la tabla por ID
    deleteDataById: async function (id, accessToken) {
        try {
            const response = await fetch(`${this.baseUrl}/${this.rutaTabla}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                // Lanzar un error si el servidor responde con un estado de error
                const errorMessage = await response.text(); // O response.json() si el error es un objeto JSON
                throw new Error(errorMessage);
            }
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error', error);
            throw error;
        }
    },

}