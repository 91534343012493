import { DataGridPro } from '@mui/x-data-grid-pro';
import ChangesField from './ChangesField';
import { useBoundStore } from 'useStore';

export default function HistoryTable({ data, count, loading }) {
	console.log('data', data);
	const paginationModel = useBoundStore(
		(state) => state.forecastPaginationModel
	);
	const setPaginationModel = useBoundStore(
		(state) => state.setForecastPaginationModel
	);

	return (
		<DataGridPro
			loading={loading}
			rows={data}
			rowCount={count}
			getRowId={getRowId}
			columns={columns}
			pageSize={25}
			getDetailPanelHeight={getDetailPanelHeight}
			getRowHeight={getRowHeight}
			disableColumnMenu
			pagination
			paginationMode="server"
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			pageSizeOptions={rowsPerPageOptions}
			disableRowSelectionOnClick
			disableColumnReorder
			sx={gridStyles}
		/>
	);
}

function formatDate(dateString) {
	const date = new Date(dateString).toISOString().split('T')[0];
	return `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`;
}

const columns = [
	{
		field: 'sku',
		headerName: 'SKU',
		headerClassName: 'header border-bottom',
		minWidth: 70,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'category_manager',
		headerName: 'Category Manager',
		headerClassName: 'header border-bottom',
		minWidth: 70,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'changes',
		headerName: 'Cambios ejecutados',
		headerClassName: 'header border-bottom',
		renderCell: (params) => {
			return <ChangesField monthsData={params.value} />;
		},
		valueGetter: (_value, row) => {
			return row?.years_month?.[0]?.months;
		},
		minWidth: 450,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'hdate',
		headerName: 'Fecha de modificación',
		headerClassName: 'header border-bottom',
		minWidth: 70,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		valueFormatter: (value) => {
			if (value == null) {
				return '';
			}
			return formatDate(value);
		},
	},
	{
		field: 'motive',
		headerName: 'Motivo',
		headerClassName: 'header border-bottom',
		minWidth: 70,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
];

const getRowId = (row) => row?.id;
const rowsPerPageOptions = [5, 10, 25];

const getDetailPanelHeight = () => 'auto';
const getRowHeight = () => 100;

const gridStyles = {
	'& .MuiDataGrid-cell': {
		borderBottom: '1px solid #E0E0E0',
	},

	'& .border-right': {
		borderRight: '1px solid #E0E0E0',
	},
	'& .border-bottom': {
		borderBottom: '1px solid #E0E0E0',
	},
	'& .MuiDataGrid-columnHeader--emptyGroup': {
		borderBottom: '1px solid #E0E0E0',
	},
	'& .header': {
		backgroundColor: '#f5f5f5',
	},

	'& .MuiDataGrid-scrollbar--vertical': {
		display: 'none',
	},
	'& .MuiDataGrid-filler': {
		height: 'fit-content !important',
	},
	'& .MuiDataGrid-main': {
		paddingBottom: '16px !important',
	},
	'& .MuiToolbar-root': {
		padding: '0 !important',
	},
	'& .MuiDataGrid-row:hover': {
		backgroundColor: 'transparent !important',
	},

	height: 'calc(100vh - 260px)',
	// height: '100%',
};
