import { Box, ButtonBase, CardMedia, Stack, Typography } from '@mui/material';

import CachedIcon from '@mui/icons-material/Cached';

// ==============================|| ERROR PAGE ||============================== //

const ErrorPage = () => {
	return (
		<Stack
			justifyContent="flex-start"
			alignItems="center"
			sx={{
				height: 'calc(100vh - 128px)',
				backgroundColor: 'white',
				borderRadius: '5px',
			}}
		>
			<Box
				sx={{
					width: '500px',
					height: '445px',
				}}
			>
				<ErrorImage />
			</Box>

			<Stack spacing={2} justifyContent="center" alignItems="center">
				<Typography
					variant="h1"
					sx={{
						color: '#00000099',
						fontWeight: '700',
						fontSize: '24px',
						fontFamily: 'Montserrat',
					}}
				>
					Error de carga
				</Typography>
				<Stack justifyContent="center" alignItems="center">
					<Typography
						sx={{
							color: '#00000061',
							fontWeight: '400',
							fontSize: '16px',
							fontFamily: 'Montserrat',
						}}
					>
						Ha ocurrido un error al cargar los datos.
					</Typography>
					<Typography
						sx={{
							color: '#00000061',
							fontWeight: '400',
							fontSize: '16px',
							fontFamily: 'Montserrat',
						}}
					>
						Intenta recargar la página nuevamente o prueba más tarde.
					</Typography>
				</Stack>

				<ButtonBase
					onClick={() => window.location.reload()}
					sx={{
						color: '#00000099',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '0.5rem',
						width: 'fit-content',
						padding: '0.5rem 1rem',
						borderRadius: '12px',
					}}
				>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: '600',
						}}
					>
						Recargar
					</Typography>
					<CachedIcon />
				</ButtonBase>
			</Stack>
		</Stack>
	);
};

export default ErrorPage;

function ErrorImage() {
	return (
		<svg
			width="501"
			height="445"
			viewBox="0 0 501 445"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Error Page</title>
			<g opacity="0.65">
				<path
					d="M83.4101 305.64C83.4101 305.64 108.41 362.64 171.62 381.28C234.83 399.92 302.33 375.08 357.49 356.41C412.65 337.74 441.66 290 429.37 241.05C417.08 192.1 370.61 195.62 327.27 152.15C283.93 108.68 278.99 90.5401 218.93 77.7001C158.87 64.8601 93.1301 115.41 76.6901 187C60.2501 258.59 83.4101 305.64 83.4101 305.64Z"
					fill="#D1D7EF"
				/>
				<path
					opacity="0.8"
					d="M83.4101 305.64C83.4101 305.64 108.41 362.64 171.62 381.28C234.83 399.92 302.33 375.08 357.49 356.41C412.65 337.74 441.66 290 429.37 241.05C417.08 192.1 370.61 195.62 327.27 152.15C283.93 108.68 278.99 90.5401 218.93 77.7001C158.87 64.8601 93.1301 115.41 76.6901 187C60.2501 258.59 83.4101 305.64 83.4101 305.64Z"
					fill="white"
				/>
				<path
					opacity="0.3"
					d="M154.09 356.451C154.09 359.451 135.66 361.841 112.91 361.841C90.16 361.841 71.73 359.431 71.73 356.451C71.73 353.471 90.17 351.061 112.91 351.061C135.65 351.061 154.09 353.481 154.09 356.451Z"
					fill="black"
				/>
				<path
					opacity="0.3"
					d="M240.3 356.451C240.3 359.451 221.86 361.841 199.12 361.841C176.38 361.841 157.94 359.431 157.94 356.451C157.94 353.471 176.38 351.061 199.12 351.061C221.86 351.061 240.3 353.481 240.3 356.451Z"
					fill="black"
				/>
				<path
					opacity="0.3"
					d="M354.5 356.451C354.5 359.451 336.07 361.841 313.33 361.841C290.59 361.841 272.15 359.431 272.15 356.451C272.15 353.471 290.58 351.061 313.33 351.061C336.08 351.061 354.5 353.481 354.5 356.451Z"
					fill="black"
				/>
				<path
					opacity="0.3"
					d="M449.9 356.451C449.9 359.451 431.46 361.841 408.72 361.841C385.98 361.841 367.5 359.431 367.5 356.451C367.5 353.471 385.94 351.061 408.68 351.061C431.42 351.061 449.9 353.481 449.9 356.451Z"
					fill="black"
				/>
				<path
					d="M317.78 198.771C317.78 198.771 316.09 205.771 316.09 206.951C316.089 207.681 316.008 208.409 315.85 209.121C315.85 209.121 312.24 213.451 312.72 214.651C313.303 215.584 314.139 216.333 315.13 216.811C315.13 216.811 317.05 223.811 318.98 224.271C320.962 224.642 322.966 224.882 324.98 224.991L325.7 230.761L335.32 229.321L332.92 220.661L333.92 213.201C333.92 213.201 335.6 210.081 333.92 205.031C332.24 199.981 328.63 199.741 326.22 199.741C323.392 199.562 320.575 199.239 317.78 198.771Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M322.11 195.171C322.11 195.171 313.69 194.921 313.93 197.091C314.17 199.261 322.83 202.091 322.83 202.091C322.83 202.091 319.94 204.011 321.38 205.941C322.82 207.871 326.19 209.551 326.19 209.551C326.19 209.551 325.19 213.641 326.68 213.881C328.17 214.121 329.08 211.951 329.32 211.231C329.56 210.511 332.21 210.751 332.93 212.671C333.337 213.669 333.58 214.726 333.65 215.801C333.65 215.801 336.54 207.141 337.02 203.291C337.5 199.441 326.68 195.171 322.11 195.171Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M327.44 214.001C327.44 214.001 330.35 207.601 332.09 210.751C333.83 213.901 333.09 217.181 331.38 217.531C329.67 217.881 328.24 218.221 328.24 218.221"
					fill="white"
				/>
				<path
					d="M327.44 214.001C327.44 214.001 330.35 207.601 332.09 210.751C333.83 213.901 333.09 217.181 331.38 217.531C329.67 217.881 328.24 218.221 328.24 218.221"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M315.61 280.791C315.14 281.341 307.43 297.871 306.47 303.401C305.51 308.931 299.26 341.891 299.01 343.331C298.76 344.771 285.55 347.661 284.58 347.901C283.61 348.141 285.58 350.791 288.43 351.511C291.28 352.231 299.5 355.601 301.43 353.671C303.36 351.741 306 340.921 306.72 339.721C307.44 338.521 320.98 306.621 320.98 306.621C320.98 306.621 327.92 291.311 329.36 289.861C330.8 288.411 327.65 282.001 326.44 280.071C325.23 278.141 318.98 276.941 315.61 280.791Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M314.17 281.001C314.17 281.001 317.54 297.121 319.22 302.891C320.792 307.737 323.158 312.289 326.22 316.361C328.15 319.011 337.53 342.361 337.53 342.361L338.97 345.971C338.97 345.971 338.49 347.411 335.85 349.331C333.21 351.251 325.74 354.381 327.67 355.111C329.6 355.841 337.29 355.831 340.9 355.591C344.008 355.37 346.922 353.997 349.07 351.741C350.52 350.051 346.67 345.241 346.67 344.281C346.67 343.321 338.25 313.981 337.77 312.531C337.29 311.081 334.88 300.751 334.88 300.751V289.001C334.88 289.001 336.09 286.841 336.09 282.001C336.09 277.161 332.48 276.221 332.48 276.221L312.75 278.151L314.17 281.001Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M312.5 279.591C312.5 279.591 312.99 283.681 314.43 283.921C315.87 284.161 330.78 288.251 337.28 283.441C337.28 283.441 338.48 279.831 337.76 278.871C337.04 277.911 335.35 278.391 326.7 278.391C318.05 278.391 312.5 278.391 312.5 279.591Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M334.61 225.711C334.61 225.711 325.71 226.911 321.86 230.711C318.01 234.511 314.65 241.541 312.97 248.031C311.29 254.521 310.08 278.341 312.48 279.541C314.88 280.741 335.09 281.951 338.48 280.541C341.87 279.131 338.24 267.791 338.24 263.461C338.24 259.131 342.09 244.461 341.37 236.291C340.65 228.121 334.61 225.711 334.61 225.711Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M302.5 250.711C302.39 240.811 298.35 232.361 292.98 228.711V226.711L278.18 226.861C269.85 226.951 263.18 237.861 263.35 251.161C263.52 264.461 270.35 275.231 278.69 275.161L293.49 275.011V273.011C298.69 269.141 302.55 260.611 302.5 250.711Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M309.14 250.641C309.03 240.241 305.02 231.361 299.38 227.501V225.371L293.04 225.441C284.28 225.531 277.31 236.961 277.46 250.971C277.61 264.981 284.82 276.261 293.58 276.171L299.92 276.101V273.981C305.43 270.001 309.25 261.001 309.14 250.641Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M315.5 250.572C315.65 264.572 308.67 276.012 299.92 276.102C291.17 276.192 283.92 264.912 283.8 250.912C283.68 236.912 290.63 225.472 299.38 225.372C308.13 225.272 315.33 236.562 315.5 250.572Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M311.86 250.14C311.93 256.77 308.62 262.19 304.47 262.24C300.32 262.29 296.9 256.93 296.83 250.24C296.76 243.55 300.07 238.24 304.22 238.14C308.37 238.04 311.79 243.5 311.86 250.14Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M429.7 300C430.525 293.836 430.876 287.618 430.75 281.4C427.5 282.9 408.54 283.45 406.8 283.49C405.06 283.53 386.1 282.9 382.85 281.4C382.706 287.617 383.041 293.835 383.85 300C385.16 308.11 396.68 340.58 396.94 342.15C397.277 343.664 397.714 345.154 398.25 346.61C398.25 346.61 391.7 351.32 388.82 353.15C385.94 354.98 388.04 355.77 390.92 355.77C393.8 355.77 399.82 354.98 401.65 354.98C403.27 354.98 406.09 353.57 406.75 353.23C407.41 353.57 410.23 354.98 411.84 354.98C413.68 354.98 419.7 355.77 422.58 355.77C425.46 355.77 427.58 354.98 424.68 353.15C421.78 351.32 415.25 346.61 415.25 346.61C415.785 345.154 416.222 343.664 416.56 342.15C416.87 340.55 428.39 308.08 429.7 300Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M402.84 230.49C402.84 230.49 392.71 233.8 387.48 237.12C382.25 240.44 377 258.42 377 259.12C376.699 260.373 376.291 261.598 375.78 262.78C375.78 262.78 370.54 261.56 369.5 265.92C368.46 270.28 374.73 275.87 378.05 276.05C381.37 276.23 382.41 275.18 382.41 275.18C382.41 275.18 380.32 279.37 381.19 281.11C382.06 282.85 389.4 289.32 403.89 289.11C418.38 288.9 428.89 286.49 430.42 284.4C431.95 282.31 432.17 277.94 432.17 277.94C432.17 277.94 437.58 276.54 439.5 273.05C441.42 269.56 441.07 265.89 440.02 262.23C438.97 258.57 433.56 247.94 429.9 242C426.24 236.06 418.55 232.75 414.9 231.7C411.25 230.65 405.28 230.49 402.84 230.49Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M405.91 245.19C407.36 244.71 411.91 240.67 413.66 236.63C414.352 235.084 414.84 233.453 415.11 231.78L414.88 231.71C411.22 230.66 405.28 230.49 402.88 230.49C402.88 230.49 400.76 231.18 397.96 232.25C397.977 234.412 398.384 236.553 399.16 238.57C400.74 242.61 404.5 245.68 405.91 245.19Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M422.56 253.352C422.56 253.352 426.75 275.702 431.5 278.002"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M382.41 275.181C382.41 275.181 387.65 255.451 388 253.531"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M429.33 263.451C429.33 263.451 426.43 264.101 426.43 266.201"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M380.06 263.771C380.06 263.771 383.29 265.872 382.97 269.102"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M401.7 355.001C403.32 355.001 406.14 353.591 406.8 353.251C407.46 353.591 410.28 355.001 411.89 355.001C413.73 355.001 419.75 355.791 422.63 355.791C425.51 355.791 427.63 355.001 424.73 353.171C422.22 351.581 416.96 347.821 415.62 346.861C412.53 349.681 406.42 348.981 406.42 348.981C405.182 348.794 403.957 348.534 402.75 348.201C400.92 347.671 398.3 346.631 398.3 346.631C398.3 346.631 391.75 351.341 388.87 353.171C385.99 355.001 388.09 355.791 390.97 355.791C393.85 355.791 399.87 355.001 401.7 355.001Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M406.42 346.672V301.012"
					stroke="white"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M406.8 353.202L406.42 348.932V346.672"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M402.75 299.441H410.34"
					stroke="white"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M392.83 204.292C392.83 204.292 387.39 207.552 388.2 210.412C389.01 213.272 391.47 213.822 390.93 215.412C390.39 217.002 388.5 218.002 390.65 219.002C391.874 219.55 393.155 219.963 394.47 220.232C394.47 220.232 396.47 227.172 399.91 228.392C403.35 229.612 409.31 228.802 413.53 228.262C414.955 228.122 416.305 227.552 417.398 226.626C418.491 225.701 419.277 224.465 419.65 223.082C420.06 221.592 417.88 223.362 416.79 221.722C415.7 220.082 417.34 215.872 418.56 212.192C419.78 208.512 416.11 201.572 411.35 199.262C406.59 196.952 397.5 198.162 392.83 204.292Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M401.97 228.002C401.278 230.284 400.809 232.629 400.57 235.002C400.39 238.002 402.84 241.292 405.46 241.112C408.08 240.932 410.34 235.702 410.69 233.112C411.04 230.522 409.82 223.512 409.82 223.512C409.82 223.512 405.82 223.692 404.23 225.082C403.296 225.901 402.529 226.892 401.97 228.002Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M398.42 208.102C398.42 208.102 398.55 212.182 402.64 216.102C405.099 218.479 408.014 220.333 411.21 221.552C411.139 222.445 410.908 223.319 410.53 224.132C410.352 224.471 410.075 224.749 409.736 224.928C409.396 225.106 409.011 225.177 408.63 225.132H407.26C407.26 225.132 405.26 229.822 402.84 230.502C400.42 231.182 394.47 230.712 393.65 224.722C392.83 218.732 392.7 213.832 393.38 212.602C394.06 211.372 398.42 208.102 398.42 208.102Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M441.21 260.801C441.83 259.55 442.207 258.192 442.32 256.801C442.32 254.981 438.94 252.371 438.32 251.721C437.7 251.071 437.08 251.141 436.95 252.051C436.948 252.561 437.051 253.067 437.252 253.537C437.453 254.006 437.749 254.43 438.12 254.781C438.627 255.18 438.994 255.73 439.17 256.351C439.23 256.801 438.05 259.011 438.64 260.441C439.23 261.871 440.89 262.301 441.21 260.801Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M305.78 250.592C305.78 250.592 320.5 255.302 329.35 266.302C338.2 277.302 344.01 275.722 355.01 272.062C366.01 268.402 370.2 255.302 385.39 266.302C400.58 277.302 403.72 281.482 416.29 275.202C428.86 268.922 435.66 253.732 447.19 258.962C458.72 264.192 453.47 290.962 454.52 303.962C455.57 316.962 453.99 349.532 456.61 353.722C459.23 357.912 485.94 354.722 485.94 354.722"
					stroke="#263238"
					strokeWidth="3.14226"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M333.35 233.112C333.35 233.112 330.42 228.332 326.1 229.582C321.53 230.902 319.17 237.432 318.1 241.502C317.03 245.572 316.31 249.971 314.35 253.761C314.35 253.761 305.69 253.612 300.86 252.212C297.175 251.063 293.55 249.728 290 248.212C290 248.212 288.29 245.822 287.08 245.352C285.87 244.882 282.73 243.961 282.5 244.681C282.27 245.401 286.14 247.772 286.14 247.772C286.14 247.772 278.94 248.771 278.22 249.541C277.5 250.311 283.31 252.612 283.31 252.612C283.31 252.612 285.48 253.312 290.76 252.052C290.76 252.052 310.13 264.352 313.99 265.762C317.85 267.172 319.99 267.622 322.62 262.542C325.25 257.462 333.98 244.861 335.37 239.791"
					fill="#D1D7EF"
				/>
				<path
					d="M333.35 233.112C333.35 233.112 330.42 228.332 326.1 229.582C321.53 230.902 319.17 237.432 318.1 241.502C317.03 245.572 316.31 249.971 314.35 253.761C314.35 253.761 305.69 253.612 300.86 252.212C297.175 251.063 293.55 249.728 290 248.212C290 248.212 288.29 245.822 287.08 245.352C285.87 244.882 282.73 243.961 282.5 244.681C282.27 245.401 286.14 247.772 286.14 247.772C286.14 247.772 278.94 248.771 278.22 249.541C277.5 250.311 283.31 252.612 283.31 252.612C283.31 252.612 285.48 253.312 290.76 252.052C290.76 252.052 310.13 264.352 313.99 265.762C317.85 267.172 319.99 267.622 322.62 262.542C325.25 257.462 333.98 244.861 335.37 239.791"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M295.03 250.251C292.2 249.191 290.03 248.251 290.03 248.251C290.03 248.251 288.32 245.861 287.11 245.391C285.9 244.921 282.76 244.001 282.53 244.721C282.3 245.441 286.17 247.811 286.17 247.811C286.17 247.811 278.97 248.811 278.25 249.581C277.53 250.351 283.34 252.651 283.34 252.651C283.34 252.651 285.51 253.351 290.79 252.091L293.5 253.801L295.03 250.251Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M369.69 262.64C369.069 261.389 368.692 260.032 368.58 258.64C368.58 256.82 371.97 254.22 372.58 253.57C373.19 252.92 373.81 252.99 373.94 253.9C373.941 254.411 373.838 254.917 373.637 255.386C373.436 255.856 373.141 256.279 372.77 256.63C372.263 257.03 371.895 257.579 371.72 258.2C371.66 258.64 372.84 260.86 372.25 262.29C371.66 263.72 370.01 264.13 369.69 262.64Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M372.02 257.152C372.02 257.152 369.23 262.302 370.85 264.152C372.47 266.002 374.04 259.992 374.34 258.332C374.64 256.672 373.05 255.002 372.02 257.152Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M374.32 259.001C374.32 259.001 371.53 264.151 373.15 266.001C374.77 267.851 376.34 261.84 376.64 260.18C376.94 258.52 375.35 256.791 374.32 259.001Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M376.85 259.431C376.85 259.431 374.06 264.581 375.68 266.431C377.3 268.281 378.87 262.271 379.17 260.611C379.47 258.951 377.88 257.271 376.85 259.431Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M380.09 259.47C380.09 259.47 377.49 263.47 378.91 264.98C380.33 266.49 381.86 261.74 382.16 260.37C382.46 259 381.05 257.67 380.09 259.47Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M439.35 257.35C439.35 257.35 442.14 262.5 440.52 264.35C438.9 266.2 437.33 260.18 437.03 258.52C436.73 256.86 438.32 255.19 439.35 257.35Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M437.05 259.15C437.05 259.15 439.84 264.3 438.22 266.15C436.6 268 435.03 261.98 434.73 260.32C434.43 258.66 436.02 257 437.05 259.15Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M434.5 259.632C434.5 259.632 437.29 264.782 435.67 266.632C434.05 268.482 432.48 262.472 432.19 260.802C431.9 259.132 433.5 257.472 434.5 259.632Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M431.28 259.671C431.28 259.671 433.88 263.721 432.46 265.181C431.04 266.641 429.51 261.941 429.22 260.571C428.93 259.201 430.32 257.871 431.28 259.671Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M87.22 316.65V322.33C85.2489 324.485 83.8315 327.086 83.09 329.91C81.88 334.55 78.09 354.52 79.09 355.04C80.09 355.56 89.5 357.71 92.5 357.26C95.94 356.74 98.87 356.92 97.14 355.02C95.41 353.12 86.32 347.81 86.32 347.81C86.32 347.81 96.32 333.35 98.54 328.01C100.76 322.67 101.12 314.41 101.12 314.41L87.7 313.9L87.22 316.65Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M92.4997 357.261C95.9397 356.741 98.8697 356.921 97.1397 355.021C95.4097 353.121 86.3197 347.811 86.3197 347.811L85.9797 349.871L79.4297 349.071C78.9497 352.411 78.7397 354.861 79.0897 355.071C80.1597 355.551 89.4997 357.711 92.4997 357.261Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M120.1 319.59L121.65 322.35C121.007 323.589 120.629 324.948 120.54 326.341C120.45 327.733 120.651 329.13 121.13 330.44C122.68 335.26 126.3 352.99 126.3 354.02C126.3 355.05 128.53 355.74 130.3 355.74C132.07 355.74 136.97 356.08 137.66 356.08C138.35 356.08 143.34 356.94 145.06 355.91C146.78 354.88 142.87 352.81 140.29 351.61C137.71 350.41 132.03 347.31 132.03 347.31C132.03 347.31 133.4 333.19 133.4 326.99C133.4 320.79 134.78 318.73 132.89 314.94C131 311.15 119.12 316.67 119.12 316.67L120.1 319.59Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M140.24 351.611C137.66 350.401 131.98 347.311 131.98 347.311L131.29 349.891L125.76 350.891C126.09 352.611 126.3 353.791 126.3 354.031C126.3 355.031 128.53 355.751 130.3 355.751C132.07 355.751 136.97 356.091 137.66 356.091C138.35 356.091 143.34 356.951 145.06 355.921C146.78 354.891 142.82 352.811 140.24 351.611Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M110.83 205C109.48 202.694 107.938 200.507 106.22 198.46C103.85 195.79 96.4198 195.94 93.1598 199.46C89.8998 202.98 90.9298 209.26 92.1598 211.04C93.3898 212.82 95.8698 221.14 95.8698 221.14L96.4598 227.08L110.27 231.98L107.45 222.33C107.45 222.33 112.8 221.33 113.24 219.66C113.68 217.99 112.35 212.38 112.35 212.38C112.35 212.38 114.13 211.19 113.98 210.15C113.83 209.11 110.83 205 110.83 205Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.9398 211.401C94.9398 211.401 97.2998 213.401 98.5898 211.111C99.8798 208.821 100.86 206.581 100.86 204.641C100.86 202.701 96.0098 201.091 99.2398 199.791C102.47 198.491 104.8 201.381 106.24 198.481C107.68 195.581 106.37 195.271 98.6098 195.271C90.8498 195.271 86.9698 199.151 88.6098 204.001C89.9214 207.583 91.5443 211.043 93.4598 214.341L94.9398 211.401Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M111.27 208.431C111.27 209.164 111.052 209.882 110.644 210.492C110.237 211.102 109.657 211.577 108.979 211.858C108.301 212.139 107.555 212.213 106.836 212.069C106.116 211.926 105.455 211.573 104.936 211.054C104.417 210.535 104.064 209.874 103.921 209.154C103.778 208.435 103.851 207.689 104.132 207.011C104.413 206.333 104.888 205.754 105.498 205.346C106.109 204.938 106.826 204.721 107.56 204.721C108.544 204.721 109.487 205.112 110.183 205.807C110.879 206.503 111.27 207.447 111.27 208.431Z"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M110.73 205.141C111.162 204.964 111.623 204.873 112.09 204.871C112.862 204.872 113.613 205.13 114.223 205.604C114.833 206.077 115.269 206.74 115.463 207.488C115.656 208.236 115.596 209.027 115.291 209.737C114.987 210.447 114.455 211.036 113.78 211.411"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M103.85 208.432L94.9399 211.402"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M97.5 213.331C97.5 213.331 95.72 209.621 93.94 210.331C92.16 211.041 93.05 216.871 94.83 217.461C95.4281 217.595 96.0498 217.585 96.6431 217.43C97.2364 217.275 97.7839 216.98 98.24 216.571C98.24 216.571 99.73 219.691 101.51 220.571"
					fill="white"
				/>
				<path
					d="M97.5 213.331C97.5 213.331 95.72 209.621 93.94 210.331C92.16 211.041 93.05 216.871 94.83 217.461C95.4281 217.595 96.0498 217.585 96.6431 217.43C97.2364 217.275 97.7839 216.98 98.24 216.571C98.24 216.571 99.73 219.691 101.51 220.571"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M85.8196 280L84.7096 294.22L81.3896 315.27C81.3896 315.27 89.1496 321.36 96.3896 321.36C103.63 321.36 105.62 320.44 105.62 320.44L109.68 298.84L113.74 321.55C113.74 321.55 121.74 320.26 127.04 318.96C130.725 317.935 134.218 316.313 137.38 314.16L126.67 286.28L124.35 271.9C124.35 271.9 112.35 273.01 105.14 273.38C97.9296 273.75 88.3396 271.9 88.3396 271.9L85.8196 280Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M93.4297 223.18C93.4297 223.18 110.43 229.11 115.68 234.87C118.58 238.07 121.83 259.971 123 265.321C124.17 270.671 126.21 281.321 126.21 281.321C126.21 281.321 115.84 286.001 107.82 286.171C99.7997 286.341 84.4097 282.66 84.4097 282.66C84.4097 282.66 86.2397 258.93 86.2397 247.9C86.2397 236.87 86.4097 229.67 88.2397 226.16C88.2497 226.19 91.2597 222.84 93.4297 223.18Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M88.9596 284.381C88.9596 284.381 89.8796 299.891 88.9596 305.621C88.3136 309.318 87.1979 312.917 85.6396 316.331"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M89.3296 291.77C90.7181 291.293 91.9588 290.464 92.93 289.363C93.9012 288.262 94.5696 286.927 94.8696 285.49"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M82.3096 312.271C82.3096 312.271 91.5496 320.211 105.95 317.271"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.34 316.881C113.34 316.881 128.48 315.221 135.13 310.791"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M181.06 216.001C181.06 216.001 179.6 217.081 174.51 220.801C169.42 224.521 173.43 230.141 173.43 230.141C173.43 230.141 169.79 230.711 164.93 235.821C160.07 240.931 167.1 249.701 173.67 251.071C180.24 252.441 187.92 244.311 190.22 236.771C192.52 229.231 189.48 217.861 187.95 215.511C186.42 213.161 181.06 216.001 181.06 216.001Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M200.34 215.001C200.34 215.001 202.87 211.091 200.58 205.421C198.29 199.751 191.45 197.161 184.79 201.271C182.57 202.592 180.848 204.608 179.891 207.008C178.935 209.407 178.797 212.055 179.5 214.541C180.15 217.161 183.43 221.191 184.12 221.251C184.81 221.311 190.8 223.491 194.89 221.581C198.98 219.671 200.34 215.001 200.34 215.001Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M170.91 347.001C170.91 347.001 175.66 354.391 183.91 355.651C192.16 356.911 184.74 349.511 183.35 348.391C181.96 347.271 172.03 344.851 170.91 347.001Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M214.86 348.901C214.86 348.901 223.93 351.131 223.23 353.091C222.53 355.051 215.14 354.901 210.82 353.781C206.5 352.661 202.44 350.991 203 349.181C203.56 347.371 214.86 348.901 214.86 348.901Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M189.07 229.701C189.07 229.701 176.93 231.481 174.71 235.771C172.49 240.061 175.62 263.431 175.62 263.431C175.62 263.431 170.44 274.861 170.37 282.221C170.3 289.581 173.58 302.531 173.32 305.761C173.06 308.991 169.74 345.761 169.74 346.761C169.74 347.761 176.74 350.001 186.83 351.201C196.92 352.401 216.22 348.811 216.22 348.811C216.22 348.811 215.84 327.111 216.85 314.171C217.86 301.231 216.42 289.601 213.85 284.771C211.28 279.941 199.31 262.551 199.31 262.551C199.31 262.551 205.11 252.031 205.47 247.431C205.83 242.831 203.96 240.431 199.58 235.141C198.278 233.581 196.678 232.296 194.873 231.362C193.068 230.427 191.095 229.863 189.07 229.701Z"
					fill="#D1D7EF"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M199.34 262.56C199.34 262.56 205.14 252.04 205.5 247.44C205.86 242.84 203.99 240.44 199.61 235.15C199.4 234.89 199.18 234.66 198.96 234.42L198.8 234.57C194.3 241.92 179.42 254.48 174.99 258.14C175.33 261.27 175.62 263.43 175.62 263.43C175.62 263.43 170.44 274.86 170.37 282.22C170.453 285.574 170.787 288.916 171.37 292.22C181.88 286.22 195.94 274.99 203.55 268.73C201.17 265.21 199.34 262.56 199.34 262.56Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M186.83 221.121C187.165 223.866 187.349 226.627 187.38 229.391C187.22 231.441 187.88 236.121 191.38 237.591C194.88 239.061 197.08 237.011 197.07 234.951C196.943 233.25 196.607 231.571 196.07 229.951L196.17 224.301C196.17 224.301 190.13 220.521 186.83 221.121Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M183.69 217.451C183.69 217.451 181.12 215.191 181.04 218.451C180.96 221.711 183.96 222.791 184.62 223.011C185.177 223.087 185.742 222.948 186.2 222.621C186.2 222.621 190.86 226.621 194.6 226.881C198.34 227.141 199.86 225.411 200.74 218.621C201.62 211.831 194.61 204.941 194.61 204.941C194.61 204.941 188.61 216.001 183.69 217.451Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M126.5 281.182C126.466 280.023 126.656 278.869 127.06 277.782C127.68 276.422 131.06 275.642 131.81 275.382C132.56 275.122 132.94 275.382 132.72 276.092C132.545 276.474 132.294 276.816 131.983 277.098C131.672 277.379 131.307 277.595 130.91 277.732C130.393 277.856 129.928 278.139 129.58 278.542C129.39 278.862 129.51 280.912 128.58 281.782C127.65 282.652 126.19 282.412 126.5 281.182Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M203.14 251.111C203.14 251.111 191.14 249.541 185.85 257.921C180.56 266.301 170.67 295.621 152.34 300.341C134.01 305.061 128.77 267.341 117.77 268.391C107.29 269.391 99.9698 268.391 93.6798 279.911C90.3198 286.081 73.5198 353.451 65.5798 354.141C40.2898 356.351 24.0098 354.911 24.0098 354.911"
					stroke="#263238"
					strokeWidth="3.14226"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M94.2099 223.662C94.2099 223.662 91.4399 222.552 87.7399 224.762C84.0399 226.972 74.0799 251.172 74.8199 253.762C75.5599 256.352 86.0799 261.152 91.9899 262.812C97.8999 264.472 108.61 266.512 108.61 266.512C108.61 266.512 111.93 271.682 113.04 271.862C114.654 271.84 116.262 271.655 117.84 271.312C117.84 271.312 119.84 272.972 120.61 271.122C121.38 269.272 119.87 266.882 118.21 265.032C116.55 263.182 109.53 262.262 109.53 262.262L90.4999 249.142L98.4999 234.372"
					fill="#263238"
				/>
				<path
					d="M94.2099 223.662C94.2099 223.662 91.4399 222.552 87.7399 224.762C84.0399 226.972 74.0799 251.172 74.8199 253.762C75.5599 256.352 86.0799 261.152 91.9899 262.812C97.8999 264.472 108.61 266.512 108.61 266.512C108.61 266.512 111.93 271.682 113.04 271.862C114.654 271.84 116.262 271.655 117.84 271.312C117.84 271.312 119.84 272.972 120.61 271.122C121.38 269.272 119.87 266.882 118.21 265.032C116.55 263.182 109.53 262.262 109.53 262.262L90.4999 249.142L98.4999 234.372"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M113.04 271.851C114.654 271.829 116.262 271.644 117.84 271.301C117.84 271.301 119.84 272.961 120.61 271.111C121.38 269.261 119.87 266.871 118.21 265.021C116.55 263.171 109.53 262.251 109.53 262.251L90.4997 249.141L93.3397 243.851C86.2297 239.421 81.5097 239.751 78.9597 240.641C76.4097 246.761 74.4697 252.561 74.8097 253.751C75.5497 256.341 86.0697 261.141 91.9797 262.801C97.8897 264.461 108.6 266.501 108.6 266.501C108.6 266.501 111.93 271.671 113.04 271.851Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M231.99 251.52C231.18 242.79 226.99 235.62 221.99 232.77L221.83 230.99L208.77 232.2C201.42 232.88 196.35 242.97 197.44 254.73C198.53 266.49 205.37 275.46 212.72 274.73L225.78 273.52L225.61 271.74C230.01 268.06 232.8 260.25 231.99 251.52Z"
					fill="#263238"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M237.9 251C237.05 241.83 232.9 234.28 227.61 231.28L227.43 229.41L221.84 229.93C214.12 230.65 208.79 241.24 209.93 253.6C211.07 265.96 218.27 275.39 225.99 274.67L231.58 274.15L231.41 272.28C236.03 268.34 238.75 260.14 237.9 251Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M243.5 250.451C244.65 262.811 239.32 273.401 231.59 274.121C223.86 274.841 216.67 265.401 215.5 253.051C214.33 240.701 219.68 230.051 227.4 229.381C235.12 228.711 242.34 238.101 243.5 250.451Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M249.5 243.23L227.81 245.23C227.506 245.259 227.199 245.228 226.907 245.138C226.615 245.048 226.344 244.901 226.109 244.706C225.874 244.511 225.679 244.271 225.537 244.001C225.395 243.73 225.308 243.435 225.28 243.13C225.251 242.826 225.282 242.519 225.372 242.227C225.462 241.935 225.609 241.664 225.804 241.429C225.999 241.194 226.239 241 226.51 240.857C226.78 240.715 227.076 240.628 227.38 240.6L249.07 238.6C249.374 238.571 249.681 238.603 249.973 238.693C250.265 238.783 250.536 238.93 250.771 239.125C251.006 239.32 251.2 239.559 251.343 239.83C251.485 240.1 251.572 240.396 251.6 240.7C251.629 241.004 251.597 241.311 251.507 241.603C251.417 241.895 251.271 242.166 251.076 242.401C250.88 242.637 250.641 242.831 250.37 242.973C250.1 243.115 249.804 243.203 249.5 243.23Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M251.11 260.42L229.42 262.42C229.116 262.449 228.809 262.417 228.517 262.327C228.225 262.237 227.954 262.091 227.719 261.896C227.483 261.7 227.289 261.461 227.147 261.19C227.005 260.92 226.917 260.624 226.89 260.32C226.861 260.016 226.892 259.709 226.982 259.417C227.072 259.125 227.219 258.854 227.414 258.619C227.609 258.384 227.849 258.189 228.119 258.047C228.39 257.905 228.686 257.818 228.99 257.79L250.68 255.79C251.293 255.737 251.902 255.928 252.376 256.321C252.849 256.714 253.149 257.278 253.21 257.89C253.239 258.194 253.207 258.501 253.117 258.793C253.027 259.085 252.88 259.356 252.685 259.591C252.49 259.826 252.251 260.02 251.98 260.163C251.71 260.305 251.414 260.392 251.11 260.42Z"
					fill="#737373"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M181.57 231.33C181.57 231.33 176.35 232.33 173.88 235.33C171.41 238.33 166.34 261.48 167.51 264.14C168.68 266.8 187.43 270.14 193.22 270.62C197.875 270.804 202.538 270.597 207.16 270C207.16 270 212.16 269.87 213.53 269.64C214.9 269.41 217.42 268.06 217.46 267.55C217.5 267.04 217.25 265.82 215.38 265.67C213.51 265.52 209.5 264.18 208.99 264.14C210.191 263.824 211.41 263.577 212.64 263.4C212.64 263.4 212.9 262.4 210.84 262.4C208.78 262.4 206.91 262.26 206.13 263.4C205.572 264.288 205.097 265.225 204.71 266.2C204.71 266.2 193.16 267.01 186.89 263.95C180.62 260.89 178.64 259.87 178.64 259.87L183.14 241.87"
					fill="#D1D7EF"
				/>
				<path
					d="M181.57 231.33C181.57 231.33 176.35 232.33 173.88 235.33C171.41 238.33 166.34 261.48 167.51 264.14C168.68 266.8 187.43 270.14 193.22 270.62C197.875 270.804 202.538 270.597 207.16 270C207.16 270 212.16 269.87 213.53 269.64C214.9 269.41 217.42 268.06 217.46 267.55C217.5 267.04 217.25 265.82 215.38 265.67C213.51 265.52 209.5 264.18 208.99 264.14C210.191 263.824 211.41 263.577 212.64 263.4C212.64 263.4 212.9 262.4 210.84 262.4C208.78 262.4 206.91 262.26 206.13 263.4C205.572 264.288 205.097 265.225 204.71 266.2C204.71 266.2 193.16 267.01 186.89 263.95C180.62 260.89 178.64 259.87 178.64 259.87L183.14 241.87"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M213.5 269.671C214.89 269.441 217.39 268.091 217.43 267.581C217.47 267.071 217.22 265.851 215.35 265.701C213.48 265.551 209.47 264.211 208.96 264.171C210.161 263.855 211.38 263.608 212.61 263.431C212.61 263.431 212.87 262.431 210.81 262.431C208.75 262.431 206.88 262.291 206.1 263.431C205.542 264.319 205.067 265.256 204.68 266.231C204.68 266.231 193.13 267.041 186.86 263.981C180.59 260.921 178.61 259.901 178.61 259.901L181.35 248.901C177.783 247.433 174.019 246.5 170.18 246.131C168.31 253.671 166.78 262.551 167.48 264.131C168.64 266.791 187.4 270.131 193.19 270.611C197.845 270.795 202.509 270.588 207.13 269.991C207.13 269.991 212.14 269.901 213.5 269.671Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M129.04 279.242C129.04 279.242 125.18 282.132 125.77 284.042C126.36 285.952 129.58 282.042 130.38 280.892C131.18 279.742 130.55 278.002 129.04 279.242Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M129.8 281.83C129.8 281.83 125.95 284.72 126.53 286.63C127.11 288.54 130.35 284.63 131.14 283.48C131.93 282.33 131.31 280.57 129.8 281.83Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M131.63 283.512C131.63 283.512 127.78 286.402 128.37 288.312C128.96 290.222 132.18 286.312 132.98 285.162C133.78 284.012 133.15 282.252 131.63 283.512Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M133.14 284.87C133.14 284.87 129.81 287.01 130.37 288.59C130.93 290.17 133.68 287.18 134.37 286.26C135.06 285.34 134.5 283.86 133.14 284.87Z"
					fill="white"
					stroke="#263238"
					strokeWidth="1.04742"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
