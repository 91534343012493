import React from 'react';
import ErrorPage from 'views/pages/maintenance/ErrorPage';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
		// log the error or perform any necessary actions
	}

	render() {
		if (this.state.hasError) {
			// fallback UI
			return <ErrorPage />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
