const { createContext, useState, useContext } = require('react');

const ForecastAlertContext = createContext(null);

export default function ForecastAlertProvider({ children }) {
	const [alertState, setAlertState] = useState({
		open: false,
		message: '',
		title: '',
		severity: '',
	});

	return (
		<ForecastAlertContext.Provider
			value={{
				alertState,
				setAlertState,
			}}
		>
			{children}
		</ForecastAlertContext.Provider>
	);
}

export const useForecastAlert = () => {
	const context = useContext(ForecastAlertContext);
	if (context === undefined) {
		throw new Error(
			'useForecastAlert must be used within a ForecastAlertProvider'
		);
	}
	return context;
};
