import React from 'react';
import SettingsMainComponent from 'views/Components/Settings/Main/SettingsMainComponent';

const SettingsPage = (props) => {
	// const divRef = useRef(null);
	// const [size, setSize] = useState({ width: 0, height: 0 });

	// useEffect(() => {
	// 	// size update
	// 	const updateSize = () => {
	// 		if (divRef.current) {
	// 			const { width, height } = divRef.current.getBoundingClientRect();
	// 			setSize({ width, height });
	// 		}
	// 	};

	// 	// set initial size
	// 	updateSize();

	// 	window.addEventListener('resize', updateSize);

	// 	// remove event controller
	// 	return () => window.removeEventListener('resize', updateSize);
	// }, []);

	return (
		<div style={mainDiv}>
			<SettingsMainComponent />
		</div>
	);
};

export default SettingsPage;

const mainDiv = {
	height: '100%',
	border: '0px solid black',
};
