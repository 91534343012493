// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { TarifasMexHelper } from 'helpers/Costos/TarifasMexHelper';
import { PaisRegionHelper } from 'helpers/Costos/PaisRegionHelper';
import { FwdtteHelper } from 'helpers/Costos/FwdtteHelper';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    // orders: [],

    //hay que declarar el estado inicial
    tarifas: [],
    tarifasSourcing: [],
    tarifasComex: [],
};

const slice = createSlice({
    name: 'virtualSeller',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET Inbound
        getTarifasSuccess(state, action) {
            state.tarifas = action.payload;
        },

        // GET Sourcing Tarifas
        getTarifasSourcingSuccess(state, action) {
            state.tarifasSourcing = action.payload;
        },
        // GET Inbound Tarifas
        getTarifasInboundSuccess(state, action) {
            state.tarifasComex = action.payload;
        },
        

        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getTarifasAll(accessToken) {
    return async (dispatch) => {
        try {
            const response = await TarifasMexHelper.fetchData(accessToken);
            if(response){
                response.sort((a, b) => {
                    return new Date(b.fecha) - new Date(a.fecha);
                });
            }
            dispatch(slice.actions.getTarifasSuccess(response));
            console.log('Get Tarifas All :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getTarifasSourcing(accessToken) {
    return async (dispatch) => {
        try {
            const response = await TarifasMexHelper.fetchData(accessToken);
            const responsePais = await PaisRegionHelper.fetchData(accessToken); 

            let paisRegionMap = new Map(responsePais.map(pais => [pais.id, `${pais.description}-${pais.region}`]));

            let respueseFiltrada = response
                .filter(tarifa => tarifa.tipo_tarifa === 1 || tarifa.tipo_tarifa === 3) //las tarifas con el id:2 son las de COMEX/INBOUND x eso tomamos las distintas
                .sort((a, b) => {
                    return new Date(b.fecha) - new Date(a.fecha);
                })
                .map(tarifa => ({
                    ...tarifa,
                // utilizamos el map para encontrar la descripción y la región
                    paisRegionOrigen: paisRegionMap.get(tarifa.paisregion_id) || 'Sin especificar'
                }))
                dispatch(slice.actions.getTarifasSourcingSuccess(respueseFiltrada));
                console.log('Get Tarifas Sourcing :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getTarifasInbound(accessToken) {
    return async (dispatch) => {
        try {
            const response = await TarifasMexHelper.fetchData(accessToken); 
            const responsePais = await PaisRegionHelper.fetchData(accessToken); 
            const responseFwd = await FwdtteHelper.fetchData(accessToken);

            //aqui es para generar el nuevo atributo nuevo al array y mostrar en la vista, tomo el valor del atributo para luego compararlo y extraerlo
            // let paisRegionMap = new Map(responsePais.map(pais => [pais.id, `${pais.description}-${pais.region}`]));
            let paisRegionMap = new Map(responsePais.map(pais => [pais.id, `${pais.description}`]));
            let fwdMap = new Map(responseFwd.map(fwd => [fwd.id, `${fwd.description}`]));

            let respueseFiltrada = response
                .sort((a, b) => {
                    return new Date(b.fecha) - new Date(a.fecha);
                })
                .map(tarifa => ({
                    ...tarifa,
                // utilizamos el map para encontrar la descripción y la región
                    paisRegionOrigen: paisRegionMap.get(tarifa.paisregion_id) || 'Sin especificar',
                    fwdDescription: fwdMap.get(tarifa.fwdtte_id) || 'Sin Especificar'
                }))
                dispatch(slice.actions.getTarifasInboundSuccess(respueseFiltrada));
            console.log('Get Tarifas Inbound :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};



