import { Stack, Typography } from '@mui/material';
import { useForecastHistoryFilters } from 'contexts/ForecastHistoryFiltersContext';

export default function HistoryCount({ count }) {
	const { selectedStatus } = useForecastHistoryFilters();
	return (
		<Stack
			direction="row"
			columnGap={2}
			rowGap={1}
			alignItems="center"
			flexWrap="wrap"
			sx={{
				padding: '0 24px',
			}}
		>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color: 'rgba(0, 0, 0, 0.60)',
				}}
			>
				Total de solicitudes:{' '}
				{selectedStatus === -1
					? count[0] + count[1]
					: selectedStatus === 2
					? count[0]
					: count[1]}
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
				}}
			>
				|
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color: '#1B5E20',
				}}
			>
				Aprobadas:{' '}
				{selectedStatus === -1 || selectedStatus === 2 ? count[0] : 0}
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
				}}
			>
				|
			</Typography>
			<Typography
				sx={{
					fontFamily: 'Roboto',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color: '#C62828',
				}}
			>
				Rechazadas:{' '}
				{selectedStatus === -1 || selectedStatus === 3 ? count[1] : 0}
			</Typography>
		</Stack>
	);
}
