// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Productos = {
    id: 'Productos_VS',
    title: <FormattedMessage id="Productos_VS" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            url: '/productosVS',
            icon: LocalMallIcon,
            breadcrumbs: false
        },
        
    ]
};

export default Productos;
