import { useEffect, useState } from 'react';
import ErrorBoundary from 'views/Components/ErrorHandler/ErrorBoundary';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';

import ThemeCustomization from 'themes';
import { dispatch } from 'store';
import { getMenu } from 'store/slices/menu';

// auth provider
import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import { GlobalQuoteProvider } from 'contexts/GlobalQuoteContext';

// ==============================|| APP ||============================== //
// Comentario de pruebas
const App = () => {
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO); // aplicamos variable de entorno de MUI PRO
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// dispatch(getMenu()).then(() => {
		//     setLoading(true);
		// });

		setLoading(true);
	}, []);

	if (!loading) return <Loader />;

	return (
		<ErrorBoundary>
			<ThemeCustomization>
				<RTLLayout>
					<Locales>
						<NavigationScroll>
							<AuthProvider>
								<GlobalQuoteProvider>
									<>
										<Routes />
										{/* SE HABILITA PARA QUE FUNCIONEN LOS SNACKBAR en la APP */}
										<Snackbar maxSnack={3} />
									</>
								</GlobalQuoteProvider>
							</AuthProvider>
						</NavigationScroll>
					</Locales>
				</RTLLayout>
			</ThemeCustomization>
		</ErrorBoundary>
	);
};

export default App;
