import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { formatearNumeroConComas, getMonthName } from '../../Main/Table/utils';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function ChangesField({ monthsData }) {
	const [selectedMonth, setSelectedMonth] = useState(monthsData?.[0]?.month);
	const selectedMonthData = monthsData?.find(
		(item) => item.month === selectedMonth
	);

	const handleChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center"
			justifyContent="center"
			sx={{
				width: '100%',
			}}
		>
			<Stack direction="row" spacing={1} sx={{ width: '300px' }}>
				<Stack
					Stack
					spacing={2}
					justifyContent="center"
					sx={{
						pt: '35px',
						pr: '10px',
					}}
				>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '400',
							fontFamily: 'Roboto',
							color: 'rgba(0, 0, 0, 0.38)',
							width: '60px',
							textAlign: 'end',
						}}
					>
						Antes
					</Typography>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '400',
							fontFamily: 'Roboto',
							color: 'rgba(0, 0, 0, 0.38)',
							width: '60px',
							textAlign: 'end',
						}}
					>
						Después
					</Typography>
				</Stack>

				<FieldColumn
					title="PVP"
					past={selectedMonthData?.data?.past_pvp}
					current={selectedMonthData?.data?.pvp}
					currency
				/>
				<FieldColumn
					title="TSU"
					past={selectedMonthData?.data?.past_tsu}
					current={selectedMonthData?.data?.tsu}
				/>
				<FieldColumn
					title="MG"
					past={selectedMonthData?.data?.past_mg}
					current={selectedMonthData?.data?.mg}
					percentage
				/>
			</Stack>

			<Box
				sx={{
					width: '100px',
				}}
			>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={selectedMonth}
					label="Age"
					onChange={handleChange}
					variant="standard"
					sx={{
						'& .MuiSelect-select': {
							borderBottom: 'none',
						},
						'&:before': {
							borderBottom: 'none',
						},
						'&:after': {
							borderBottom: 'none',
						},
						'&:hover:not(.Mui-disabled):before': {
							borderBottom: 'none',
						},
						border: '1px solid rgba(0, 0, 0, 0.23)',
						px: '4px',
						borderRadius: '4px',
					}}
				>
					{monthsData?.map((item, index) => (
						<MenuItem key={index} value={item.month}>
							{getMonthName(item.month)}
						</MenuItem>
					))}
				</Select>
			</Box>
		</Stack>
	);
}

function FieldColumn({ title, past, current, currency, percentage }) {
	const Icon = current > past ? ArrowUpwardIcon : ArrowDownwardIcon;
	const color = current > past ? '#2E7D32' : '#D32F2F';

	return (
		<Stack spacing={2} justifyContent="center">
			<Typography
				sx={{
					fontSize: '12px',
					fontWeight: '400',
					fontFamily: 'Roboto',
					color: 'rgba(0, 0, 0, 0.87)',
					width: '60px',
				}}
			>
				{title}
			</Typography>

			<Typography
				sx={{
					fontSize: '12px',
					fontWeight: '400',
					fontFamily: 'Roboto',
					color: 'rgba(0, 0, 0, 0.38)',
					textDecoration: 'line-through',
					width: '60px',
				}}
			>
				{currency && '$'}
				{formatearNumeroConComas(past)}
				{percentage && '%'}
			</Typography>

			<Typography
				sx={{
					fontSize: '12px',
					fontWeight: '400',
					fontFamily: 'Roboto',
					color,
					width: '60px',
				}}
			>
				{currency && '$'}
				{formatearNumeroConComas(current)}
				{percentage && '%'}
				<Icon
					sx={{
						fontSize: '12px',
						color,
						verticalAlign: 'middle',
						marginLeft: '2px',
					}}
				/>
			</Typography>
		</Stack>
	);
}
