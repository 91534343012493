export const events = [
	{
		id: 1,
		country: 1,
		tier: 1,
		event: 'Black Friday',
		from: '2024-11-26',
		to: '2024-11-28',
		categories: ['Todos los productos'],
	},
	{
		id: 2,
		country: 1,
		tier: 2,
		event: 'Cyber Monday',
		from: '2024-11-29',
		to: '2024-11-30',
		categories: ['Adultos', 'Deportes', 'Fitness', 'Niños'],
	},
	{
		id: 3,
		country: 1,
		tier: 3,
		event: 'Christmas',
		from: '2024-12-25',
		to: '2024-12-25',
		categories: ['Todos los productos'],
	},
];

// Utilidad para obtener la cantidad de días en el mes actual
export const generateCalendarDays = () => {
	const daysArray = [];
	const currentYear = new Date().getFullYear();

	for (let month = 0; month < 12; month++) {
		const daysInMonth = new Date(currentYear, month + 1, 0).getDate();

		for (let day = 1; day <= daysInMonth; day++) {
			const date = new Date(currentYear, month, day);
			daysArray.push({
				day: day,
				month: month + 1,
				year: currentYear,
				date: date.toISOString().split('T')[0], // Format YYYY-MM-DD
			});
		}
	}

	return daysArray;
};

// obtener el nombre del mes actual
export const getMonthName = (month) => {
	const months = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];

	return months[month - 1];
};

export function rangesOverlap(start1, end1, start2, end2) {
	const startDate1 = new Date(start1);
	const endDate1 = new Date(end1);
	const startDate2 = new Date(start2);
	const endDate2 = new Date(end2);
	return startDate1 <= endDate2 && startDate2 <= endDate1;
}
