// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';

// project imports
import { ProveedoresOemHelper } from 'helpers/Costos/ProveedoresOemHelper';
import { PaisRegionHelper } from 'helpers/Costos/PaisRegionHelper';
import { FwdtteHelper } from 'helpers/Costos/FwdtteHelper';
import { TarifasMexHelper } from 'helpers/Costos/TarifasMexHelper';
import { NcmHelper } from 'helpers/Costos/NcmHelper';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    orders: [],
    
    //hay que declarar el estado inicial
    proveedoresOemRedux: [],
    NCM_MexRedux: [],
    NCM_ColRedux: [],
    ownerRedux: [],
    paisesRedux: [],
    FwdtteListRedux: [],
    FwdtteRedux: [],
    FwdtteCSTRedux: [],

};

const slice = createSlice({
    name: 'DatosForm',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET proveedores
        getProveedoresOEMSuccess(state, action) {
            state.proveedoresOemRedux = action.payload;
        }, 

        // GET NCM MEX
        getNCM_MexSuccess(state, action) {
            state.NCM_MexRedux = action.payload;
        },

        // GET NCM COL
        getNCM_ColSuccess(state, action) {
            state.NCM_ColRedux = action.payload;
        },

        // GET proveedores
        getOwnerSuccess(state, action) {
            state.ownerRedux = action.payload;
        },

        // GET Paises
        getPaisesSuccess(state, action) {
            state.paisesRedux = action.payload;
        },

        // GET FwdtteList
        getFwdtteListSuccess(state, action) {
            state.FwdtteListRedux = action.payload;
        },

        // GET Fwdtte
        getFwdtteSuccess(state, action) {
            state.FwdtteRedux = action.payload;
        },

        // GET Fwdtte CST
        getFwdtteCSTSuccess(state, action) {
            state.FwdtteCSTRedux = action.payload;
        },
        
        // has error
        hasError(state, action) {
            state.error = action.payload;
        },
        
        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getProveedoresOEM(accessToken) {
    return async (dispatch) => {
        try {
            let response = await ProveedoresOemHelper.fetchData(accessToken); 
            
            dispatch(slice.actions.getProveedoresOEMSuccess(response));
            console.log('Get Proveedores OEM :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getNCM_MEX(accessToken) {
    return async (dispatch) => {
        try {
            let response = await NcmHelper.fetchDataMex(accessToken); 
            dispatch(slice.actions.getNCM_MexSuccess(response));
            console.log('Get NCM MEX :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getNCM_COL(accessToken) {
    return async (dispatch) => {
        try {
            let response = await NcmHelper.fetchDataCol(accessToken); 
            dispatch(slice.actions.getNCM_ColSuccess(response));
            console.log('Get NCM Col :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getOwner(accessToken) {
    return async (dispatch) => {
        try {
            let response = await PresupuestoHelper.fetchOwnersList(accessToken); 
            
            dispatch(slice.actions.getOwnerSuccess(response));
            console.log('Get Owner :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getPaises(accessToken) {
    return async (dispatch) => {
        try {
            let response = await PaisRegionHelper.fetchData(accessToken); 
            
            dispatch(slice.actions.getPaisesSuccess(response));
            console.log('Get Paises :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getFwdtteList(accessToken) {
    return async (dispatch) => {
        try {
            let response = await FwdtteHelper.fetchData(accessToken); 
            
            dispatch(slice.actions.getFwdtteListSuccess(response));
            console.log('Get FwdtteList :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getFwdtte(accessToken) {
    return async (dispatch) => {
        try {
            let response = await TarifasMexHelper.fetchDataDistinctFwdByDpto( 1, accessToken ); 
            
            dispatch(slice.actions.getFwdtteSuccess(response));
            console.log('Get Fwdtte :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getFwdtteCST(accessToken) {
    return async (dispatch) => {
        try {
            let response = await TarifasMexHelper.fetchDataDistinctFwdByDpto( 3, accessToken ); 
            
            dispatch(slice.actions.getFwdtteCSTSuccess(response));
            console.log('Get Fwdtte CST:');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};


