// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBorderAll, IconChartDots, IconClipboardCheck, IconForms, IconPictureInPicture, IconStairsUp } from '@tabler/icons';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';


// constant
const icons = {
    IconClipboardCheck,
    IconPictureInPicture,
    IconForms,
    IconBorderAll,
    IconChartDots,
    IconStairsUp
};

// ==============================|| UI FORMS MENU ITEMS ||============================== //

const masterTables = {
    id: 'masterTables',
    title: <FormattedMessage id="MASTERTABLES" />,
    icon: icons.IconPictureInPicture,
    type: 'group',
    children:
        [
            // tabla Tarifas
                // TABLAS TARIFON MEX
                
            {
                id: 'TARIFAS',
                title: <FormattedMessage id="TARIFAS" />,
                // type: 'collapse',
                type: 'item',
                icon: PriceCheckOutlinedIcon,
                url: '/Tarifon/tarifas',
                breadcrumbs: false,
                children: [
                    {
                        id: 'tarifas',
                        title: <FormattedMessage id="tarifas" />,
                        type: 'item',
                        url: '/Tarifon/tarifas',
                        breadcrumbs: false
                    },
                ]
            },

            {
                id: 'NCM',
                title: <FormattedMessage id="NCM" />,
                // type: 'collapse',
                type: 'item',
                url: '/NCM/NCM',
                icon: MenuBookOutlinedIcon,
                breadcrumbs: false,
                
            },
            
            // LISTA DE Constantes
            {
                id: 'CONSTANTES',
                title: <FormattedMessage id="CONSTANTES" />,
                type: 'collapse',
                icon: FormatListNumberedOutlinedIcon,
                children: [
                    // tabla PAIS
                    {
                        id: 'pais-region',
                        title: <FormattedMessage id="pais-region" />,
                        type: 'item',
                        icon: PublicOutlinedIcon,
                        url: '/paisRegion/paisRegion',
                        /*children: [
                            {
                                id: 'canal',
                                title: <FormattedMessage id="canal" />,
                                type: 'item',
                                url: '/canal/canal',
                                breadcrumbs: false
                            },
                        ]*/
                    },
                    // CARGA
                    {
                        id: 'carga',
                        title: <FormattedMessage id="carga" />,
                        type: 'item',
                        icon: LocalShippingOutlinedIcon,
                        url: '/carga/carga',
                    },
                    // tabla CANAL
                    {
                        id: 'canal',
                        title: <FormattedMessage id="canal" />,
                        type: 'item',
                        icon: TrafficOutlinedIcon,
                        url: '/canal/canal',
                    },
                    // LISTA DE IMPUESTOS
                    {
                        id: 'impuestos',
                        title: <FormattedMessage id="impuestos" />,
                        type: 'item',
                        icon: PaidOutlinedIcon,
                        url: '/impuestos/impuestos',
                    },
                ]
            },
            {
                id: 'Proveedores',
                title: <FormattedMessage id="PROVEEDORES" />,
                type: 'collapse',
                icon: MiscellaneousServicesOutlinedIcon,
                children:
                    [
                        {
                            id: 'banco',
                            title: <FormattedMessage id="banco" />,
                            type: 'item',
                            icon: AccountBalanceOutlinedIcon,
                            url: '/banco/banco',
                        },
                        // tabla fwdtte
                        {
                            id: 'fwdtte',
                            title: <FormattedMessage id="fwdtte" />,
                            type: 'item',
                            icon: LocalShippingOutlinedIcon,
                            url: '/fwdtte/fwdtte',
                        },
                        // LISTA DE Terminal
                        {
                            id: 'terminal',
                            title: <FormattedMessage id="terminal" />,
                            type: 'item',
                            icon: WarehouseOutlinedIcon,
                            url: '/terminal/terminal',
                        },
                        // LISTA DE Custodia
                        {
                            id: 'custodia',
                            title: <FormattedMessage id="custodia" />,
                            type: 'item',
                            icon: AdminPanelSettingsOutlinedIcon,
                            url: '/custodia/custodia',
                        },
                        // LISTA DE Flete
                        {
                            id: 'flete',
                            title: <FormattedMessage id="flete" />,
                            type: 'item',
                            icon: LocalShippingOutlinedIcon,
                            url: '/flete/flete',
                        },
                        // LISTA DE Truck
                        {
                            id: 'truck',
                            title: <FormattedMessage id="truck" />,
                            // type: 'collapse',
                            type: 'item',
                            url: '/truck/truck',
                            icon: LocalShippingOutlinedIcon,
                            children: [
                                {
                                    id: 'truck',
                                    title: <FormattedMessage id="truck" />,
                                    type: 'item',
                                    url: '/truck/truck',
                                    breadcrumbs: false
                                },
                            ]
                        },
                        // LISTA DE Despechantes
                        {
                            id: 'despachante',
                            title: <FormattedMessage id="despachante" />,
                            type: 'item',
                            icon: PersonOutlineOutlinedIcon,
                            url: '/despachante/despachante',
                        },
                        // LISTA DE Deposito
                        {
                            id: 'deposito',
                            title: <FormattedMessage id="DEPOSITO" />,
                            type: 'item',
                            icon: WarehouseOutlinedIcon,
                            url: '/deposito/deposito',
                        },
                        // LISTA DE GestDigitalDoc
                        {
                            id: 'GestDigitalDoc',
                            title: <FormattedMessage id="GestDigitalDoc" />,
                            type: 'item',
                            icon: CopyAllOutlinedIcon,
                            url: '/GestDigitalDoc/GestDigitalDoc',
                        },
                        // LISTA DE Poliza
                        {
                            id: 'poliza',
                            title: <FormattedMessage id="poliza" />,
                            type: 'item',
                            icon: ShieldOutlinedIcon,
                            url: '/poliza/poliza',
                        },
                        // LISTA DE Proveedor OEM
                        {
                            id: 'proveedoresOem',
                            title: <FormattedMessage id="proveedoresOem" />,
                            // type: 'collapse',
                            type: 'item',
                            url: '/proveedoresOem/proveedoresOem',
                            icon: FactoryOutlinedIcon,
                            children: [
                                {
                                    id: 'proveedoresOem',
                                    title: <FormattedMessage id="proveedoresOem" />,
                                    type: 'item',
                                    url: '/proveedoresOem/proveedoresOem',
                                    breadcrumbs: false
                                },
                            ]
                        },
                        
                    ]
            },

        ]
};

export default masterTables;
