import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { createContext, useState, useContext, useEffect } from 'react';
import { AppGlobalContext } from './GlobalContext';

const ForecastHistoryFilterContext = createContext(null);

export default function ForecastHistoryFiltersProvider({ children }) {
	const { mainCountry } = useContext(AppGlobalContext);
	const { accessToken } = useAuth();

	const [owners, setOwners] = useState([]);
	const [selectedOwner, setSelectedOwner] = useState(-1);
	const [masterCountries, setMasterCountries] = useState([]);
	const [marketplacesMaestro, setMarketplacesMaestro] = useState([]);
	const [marketplaces, setMarketplaces] = useState([]);
	const [selectedMarketplace, setSelectedMarketplace] = useState(-1);
	const [selectedStatus, setSelectedStatus] = useState(-1);
	const [selectedApprovalType, setSelectedApprovalType] = useState('IGNORE');
	const [country, setCountry] = useState(null);
	const [search, setSearch] = useState('');
	const [motives, setMotives] = useState([]);
	const [selectedMotive, setSelectedMotive] = useState(-1);
	const [selectedDate, setSelectedDate] = useState('IGNORE');

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0,
	});

	useEffect(() => {
		const fetchCountries = async () => {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/Maestro/country`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}
			);
			if (res.status === 200) {
				setMasterCountries(res.data);
				setCountry(
					res.data.find(
						(y) => y.name.toLowerCase() === mainCountry.pais.toLowerCase()
					)
				);
			}
		};

		fetchCountries();
	}, [accessToken, mainCountry]);

	useEffect(() => {
		const fetchOwners = async () => {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/Maestro/user`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}
			);
			if (res.status === 200) {
				setOwners(res.data);
			}
		};
		const fetchMotives = async () => {
			const response = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/forecast/motivo`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			setMotives(response.data);
		};
		fetchMotives();

		fetchOwners();
	}, [accessToken]);

	useEffect(() => {
		const fetchMarketplaces = async () => {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/Maestro/marketplacecountryvista`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}
			);
			if (res.status === 200) {
				setMarketplacesMaestro(res.data);
				setMarketplaces(res.data.filter((y) => y.country_id === country.id));
			}
		};

		if (country) fetchMarketplaces();
	}, [accessToken, country]);

	return (
		<ForecastHistoryFilterContext.Provider
			value={{
				owners,
				setOwners,
				search,
				setSearch,
				masterCountries,
				country,
				setCountry,
				marketplacesMaestro,
				marketplaces,
				selectedOwner,
				setSelectedOwner,
				selectedMarketplace,
				setSelectedMarketplace,
				selectedStatus,
				setSelectedStatus,
				paginationModel,
				setPaginationModel,
				selectedApprovalType,
				setSelectedApprovalType,
				motives,
				setMotives,
				selectedMotive,
				setSelectedMotive,
				selectedDate,
				setSelectedDate,
			}}
		>
			{children}
		</ForecastHistoryFilterContext.Provider>
	);
}

export const useForecastHistoryFilters = () => {
	const context = useContext(ForecastHistoryFilterContext);
	if (context === undefined) {
		throw new Error(
			'useForecastFilters must be used within a ForecastFiltersProvider'
		);
	}
	return context;
};
