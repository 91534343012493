import { Box, Grid, Stack, Typography } from '@mui/material';
import { formatearNumeroConComas } from './utils';
import InfoTooltip from './InfoTooltip';

export default function MainColumnHeader({ year, headers }) {
	const yearGMV = Object.values(headers).reduce(
		(acc, curr) => {
			return {
				total_gmv_us: acc.total_gmv_us + curr.gmv_sum_us_month,
				total_gmv_local: acc.total_gmv_local + curr.gmv_sum_loc_month,
			};
		},
		{
			total_gmv_us: 0,
			total_gmv_local: 0,
		}
	);

	return (
		<Box
			sx={{
				backgroundColor: '#35488740',
				padding: '24px 16px',

				fontSize: '1.5rem',
				height: '164px',
				width: '325px',

				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Stack>
				<Typography
					sx={{
						color: '#36498A',
						fontWeight: 500,
					}}
				>
					Forecast
				</Typography>
				<Typography
					sx={{
						color: '#36498A',
						fontWeight: 600,
						fontSize: '36px',
						fontFamily: 'Montserrat',
						lineHeight: '24px',
						letterSpacing: '0.17px',
					}}
				>
					{year.value}
				</Typography>
			</Stack>

			<Grid container>
				<Grid item xs={6}>
					<Stack direction="row" alignItems="center" spacing="2px">
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '12px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							GMV USD Anual
						</Typography>
						<InfoTooltip title="GMV USD Anual Total" />
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Typography
						sx={{
							color: '#000000DE',
							fontWeight: 600,
							fontFamily: 'Roboto',
							fontSize: '12px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
						}}
					>
						USD {formatearNumeroConComas(yearGMV.total_gmv_us)}
					</Typography>
				</Grid>

				<Grid item xs={6}>
					<Stack direction="row" alignItems="center" spacing="2px">
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '12px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							GMV Local Anual
						</Typography>
						<InfoTooltip title="GMV Local Anual Total" />
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Typography
						sx={{
							color: '#000000DE',
							fontWeight: 600,
							fontFamily: 'Roboto',
							fontSize: '12px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
						}}
					>
						${formatearNumeroConComas(yearGMV.total_gmv_local)}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
