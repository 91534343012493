// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';
import { UtilidadesHelper } from 'helpers/UtilidadesHelper';

// ----------------------------------------------------------------------
const baseUrl = process.env.REACT_APP_BASE_URL;
const rutaTabla = 'Presupuesto';

const initialState = {
    error: null,

    //hay que declarar el estado inicial
    tipoDeCambio: [],
};

const slice = createSlice({
    name: 'utilidades',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET presupuestos
        getTipoDeCambioSuccess(state, action) {
            state.tipoDeCambio = action.payload;
        },
        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCotizaciones() {
    return async (dispatch) => {
        try {
            const response = await UtilidadesHelper.tipoCambioGeneral(); 
            dispatch(slice.actions.getTipoDeCambioSuccess(response.quotes));
            // console.log('Get Cotizaciones:', response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};
