import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import SelectCategoriesDrawer from './SelectCategoriesDrawer';
import CreateEventModal from './CreateEvenModal';
import dayjs from 'dayjs';

export default function CreateEditCampaignModal({ campaign, open, onClose }) {
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>{campaign ? 'Editar Campaña' : 'Crear Campaña'}</DialogTitle>
			<DialogContent>
				<CreateEditCampaignForm campaign={campaign} onClose={onClose} />
			</DialogContent>
		</Dialog>
	);
}

const CampaignSchema = Yup.object().shape({
	tier: Yup.number().required('El tier es requerido'),
	event: Yup.string().required('El evento es requerido'),
	from: Yup.date().required('La fecha de inicio es requerida'),
	to: Yup.date().required('La fecha de fin es requerida'),
	categories: Yup.array(Yup.string()).required('Las categorías son requeridas'),
});

function CreateEditCampaignForm({ campaign, onClose }) {
	const formik = useFormik({
		initialValues: {
			tier: campaign?.tier || '',
			event: campaign?.event?.toLowerCase() || '',
			from: new Date(campaign?.from) || null,
			to: new Date(campaign?.to) || null,
			categories: campaign?.categories || [],
		},
		validationSchema: CampaignSchema,
		onSubmit: (values) => {
			if (campaign) {
				// edit campaign
			} else {
				// create campaign
			}
			onClose();
		},
	});
	const [limits, setLimits] = useState(
		campaign?.categories?.length > 0 &&
			campaign?.categories[0] === 'Todos los productos'
			? 'all'
			: 'categories'
	);

	console.log('formik', formik.values);

	const setCategories = (categories) => {
		formik.setFieldValue('categories', categories);
	};

	return (
		<Box
			component="form"
			onSubmit={formik.handleSubmit}
			noValidate
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<FormControl fullWidth>
				<Typography
					component="label"
					htmlFor="tier"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Tipo de evento
				</Typography>
				<Select
					value={formik.values.tier}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					name="tier"
					id="tier"
					displayEmpty
					error={formik.touched.tier && Boolean(formik.errors.tier)}
				>
					<MenuItem value="" disabled>
						<Typography
							sx={{
								color: '#00000099',
							}}
						>
							-Elegir tipo de evento-
						</Typography>
					</MenuItem>
					<MenuItem value={1}>Tier 1</MenuItem>
					<MenuItem value={2}>Tier 2</MenuItem>
					<MenuItem value={3}>Tier 3</MenuItem>
					<MenuItem value={3}>Tier 4</MenuItem>
				</Select>
			</FormControl>

			<FormControl fullWidth>
				<Typography
					component="label"
					htmlFor="event"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Evento
				</Typography>
				<Select
					value={formik.values.event}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.event && Boolean(formik.errors.event)}
					name="event"
					id="event"
					displayEmpty
				>
					<MenuItem value="" disabled>
						<Typography
							sx={{
								color: '#00000099',
							}}
						>
							-Elegir evento-
						</Typography>
					</MenuItem>
					<MenuItem value={'black friday'}>Black Friday</MenuItem>
					<MenuItem value={'cyber monday'}>Cyber Monday</MenuItem>
					<MenuItem value={'christmas'}>Christmas</MenuItem>
					<Divider />

					<Box
						sx={{
							padding: '6px',
						}}
					>
						<CreateEventModal />
					</Box>
				</Select>
			</FormControl>

			<FormControl fullWidth>
				<Typography
					component="label"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Vigencia
				</Typography>
				<Stack direction="row" spacing={2}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Desde"
							id="from"
							name="from"
							inputFormat="YYYY-MM-DD"
							value={formik.values.from}
							onChange={(newValue) => {
								if (!newValue) return;

								formik.setFieldValue('from', newValue.format('YYYY-MM-DD'));
							}}
							onBlur={formik.handleBlur}
							renderInput={(params) => (
								<TextField
									{...params}
									error={formik.touched.from && Boolean(formik.errors.from)}
									fullWidth
								/>
							)}
						/>
					</LocalizationProvider>

					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Hasta"
							id="to"
							name="to"
							inputFormat="YYYY-MM-DD"
							value={formik.values.to}
							onChange={(newValue) => {
								if (!newValue) return;

								formik.setFieldValue('to', newValue.format('YYYY-MM-DD'));
							}}
							disablePast
							onBlur={formik.handleBlur}
							renderInput={(params) => (
								<TextField
									{...params}
									error={formik.touched.to && Boolean(formik.errors.to)}
									fullWidth
								/>
							)}
						/>
					</LocalizationProvider>
				</Stack>
			</FormControl>

			<FormControl fullWidth>
				<Typography
					component="label"
					htmlFor="limits"
					sx={{
						fontSize: '14px',
						fontWeight: 500,
						marginBottom: '8px',
						fontFamily: 'Roboto',
					}}
				>
					Limites
				</Typography>
				<Select
					value={limits}
					onChange={(e) => {
						if (e.target.value === 'all') {
							setCategories(['all']);
						}
						setLimits(e.target.value);
					}}
					name="limits"
					id="limits"
					displayEmpty
				>
					<MenuItem value="" disabled>
						<Typography
							sx={{
								color: '#00000099',
							}}
						>
							-Elegir limite-
						</Typography>
					</MenuItem>
					<MenuItem value={'all'}>Todos los productos</MenuItem>
					<MenuItem value={'categories'}>Categorias</MenuItem>
				</Select>
			</FormControl>

			<SelectCategoriesDrawer
				categories={[
					'Adultos',
					'Deportes',
					'Fitness',
					'Hogar',
					'Moda',
					'Niños',
				]}
				pickedCategories={formik.values.categories}
				setCategories={setCategories}
				disabled={limits !== 'categories'}
			/>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button onClick={onClose} variant="outlined">
					Cancelar
				</Button>
				<Button type="submit" variant="contained">
					Guardar
				</Button>
			</Stack>
		</Box>
	);
}
