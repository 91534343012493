import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { createContext, useState, useContext, useCallback } from 'react';

const ForecastFlowContext = createContext();

export default function ForecastFlowProvider({ children }) {
	const { accessToken } = useAuth();

	const [data, setData] = useState(null);
	const [employees, setEmployees] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchForecastFlowData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await Promise.all([
				axios.get(`${process.env.REACT_APP_API_URL}/forecast/autoapro/`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}),
				axios.get(`${process.env.REACT_APP_API_URL}/forecast/employee`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}),
			]);

			setData(
				res[0]?.data
					.map((item) => ({
						...item,
						users: item?.applyto?.map(
							(userId) =>
								res[1]?.data?.find((owner) => owner?.id === userId)?.name
						),
					}))
					.sort((a, b) => new Date(b?.desde) - new Date(a?.desde))
			);
			setEmployees(res[1]?.data);
		} catch (error) {
			console.error('error', error);
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [accessToken]);

	const deleteForecastFlow = useCallback(
		async (id) => {
			try {
				await axios.delete(
					`${process.env.REACT_APP_API_URL}/forecast/autoapro/${id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				);

				await fetchForecastFlowData();
			} catch (error) {
				console.error('error', error);
			}
		},
		[accessToken, fetchForecastFlowData]
	);

	return (
		<ForecastFlowContext.Provider
			value={{
				data,
				employees,
				loading,
				error,
				fetchForecastFlowData,
				deleteForecastFlow,
			}}
		>
			{children}
		</ForecastFlowContext.Provider>
	);
}

export const useForecastFlow = () => {
	const context = useContext(ForecastFlowContext);
	if (context === undefined) {
		throw new Error('useForecastFlow must be used within a ForecastProvider');
	}
	return context;
};
