import { Box, Stack, Typography } from '@mui/material';
import {
	formatearNumeroConComas,
	getMonthName,
	getQuarterMonth,
} from './utils';
import QuarterTag from './QuarterTag';

import { useMemo } from 'react';
import InfoTooltip from './InfoTooltip';
import MonthEvents from './MonthEvents';

export default function MonthColumnHeader({
	month,
	dataLength,
	headers,
	filteredData,
	events,
}) {
	const subtotalData = useMemo(() => {
		if (dataLength === filteredData.length) {
			return {
				subtotal_tsu: headers.tsu_sum,
				subtotal_gmv_tc_local: headers.gmv_sum_loc_month,
				subtotal_gmv_us: headers.gmv_sum_us_month,
			};
		}

		return filteredData.reduce(
			(acc, curr) => {
				let subtotal_tsu = 0;
				let subtotal_gmv_tc_local = 0;
				let subtotal_gmv_us = 0;
				for (const item of curr.data[month]) {
					subtotal_tsu += item.apro ? item.apro.tsu : item.def.tsu;
					subtotal_gmv_tc_local += item.apro
						? item.apro.gmv_local
						: item.def.gmv_local;
					subtotal_gmv_us += item.apro ? item.apro.gmv_usd : item.def.gmv_usd;
				}

				return {
					subtotal_tsu: acc.subtotal_tsu + subtotal_tsu,
					subtotal_gmv_tc_local:
						acc.subtotal_gmv_tc_local + subtotal_gmv_tc_local,
					subtotal_gmv_us: acc.subtotal_gmv_us + subtotal_gmv_us,
				};
			},
			{ subtotal_tsu: 0, subtotal_gmv_tc_local: 0, subtotal_gmv_us: 0 }
		);
	}, [dataLength, filteredData, month, headers]);

	return (
		<Box
			sx={{
				height: '164px',
				width: '560px',
				border: '1px solid #35488740',
				padding: '16px',
			}}
		>
			<Stack direction="row" spacing={2}>
				<Stack
					sx={{
						width: '35%',
					}}
					spacing={1}
				>
					<Stack direction="row" spacing="18px" alignItems="center">
						<QuarterTag quarter={getQuarterMonth(month)} />
						<Typography
							sx={{
								color: '#000000BF',
								fontWeight: 600,
								fontSize: '20px',
								fontFamily: 'Roboto',
								lineHeight: '24px',
								letterSpacing: '0.15px',
								textTransform: 'uppercase',
							}}
						>
							{getMonthName(month)}
						</Typography>
					</Stack>

					<Stack>
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							FX
						</Typography>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="Tasa de cambio" />

							<Typography
								sx={{
									color: '#000000DE',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								${formatearNumeroConComas(headers?.tc_local)}
							</Typography>
						</Stack>
					</Stack>

					{events.length > 0 && <MonthEvents events={events} />}
				</Stack>

				<Stack
					sx={{
						borderLeft: '1px solid #35488740',
						borderRight: '1px solid #35488740',
						width: '35%',
						paddingX: '16px',
					}}
				>
					<Stack>
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							GMV USD
						</Typography>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="GMV USD total" />

							<Typography
								sx={{
									color: '#000000DE',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								U$D {formatearNumeroConComas(headers.gmv_sum_us_month)}
							</Typography>
						</Stack>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="GMV USD subtotal" />

							<Typography
								sx={{
									color: '#00000061',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								U$D {formatearNumeroConComas(subtotalData.subtotal_gmv_us)}
							</Typography>
						</Stack>
					</Stack>
					<Stack>
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							GMV Local
						</Typography>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="GMV Local total" />

							<Typography
								sx={{
									color: '#000000DE',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								${formatearNumeroConComas(headers.gmv_sum_loc_month)}
								{/* {headers.total_gmv_tc_local > 1000000 ? (
									<TooltipDescription
										description={formatearNumeroConComas(
											headers.total_gmv_tc_local
										)}
										title={`${formatearNumeroConComas(
											headers.total_gmv_tc_local / 1000000
										)}M`}
									/>
								) : (
									formatearNumeroConComas(headers.total_gmv_tc_local)
								)} */}
							</Typography>
						</Stack>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="GMV Local subtotal" />

							<Typography
								sx={{
									color: '#00000061',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								${formatearNumeroConComas(subtotalData.subtotal_gmv_tc_local)}
								{/* {subtotalData.subtotal_gmv_tc_local > 1000000 ? (
									<TooltipDescription
										description={formatearNumeroConComas(
											subtotalData.subtotal_gmv_tc_local
										)}
										title={`${formatearNumeroConComas(
											subtotalData.subtotal_gmv_tc_local / 1000000
										)}M`}
									/>
								) : (
									formatearNumeroConComas(subtotalData.subtotal_gmv_tc_local)
								)} */}
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				<Stack
					sx={{
						width: '30%',
					}}
				>
					<Stack>
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							TSU
						</Typography>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="TSU total" />

							<Typography
								sx={{
									color: '#000000DE',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								{formatearNumeroConComas(headers.tsu_sum)}
							</Typography>
						</Stack>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="TSU subtotal" />

							<Typography
								sx={{
									color: '#00000061',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								{formatearNumeroConComas(subtotalData.subtotal_tsu)}
							</Typography>
						</Stack>
					</Stack>
					<Stack>
						<Typography
							sx={{
								color: '#00000061',
								fontWeight: 600,
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							Contribución
						</Typography>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="Contribución total" />

							<Typography
								sx={{
									color: '#000000DE',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								100%
							</Typography>
						</Stack>
						<Stack direction={'row'} alignItems="center">
							<InfoTooltip title="Contribución subtotal" />

							<Typography
								sx={{
									color: '#00000061',
									fontWeight: 400,
									fontFamily: 'Roboto',
									fontSize: '12px',
									lineHeight: '24px',
									letterSpacing: '0.15px',
									paddingLeft: '8px',
								}}
							>
								{headers.total_gmv_us !== 0
									? formatearNumeroConComas(
											(subtotalData.subtotal_gmv_us /
												headers.gmv_sum_us_month) *
												100
									  )
									: 0}
								%
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}
