// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { CargaHelper } from 'helpers/Costos/CargaHelper';
import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

export const initialState = {
    error: null,
    cargas: [],
    cargaById: null,
    presupuesto: null,
    historicoRedux: null,
    diferenciasRedux: null,
};

const slice = createSlice({
    name: 'carga',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getCargasSuccess(state, action) {
            state.cargas = action.payload;
        },

        // GET PRODUCT
        getCargaByIdSuccess(state, action) {
            state.cargaById = action.payload;
        },

        getPresupuestoSuccess(state, action){
            state.presupuesto = action.payload;
        },

        getHistoricoSuccess(state, action){
            state.historicoRedux = action.payload;
        },

        getDiferenciasSuccess(state, action){
            state.diferenciasRedux = action.payload;
        },

        cleanPresupuestoSuccess(state, action){
            state.presupuesto = [];
            state.historicoRedux = [];
            state.diferenciasRedux = [];
        },


    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCargas(accessToken) {
    return async () => {
        try {
            const response = await CargaHelper.fetchData(accessToken);
            
            dispatch(slice.actions.getCargasSuccess(response));
            console.log('Get Cargas :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCargabyId(id, accessToken) {
    return async () => {
        try {
            const response = await CargaHelper.readDataById( id, accessToken);
            dispatch(slice.actions.getCargaByIdSuccess(response));
            console.log('Get Carga by ID :', response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function consultarPresupuesto(estnumber, vers, accessToken){
    return async () => {
        try {
            const response = await PresupuestoHelper.readDataEstVers(estnumber, vers, accessToken);
            dispatch(slice.actions.getPresupuestoSuccess(response))
            console.log('Get presupuesto :');
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function consultarPresupuestoVS(estnumber,accessToken){
    return async () => {
        try {
            const response = await PresupuestoHelper.readDataVirtualSellerById(estnumber, accessToken);
            dispatch(slice.actions.getPresupuestoSuccess(response))
            console.log('Get presupuesto VS :');
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function PresupuestoHistorico(estnumber, accessToken){
    return async () => {
        try {
            const response = await PresupuestoHelper.fetchDataHistorico(estnumber, accessToken);
            dispatch(slice.actions.getHistoricoSuccess(response))
            console.log('Get Historico :');
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
} 

export function PresupuestoDiferencias(estnumber, accessToken){
    return async () => {
        try {
            const response = await PresupuestoHelper.fetchLogHistorico(estnumber, accessToken);
            dispatch(slice.actions.getDiferenciasSuccess(response))
            console.log('Get Diferencias :');
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function cleanPresupuesto(){
    return (dispatch, getState) => {
        try {
            dispatch(slice.actions.cleanPresupuestoSuccess())
            let estados = getState()
            // console.log('ESTADOS ACTUALIZADOS', estados);
            console.log('Action se limpia Presupuesto :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}
