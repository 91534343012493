import axios from 'axios';
import useAuth from 'hooks/useAuth';
import {
	createContext,
	useState,
	useContext,
	useEffect,
	useCallback,
} from 'react';
import { useForecastHistoryFilters } from './ForecastHistoryFiltersContext';

const ForecastHistoryContext = createContext();

export default function ForecastHistoryProvider({ children }) {
	const { accessToken } = useAuth();
	const {
		country,
		selectedOwner,
		selectedMarketplace,
		selectedStatus,
		paginationModel,
		selectedApprovalType,
		motives,
		selectedMotive,
		selectedDate,
		search,
	} = useForecastHistoryFilters();

	const [data, setData] = useState(null);
	const [count, setCount] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchForecastHistoryData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await Promise.all([
				axios.get(
					`${process.env.REACT_APP_API_URL}/forecastdet/historico/${
						country.id
					}/${paginationModel.pageSize}/${
						paginationModel.page + 1
					}/-1/-1/${selectedMarketplace}/${selectedOwner}/IGNORE/${selectedStatus}/${selectedApprovalType}/${selectedMotive}/-1/${
						search ? search : 'IGNORE'
					}/${selectedDate}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				),
				axios.get(
					`${process.env.REACT_APP_API_URL}/forecastdet/historicocounts/${
						country.id
					}/-1/-1/${selectedMarketplace}/${selectedOwner}/IGNORE
						/${selectedApprovalType}/${selectedMotive}/-1/${
						search ? search : 'IGNORE'
					}/${selectedDate}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				),
				axios.get(
					`${process.env.REACT_APP_API_URL}/Maestro/sku/${country.id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							accept: '*/*',
						},
					}
				),
				axios.get(`${process.env.REACT_APP_API_URL}/forecast/employee`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accept: '*/*',
					},
				}),
			]);

			setData(
				res[0].data
					.map((item) => ({
						...item,
						motive: motives?.find((m) => m?.id === item?.motivo_id)?.motivo_str,
						sku: res[2]?.data?.find((sku) => sku?.id === item?.product_id)?.sku,
						category_manager: res[3]?.data?.find(
							(category) => category?.id === item?.cm_creador_id
						)?.name,
					}))
					.sort(
						(a, b) =>
							new Date(b?.htimestamp_inic) - new Date(a?.htimestamp_inic)
					)
			);
			setCount(res[1].data);
		} catch (error) {
			console.error('error', error);
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [
		accessToken,
		country?.id,
		selectedOwner,
		selectedMarketplace,
		selectedStatus,
		paginationModel?.pageSize,
		paginationModel?.page,
		selectedApprovalType,
		selectedMotive,
		selectedDate,
		search,
		motives,
	]);

	useEffect(() => {
		if (country && motives.length > 0) {
			fetchForecastHistoryData();
		}
	}, [country, motives.length, fetchForecastHistoryData]);

	return (
		<ForecastHistoryContext.Provider
			value={{ data, count, loading, error, fetchForecastHistoryData }}
		>
			{children}
		</ForecastHistoryContext.Provider>
	);
}

export const useForecastHistory = () => {
	const context = useContext(ForecastHistoryContext);
	if (context === undefined) {
		throw new Error(
			'useForecastHistory must be used within a ForecastProvider'
		);
	}
	return context;
};
