import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

const fetchCountries = async (accessToken) => {
	const res = await axios.get(
		`${process.env.REACT_APP_API_URL}/Maestro/country`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				accept: '*/*',
			},
		}
	);

	return res;
};

export default function useCountriesData() {
	const { accessToken } = useAuth();

	const countriesQuery = useQuery({
		queryKey: ['countries'],
		queryFn: () => fetchCountries(accessToken),
		select: (data) => {
			return data?.data;
		},
		staleTime: Infinity,
		retry: false,
	});

	return countriesQuery;
}
