import { IconButton } from '@mui/material';
import {
	gridDetailPanelExpandedRowsContentCacheSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { Box } from '@mui/material';

export default function ForecastCustomDetailPanelToggle(props) {
	const { id, value: isExpanded } = props;
	const apiRef = useGridApiContext();

	// To avoid calling ´getDetailPanelContent` all the time, the following selector
	// gives an object with the detail panel content for each row id.
	const contentCache = useGridSelector(
		apiRef,
		gridDetailPanelExpandedRowsContentCacheSelector
	);

	// If the value is not a valid React element, it means that the row has no detail panel.
	const hasDetail = React.isValidElement(contentCache[id]);

	const hasPending = Object.values(props.row.data).some((monthRow) =>
		monthRow.some((marketplaceRow) => marketplaceRow.pend)
	);

	const hasRejected = Object.values(props.row.data).some((monthRow) =>
		monthRow.some((marketplaceRow) => marketplaceRow.reject)
	);

	const statusClassName = hasPending
		? 'triangle-top-left-pend'
		: hasRejected
		? 'triangle-top-left-can'
		: '';

	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<Box
				className={statusClassName}
				sx={{
					position: 'absolute',
					top: '0',
					left: '0',
				}}
			/>

			{hasPending && hasRejected && (
				<Box
					className="triangle-bottom-left-can"
					sx={{
						position: 'absolute',
						bottom: '0',
						left: '0',
					}}
				/>
			)}
			<IconButton
				size="small"
				tabIndex={-1}
				disabled={!hasDetail}
				aria-label={isExpanded ? 'Close' : 'Open'}
			>
				<ArrowDropDownIcon
					sx={{
						transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
						transition: (theme) =>
							theme.transitions.create('transform', {
								duration: theme.transitions.duration.shortest,
							}),
						width: '24px',
						height: '24px',
					}}
					fontSize="inherit"
				/>
			</IconButton>
		</Box>
	);
}
