import { Alert, AlertTitle, IconButton, Snackbar } from '@mui/material';
import { useForecastAlert } from 'contexts/ForecastAlertContext';
import CloseIcon from '@mui/icons-material/Close';

export default function ForecastAlert() {
	const { alertState, setAlertState } = useForecastAlert();
	return (
		<Snackbar
			open={alertState.open}
			autoHideDuration={6000}
			onClose={() => setAlertState((prev) => ({ ...prev, open: false }))}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<Alert
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => setAlertState((prev) => ({ ...prev, open: false }))}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
				sx={{ width: '100%' }}
				severity={alertState.severity}
			>
				<AlertTitle>{alertState.title}</AlertTitle>
				{alertState.message}
			</Alert>
		</Snackbar>
	);
}
