// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    orders: [],

    //hay que declarar el estado inicial
    sourcing: [],
    sourcingRespuesta: false, //esta respuesta booleana sirve para mostrar el componente si tienes o no presupuestos, ya que todo lo manejamos como array y no hay manera de distinguir la primera instancia, osea ser nuevo
    sourcingPO: [],
    sourcingPORespuesta: false,
};

const slice = createSlice({
    name: 'sourcing',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET Inbound
        getSourcingSuccess(state, action) {
            state.sourcing = action.payload;
            state.sourcingRespuesta = true;
        },

        getSourcingPOSuccess(state, action) {
            state.sourcingPO = action.payload;
            state.sourcingPORespuesta = true;
        },

        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getSourcing(accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.fetchDataSourcing(accessToken); 
            dispatch(slice.actions.getSourcingSuccess(response));
            console.log('Get Sourcing :');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getSourcing_Id_pais( name, id, accessToken) {
    return async (dispatch, getState) => {
        try {
            // Acceder al estado completo de Redux
            const state = getState();
            const permisos = state.permisos.permisos; //accedo estado permisos
            // Verificar si alguno de los roles es 'CEO', 'Gerencia' o 'Lider'
            const rolesPermitidos = ['CEO', 'Gerencia', 'Lider'];
            const tienePermiso = permisos.some(permiso => rolesPermitidos.includes(permiso));

            let response;
            if(tienePermiso){
                response = await PresupuestoHelper.fetchDataSourcing_Id_Pais( 'all', id, accessToken);
            }else{
                response = await PresupuestoHelper.fetchDataSourcing_Id_Pais( name, id, accessToken); 
            }
            dispatch(slice.actions.getSourcingSuccess(response));
            console.log('Get Sourcing BC Id, Pais:');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getSourcing_PO_Id_pais( name, id, accessToken) {
    return async (dispatch, getState) => {
        try {
            // Acceder al estado completo de Redux
            const state = getState();
            const permisos = state.permisos.permisos; //accedo estado permisos
            // Verificar si alguno de los roles es 'CEO', 'Gerencia' o 'Lider'
            const rolesPermitidos = ['CEO', 'Gerencia', 'Lider'];
            const tienePermiso = permisos.some(permiso => rolesPermitidos.includes(permiso));
            
            let response;
            if(tienePermiso){
                response = await PresupuestoHelper.fetchDataSourcing_PO_Id_Pais( 'all', id, accessToken);
            }else{
                response = await PresupuestoHelper.fetchDataSourcing_PO_Id_Pais( name, id, accessToken); 
            }
            dispatch(slice.actions.getSourcingPOSuccess(response));
            console.log('Get Sourcing PO Id, Pais:');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getOrders() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/inbound/order/list');
            dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

