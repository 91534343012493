import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
// import DeleteIcon from '@mui/icons-material/Delete';
import CategoriesDrawer from './CategoriesDrawer';
import EventTag from './EventTag';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useEventsFilters } from 'contexts/EventsFiltersContext';
import { rangesOverlap } from './utils';
import CreateCampaign from './CreateCampaign';
import EditCampaign from './EditCampaign';

const columns = [
	{
		field: 'event',
		headerName: 'Evento',
		headerClassName: 'border-bottom border-left header border-top border-right',
		cellClassName: 'border-left',
		minWidth: 150,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: '100%',
				}}
			>
				<EventTag event={params.row} />
			</Box>
		),
	},
	// {
	// 	field: 'country',
	// 	headerName: 'Pais',
	// 	headerClassName: 'border-bottom header border-top border-right',
	// flex: 1,
	// 	resizable: false,
	// 	sortable: false,
	// 	headerAlign: 'center',
	// 	align: 'center',
	// },
	{
		field: 'validity',
		headerName: 'Vigencia',
		headerClassName: 'border-bottom header border-top border-right',
		minWidth: 150,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		valueGetter: (_value, row) => `${row.from} - ${row.to}`,
	},
	{
		field: 'categories',
		headerName: 'Aplicar a',
		headerClassName: 'border-right border-bottom header border-top',
		minWidth: 150,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		valueGetter: (value) => value.join(', '),
	},
	{
		field: 'actions',
		headerName: 'Acciones',
		type: 'actions',
		headerClassName: 'header border-bottom border-right border-top',
		cellClassName: 'border-right',
		minWidth: 150,
		flex: 1,
		getActions: (params) => [
			<EditCampaign key="edit" campaign={params.row} />,
			// <GridActionsCellItem
			// 	key="delete"
			// 	icon={<DeleteIcon sx={{ color: '#354887' }} />}
			// 	label="Delete"
			// 	onClick={() => console.log(params.id)}
			// />,
			<CategoriesDrawer key="view" categories={params.row.categories} />,
		],
		resizable: false,
		sortable: false,
	},
];

export default function EventsTable({ events }) {
	const { dateRange, search } = useEventsFilters();

	const filteredRows = useMemo(() => {
		return events.filter((event) => {
			let isValid = true;
			if (search) {
				isValid = event.event.toLowerCase().includes(search.toLowerCase());
			}
			if (isValid && dateRange[0] && dateRange[1]) {
				isValid = rangesOverlap(
					dateRange[0],
					dateRange[1],
					event.from,
					event.to
				);
			}
			return isValid;
		});
	}, [dateRange, search, events]);

	return (
		<DataGridPro
			// loading={loading}
			rows={filteredRows}
			getRowId={(row) => row.id}
			columns={columns}
			pageSize={5}
			rowsPerPageOptions={[5]}
			disableSelectionOnClick
			disableRowSelectionOnClick
			getDetailPanelHeight={() => 'auto'}
			disableColumnMenu
			pagination
			pageSizeOptions={[25, 50, 100]}
			disableVirtualization
			sx={{
				'& .MuiDataGrid-cell': {
					borderBottom: '1px solid #E0E0E0',
				},

				'& .border-right': {
					borderRight: '1px solid #E0E0E0',
				},
				'& .border-top': {
					borderTop: '1px solid #E0E0E0',
				},
				'& .border-left': {
					borderLeft: '1px solid #E0E0E0',
				},
				'& .border-bottom': {
					borderBottom: '2px solid #E0E0E0 !important',
				},
				'& .MuiDataGrid-columnHeader--emptyGroup': {
					borderBottom: '1px solid #E0E0E0',
				},
				'& .header': {
					backgroundColor: '#f3f4f6',
				},
				'& .header-special': {
					backgroundColor: '#e2e8f0',
				},
				'& .cell-special': {
					backgroundColor: '#f1f5f9',
				},

				'& .group': {
					height: '70px !important',
				},
				'& .group-header-special': {
					height: 'fit-content !important',
					padding: '0 !important',
				},

				'& .MuiDataGrid-scrollbar--vertical': {
					display: 'none',
				},
				'& .MuiDataGrid-filler': {
					height: 'fit-content !important',
				},
				'& .MuiDataGrid-main': {
					paddingBottom: '16px !important',
				},
				'& .no-padding': {
					padding: '0 !important',
				},

				// height: 'calc(100vh - 518px)',
			}}
		/>
	);
}
