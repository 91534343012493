import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import StatusField from './StatusField';
import { useForecastHistoryFilters } from 'contexts/ForecastHistoryFiltersContext';

const columns = [
	{
		field: 'estado_id',
		headerName: 'Estado',
		headerClassName: 'header border-bottom',
		renderCell: (params) => {
			return (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100%',
					}}
				>
					<StatusField status={params.value} />
				</Box>
			);
		},
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'aprobado_auto',
		headerName: 'Tipo de aprobación',
		headerClassName: 'header border-bottom',
		valueFormatter: (value) => {
			if (typeof value !== 'boolean') {
				return '';
			}
			return value ? 'Automática' : 'Manual';
		},
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'category_manager',
		headerName: 'Category Manager',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'sku',
		headerName: 'SKU',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'motive',
		headerName: 'Motivos',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'detalle',
		headerName: 'Comentario',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'htimestamp_inic',
		headerName: 'Fecha de solicitud',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		valueFormatter: (value) => {
			if (value == null) {
				return '';
			}
			return new Date(value).toLocaleDateString();
		},
	},
	{
		field: 'htimestamp',
		headerName: 'Fecha de modificación',
		headerClassName: 'header border-bottom',
		minWidth: 90,
		flex: 1,
		resizable: false,
		sortable: false,
		headerAlign: 'center',
		align: 'center',
		valueFormatter: (value) => {
			if (value == null) {
				return '';
			}
			return new Date(value).toLocaleDateString();
		},
	},
];

export default function HistoryTable({ data, count, loading }) {
	const { paginationModel, setPaginationModel, selectedStatus } =
		useForecastHistoryFilters();

	return (
		<DataGridPro
			loading={loading}
			rows={data}
			rowCount={
				selectedStatus === -1
					? count[0] + count[1]
					: selectedStatus === 2
					? count[0]
					: count[1]
			}
			getRowId={(row) => row.id}
			columns={columns}
			pageSize={5}
			rowsPerPageOptions={[5]}
			getDetailPanelHeight={() => 'auto'}
			disableColumnMenu
			pagination
			paginationMode="server"
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			pageSizeOptions={[5, 10, 25]}
			disableRowSelectionOnClick
			disableVirtualization
			sx={{
				'& .MuiDataGrid-cell': {
					borderBottom: '1px solid #E0E0E0',
				},

				'& .border-right': {
					borderRight: '1px solid #E0E0E0',
				},
				'& .border-bottom': {
					borderBottom: '1px solid #E0E0E0',
				},
				'& .MuiDataGrid-columnHeader--emptyGroup': {
					borderBottom: '1px solid #E0E0E0',
				},
				'& .header': {
					backgroundColor: '#f5f5f5',
				},

				'& .MuiDataGrid-scrollbar--vertical': {
					display: 'none',
				},
				'& .MuiDataGrid-filler': {
					height: 'fit-content !important',
				},
				'& .MuiDataGrid-main': {
					paddingBottom: '16px !important',
				},

				'& .MuiToolbar-root': {
					padding: '0 !important',
				},

				height: 'calc(100vh - 260px)',
				// height: '100%',
			}}
		/>
	);
}
