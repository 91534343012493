import { Card, CardContent, Typography } from '@mui/material';
import { getMonthName } from './utils';
import EventTag from './EventTag';

function isDateInRange(date, startDate, endDate) {
	const targetDate = new Date(date);
	const start = new Date(startDate);
	const end = new Date(endDate);
	return targetDate >= start && targetDate <= end;
}

export default function DayCard({ day, events }) {
	const dayEvents = events.filter((event) =>
		isDateInRange(day.date, event.from, event.to)
	);

	return (
		<Card
			sx={{
				margin: '10px 10px',
				boxShadow: '0 0 12px rgba(0, 0, 0, 0.1)',
				height: '200px',
			}}
		>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					height: '100%',
					overflowY: 'auto',
				}}
			>
				<Typography variant="h6" component="div">
					{day.day} {getMonthName(day.month)}
				</Typography>

				{dayEvents.map((event) => (
					<EventTag key={event.id} event={event} />
				))}
			</CardContent>
		</Card>
	);
}
