import { Box, Typography, Stack } from '@mui/material';
import MainColumnHeader from '../MainColumnHeader';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import * as _ from 'lodash';

import LabelTag from '../LabelTag';
import MonthColumnHeader from '../MonthColumnHeader';

import ForecastCustomDetailPanelToggle from '../ForecastCustomDetailPanelToggle';
import ColumnTSU from '../ColumnTSU';
import TableAvatar from '../TableAvatar';

// Formatea un número con comas
export function formatearNumeroConComas(numero) {
	if (typeof numero !== 'number') {
		return 0;
	}

	const numeroFormateado = numero.toLocaleString('en-US', {
		maximumFractionDigits: 2,
	});
	return numeroFormateado;
}

// Campos principales
const mainFields = [
	{ id: 'thumbnail', label: 'Foto' },
	{ id: 'sku', label: 'SKU' },
	{ id: 'co', label: 'CO' },
	{ id: 'label', label: 'Ciclo' },
];

// Cuartos
export const q1Months = [1, 2, 3];
export const q2Months = [4, 5, 6];
export const q3Months = [7, 8, 9];
export const q4Months = [10, 11, 12];

// Campos de la tabla
export const fields = [
	{ id: 'pvp', label: 'PVP' },
	{ id: 'tsu', label: 'TSU' },
	{ id: 'mg', label: 'MG%' },
	{ id: 'gmv_local', label: 'GMV Local' },
	{ id: 'gmv_usd', label: 'GMV USD' },
	{ id: 'profit', label: 'Profit' },
	{ id: 'cbm', label: 'CBM' },
];

// Eventos de cada mes
export const events = {
	1: [],
	2: [],
	3: [
		{
			name: 'Cyber Fest',
			season: 't1',
		},
	],
	4: [
		{
			name: 'Semana Santa',
			season: 't2',
		},
	],
	5: [
		{
			name: 'Hot Sale',
			season: 't1',
		},
	],
	6: [
		{
			name: 'DDP',
			season: 't2',
		},
	],
	7: [],
	8: [
		{
			name: 'DDN',
			season: 't2',
		},
	],
	9: [],
	10: [
		{
			name: 'DDM',
			season: 't2',
		},
	],
	11: [
		{
			name: 'Cyber Monday',
			season: 't1',
		},
		{
			name: 'Black Friday',
			season: 't1',
		},
	],
	12: [
		{
			name: 'Navidad',
			season: 't1',
		},
	],
};

// Obtiene el nombre del mes
export const getMonthName = (month) => {
	switch (month) {
		case 1:
			return 'Enero';
		case 2:
			return 'Febrero';
		case 3:
			return 'Marzo';
		case 4:
			return 'Abril';
		case 5:
			return 'Mayo';
		case 6:
			return 'Junio';
		case 7:
			return 'Julio';
		case 8:
			return 'Agosto';
		case 9:
			return 'Septiembre';
		case 10:
			return 'Octubre';
		case 11:
			return 'Noviembre';
		case 12:
			return 'Diciembre';
		default:
			return 'Enero';
	}
};

// Obtiene el quarto al que pertenece el mes
export const getQuarterMonth = (month) => {
	if (q1Months.includes(month)) return 'q1';
	if (q2Months.includes(month)) return 'q2';
	if (q3Months.includes(month)) return 'q3';
	if (q4Months.includes(month)) return 'q4';

	return 'q1';
};

// Obtiene el id de la fila de la tabla
export const getRowId = (row) => row.product_id;

// Obtiene la definición de las columnas agrupadas de la tabla de cada mes
const getColumnGroupDefinition = (month, year, columns, data, filteredData) => {
	const filteredFields = columns
		? fields.filter((field) => columns[field.id])
		: fields;

	return {
		groupId: `${month}`,
		children: filteredFields.map((field) => ({
			field: `${field.id}-${month}-${year}`,
		})),
		headerClassName: 'group-header-special',
		renderHeaderGroup: () => {
			return (
				<MonthColumnHeader
					month={month}
					dataLength={data.data.length}
					headers={data.headers[month]}
					filteredData={filteredData}
					events={events[month]}
				/>
			);
		},
	};
};

// Obtiene las columnas agrupadas de la tabla de cada mes filtradas por quarto
export const getColumnGroups = (year, quarter, columns, data, filteredData) => {
	const q1 = q1Months.map((month) =>
		getColumnGroupDefinition(month, year, columns, data, filteredData)
	);
	const q2 = q2Months.map((month) =>
		getColumnGroupDefinition(month, year, columns, data, filteredData)
	);
	const q3 = q3Months.map((month) =>
		getColumnGroupDefinition(month, year, columns, data, filteredData)
	);
	const q4 = q4Months.map((month) =>
		getColumnGroupDefinition(month, year, columns, data, filteredData)
	);

	if (quarter === 'q1') {
		return q1;
	}
	if (quarter === 'q2') {
		return q2;
	}
	if (quarter === 'q3') {
		return q3;
	}
	if (quarter === 'q4') {
		return q4;
	}

	return [...q1, ...q2, ...q3, ...q4];
};

// Obtiene la definición de las columnas de la tabla de cada mes
const getColumnDefinition = (field, month, year, width, index) => ({
	field: `${field.id}-${month}-${year}`,
	headerName: field.label,
	headerClassName: `${
		field.id === 'gmv_local' || field.id === 'gmv_usd' ? 'header-special' : ''
	} ${index === 0 ? 'border-left' : ''} border-bottom`,
	cellClassName: `${
		field.id === 'gmv_local' || field.id === 'gmv_usd' ? 'cell-special' : ''
	} ${index === 0 ? 'border-left' : ''}`,
	renderHeader:
		field.id === 'gmv_local' || field.id === 'gmv_usd'
			? () => {
					return (
						<Stack
							spacing="4px"
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								width: '100%',
							}}
						>
							<Typography
								sx={{
									fontWeight: 500,
								}}
							>
								GMV
							</Typography>
							<Typography
								sx={{
									fontWeight: 500,
								}}
							>
								{field.id === 'gmv_local' ? 'Local' : 'USD'}
							</Typography>
						</Stack>
					);
			  }
			: undefined,
	renderCell:
		field.id === 'tsu'
			? (params) => <ColumnTSU {...params} month={month} />
			: undefined,
	width,
	resizable: false,
	sortable: false,
	headerAlign: 'center',
	align: 'center',
	valueGetter: (value, row) => {
		if (!row?.data[month]) return 0;
		return row?.data[month]?.reduce((acc, curr) => {
			const fieldValue = curr?.apro
				? curr?.apro[field.id]
				: curr?.def[field.id];
			return acc + fieldValue;
		}, 0);
	},
	valueFormatter: (value) => {
		if (['pvp', 'gmv_local', 'gmv_usd', 'profit'].includes(field?.id)) {
			return `$${formatearNumeroConComas(value)}`;
		}
		if (['mg'].includes(field.id)) {
			return `${formatearNumeroConComas(value)}%`;
		}

		return formatearNumeroConComas(value);
	},
});

// Obtiene las columnas de la tabla de cada mes filtradas por quarto
export const getColumns = (year, quarter, columns) => {
	const filteredFields = columns
		? fields.filter((field) => columns[field.id])
		: fields;

	const width = 560 / filteredFields.length;

	if (quarter === 'q1') {
		const q1 = q1Months.map((month) =>
			filteredFields.map((field, index) =>
				getColumnDefinition(field, month, year, width, index)
			)
		);
		return q1;
	}
	if (quarter === 'q2') {
		const q2 = q2Months.map((month) =>
			filteredFields.map((field, index) =>
				getColumnDefinition(field, month, year, width, index)
			)
		);
		return q2;
	}
	if (quarter === 'q3') {
		const q3 = q3Months.map((month) =>
			filteredFields.map((field, index) =>
				getColumnDefinition(field, month, year, width, index)
			)
		);
		return q3;
	}
	if (quarter === 'q4') {
		const q4 = q4Months.map((month) =>
			filteredFields.map((field, index) =>
				getColumnDefinition(field, month, year, width, index)
			)
		);
		return q4;
	}

	const q1 = q1Months.map((month) =>
		filteredFields.map((field, index) =>
			getColumnDefinition(field, month, year, width, index)
		)
	);
	const q2 = q2Months.map((month) =>
		filteredFields.map((field, index) =>
			getColumnDefinition(field, month, year, width, index)
		)
	);
	const q3 = q3Months.map((month) =>
		filteredFields.map((field, index) =>
			getColumnDefinition(field, month, year, width, index)
		)
	);
	const q4 = q4Months.map((month) =>
		filteredFields.map((field, index) =>
			getColumnDefinition(field, month, year, width, index)
		)
	);

	return [...q1, ...q2, ...q3, ...q4];
};

// Obtiene las columnas principales de la tabla
const getMainColumns = (columnsFiltered, width) =>
	[
		{
			field: 'thumbnail',
			headerName: 'Foto',
			headerClassName: 'border-bottom',
			cellClassName: (params) =>
				`${
					Object.values(params?.row?.data).some((monthRow) =>
						monthRow.some((marketplaceRow) => marketplaceRow?.pend)
					)
						? 'cell-pend'
						: ''
				}`,
			renderCell: (params) => (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<TableAvatar
						imageUrl={params?.row?.skuData?.skuAndOwnerData?.sku_imagen_maestro}
						name="N D"
					/>
				</Box>
			),
			width,
			resizable: false,
			sortable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'sku',
			headerName: 'SKU',
			headerClassName: 'border-bottom',
			cellClassName: (params) =>
				`${
					Object.values(params?.row?.data).some((monthRow) =>
						monthRow.some((marketplaceRow) => marketplaceRow?.pend)
					)
						? 'cell-pend'
						: ''
				}`,
			width: Math.max(90, width),

			resizable: false,
			headerAlign: 'center',
			align: 'center',
			valueGetter: (value, row) => row.skuData?.skuAndOwnerData?.sku_maestro,
		},
		{
			field: 'co',
			headerName: 'CO',
			headerClassName: 'border-bottom',
			cellClassName: (params) =>
				`${
					Object.values(params?.row?.data).some((monthRow) =>
						monthRow.some((marketplaceRow) => marketplaceRow?.pend)
					)
						? 'cell-pend'
						: ''
				}`,
			renderCell: (params) => (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<TableAvatar
						imageUrl={
							params?.row?.skuData?.skuAndOwnerData?.owner_imagen_maestro
						}
						name={params?.row?.skuData?.skuAndOwnerData?.user_name_maestro}
					/>
				</Box>
			),
			width,
			resizable: false,
			sortable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'label',
			headerName: 'Ciclo',
			headerClassName: 'border-right border-bottom',
			cellClassName: (params) =>
				`${
					Object.values(params?.row?.data).some((monthRow) =>
						monthRow.some((marketplaceRow) => marketplaceRow?.pend)
					)
						? 'cell-pend'
						: ''
				} border-right`,
			width,
			renderCell: (params) => (
				<Box
					sx={{
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<LabelTag label={params?.value} />
				</Box>
			),
			resizable: false,
			sortable: false,

			headerAlign: 'center',
			align: 'center',
		},
	].filter((column) => columnsFiltered[column.field]);

// Obtiene las columnas y columnas agrupadas de la tabla dependiendo de los filtros aplicados
export const getTableData = (
	data,
	filteredData,
	quarter,
	year,
	columnsFiltered
) => {
	// filtra las columnas a mostrar
	const filteredFields = columnsFiltered
		? mainFields.filter((field) => columnsFiltered[field.id])
		: mainFields;

	// Calcula el ancho de las columnas para que se ajusten al tamaño de la cabecera de mes
	const width =
		filteredFields.length < 4
			? 270 / filteredFields.length
			: 180 / (filteredFields.length - 1);

	// Obtiene las columnas principales
	const columns = _.flatten([
		{
			...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
			headerClassName: 'border-bottom',
			cellClassName: (params) =>
				`${
					Object.values(params.row.data).some((monthRow) =>
						monthRow.some((marketplaceRow) => marketplaceRow.pend)
					)
						? 'cell-pend'
						: ''
				} no-padding`,
			renderCell: (params) => (
				<ForecastCustomDetailPanelToggle
					id={params.id}
					value={params.value}
					row={params.row}
				/>
			),
			width: 55,
			resizable: false,
			headerAlign: 'center',
			align: 'center',
		},
		...getMainColumns(columnsFiltered, width),
		...getColumns(year.value, quarter, columnsFiltered),
	]);

	// Obtiene las columnas agrupadas
	const columnGroup = [
		{
			groupId: `Forecast ${year.value}`,
			headerClassName: 'group-header-special',
			renderHeaderGroup: () => {
				return <MainColumnHeader year={year} headers={data.headers} />;
			},
			children: [
				{
					field: '__detail_panel_toggle__',
				},
				{
					field: 'thumbnail',
				},
				{
					field: 'sku',
				},
				{
					field: 'co',
				},
				{
					field: 'label',
				},
			],
		},
		...getColumnGroups(
			year.value,
			quarter,
			columnsFiltered,
			data,
			filteredData
		),
	];

	return { columns, columnGroup };
};

// // Transforma la data de la API en un formato que facilite la creación de la tabla
// export function generateTableData(data) {
// 	const tableData = {};

// 	// Unifica la data por producto y mes
// 	for (const monthCard of data.monthCards) {
// 		const cabecera = monthCard.cabecera;
// 		for (const forecastSkuDet of monthCard.forecastSkuDet) {
// 			const product_id = forecastSkuDet.product_id;

// 			const skuData = forecastSkuDet.skumktp.map((sku) => ({
// 				product_id,
// 				...sku,
// 				def: {
// 					...sku.def,
// 					gmv_local: sku.def?.tsu * sku.def?.pvp * cabecera.tc_local,
// 					gmv_usd: sku.def?.tsu * sku.def?.pvp,
// 					tc: cabecera.tc_local,
// 				},
// 				apro: sku.apro
// 					? {
// 							...sku.apro,
// 							gmv_local: sku.apro.tsu * sku.apro.pvp * cabecera.tc_local,
// 							gmv_usd: sku.apro.tsu * sku.apro.pvp,
// 							tc: cabecera.tc_local,
// 					  }
// 					: null,
// 				reject: sku.reject
// 					? {
// 							...sku.reject,
// 							gmv_local: sku.reject.tsu * sku.reject.pvp * cabecera.tc_local,
// 							gmv_usd: sku.reject.tsu * sku.reject.pvp,
// 							tc: cabecera.tc_local,
// 					  }
// 					: null,
// 				pend: sku.pend
// 					? {
// 							...sku.pend,
// 							gmv_local: sku.pend.tsu * sku.pend.pvp * cabecera.tc_local,
// 							gmv_usd: sku.pend.tsu * sku.pend.pvp,
// 							tc: cabecera.tc_local,
// 					  }
// 					: null,
// 				aproprev: sku.aproprev
// 					? {
// 							...sku.aproprev,
// 							gmv_local:
// 								sku.aproprev.tsu * sku.aproprev.pvp * cabecera.tc_local,
// 							gmv_usd: sku.aproprev.tsu * sku.aproprev.pvp,
// 							tc: cabecera.tc_local,
// 					  }
// 					: null,
// 			}));

// 			tableData[product_id] =
// 				tableData[product_id] === undefined
// 					? { [monthCard.cabecera.mes]: skuData }
// 					: { ...tableData[product_id], [monthCard.cabecera.mes]: skuData };
// 		}
// 	}

// 	const tableRows = [];

// 	// Agrupa la data del producto y la data de forecast por meses
// 	for (const key in tableData) {
// 		tableRows.push({
// 			product_id: key,
// 			label: 'npi',
// 			skuData: data.skuOwnerInfo.find(
// 				(sku) =>
// 					sku?.skuAndOwnerData.product_id_maestro === Number.parseInt(key)
// 			),
// 			data: tableData[key],
// 		});
// 	}

// 	const tableHeaders = {};

// 	// Obtiene las cabeceras de los meses
// 	for (const monthCard of data.monthCards) {
// 		tableHeaders[monthCard.cabecera.mes] = monthCard.cabecera;
// 	}

// 	return { data: tableRows, headers: tableHeaders };
// }

// Transforma la data de la API en un formato que facilite la creación de la tabla
export function generateTableData(data) {
	const tableData = generateYearData(data[0], data[1], data[2]);

	return tableData;
}

const generateYearData = (prev, current, next) => {
	const tableData = {};

	// Unifica la data por producto y mes

	for (const [index, monthCard] of current.monthCards.entries()) {
		const totals = monthCard.forecastTotals;
		for (const [
			skuIndex,
			forecastSkuDet,
		] of monthCard.forecastSkuDet.entries()) {
			const product_id = forecastSkuDet.product_id;

			const skuData = forecastSkuDet.skumktp.map((sku, i) => ({
				product_id,
				...sku,
				def: {
					...sku.def,
					gmv_local: sku.def?.tsu * sku.def?.pvp * totals.tc_local,
					gmv_usd: sku.def?.tsu * sku.def?.pvp,
					tc: totals.tc_local,
				},
				apro: sku.apro
					? {
							...sku.apro,
							gmv_local: sku.apro.tsu * sku.apro.pvp * totals.tc_local,
							gmv_usd: sku.apro.tsu * sku.apro.pvp,
							tc: totals.tc_local,
					  }
					: null,
				reject: sku.reject
					? {
							...sku.reject,
							gmv_local: sku.reject.tsu * sku.reject.pvp * totals.tc_local,
							gmv_usd: sku.reject.tsu * sku.reject.pvp,
							tc: totals.tc_local,
					  }
					: null,
				pend: sku.pend
					? {
							...sku.pend,
							gmv_local: sku.pend.tsu * sku.pend.pvp * totals.tc_local,
							gmv_usd: sku.pend.tsu * sku.pend.pvp,
							tc: totals.tc_local,
					  }
					: null,
				aproprev:
					prev.monthCards[index].forecastSkuDet[skuIndex].skumktp[i].apro ??
					null,
				apronext:
					next.monthCards[index].forecastSkuDet[skuIndex].skumktp[i].apro ??
					null,
			}));

			tableData[product_id] =
				tableData[product_id] === undefined
					? { [index + 1]: skuData }
					: { ...tableData[product_id], [index + 1]: skuData };
		}
	}

	const tableRows = [];

	// Agrupa la data del producto y la data de forecast por meses
	for (const key in tableData) {
		const product_id = Number.parseInt(key);
		tableRows.push({
			product_id,
			label: 'npi',
			skuData: current.skuOwnerInfo.find(
				(sku) => sku?.skuAndOwnerData.product_id_maestro === product_id
			),
			data: tableData[key],
		});
	}

	const tableHeaders = {};

	// Obtiene las cabeceras de los meses

	for (const [index, monthCard] of current.monthCards.entries()) {
		tableHeaders[index + 1] = monthCard.forecastTotals;
	}

	return { data: tableRows, headers: tableHeaders };
};
