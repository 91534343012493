// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { PresupuestoHelper } from 'helpers/Costos/PresupuestoHelper';

// ----------------------------------------------------------------------
const baseUrl = process.env.REACT_APP_BASE_URL;
const rutaTabla = 'Presupuesto';

const initialState = {
    error: null,
    orders: [],

    //hay que declarar el estado inicial
    presupuestos: [],
    presupuestosAmount: [],
    presupuestosAmountDate: [],
};

const slice = createSlice({
    name: 'presupuesto',  //como se llama al metodo para luego ingresar a la respuesta ej inbound.inbound
    initialState,
    reducers: {

        // GET presupuestos
        getPresupuestoSuccess(state, action) {
            state.presupuestos = action.payload;
        },

        // GET presupuestos cantidad
        getPresupuestoAmountSuccess(state, action) {
            state.presupuestosAmount = action.payload;
        },
        
        // GET presupuestos cantidad
        getPresupuestoAmountDateSuccess(state, action) {
            state.presupuestosAmountDate = action.payload;
        },
        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getPresupuestos(accessToken) {
    return async (dispatch) => {
        try {
            // const response = await PresupuestoHelper.fetchData(accessToken); 
            const response = await axios.get(`${baseUrl}/${rutaTabla}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Incluye el token en la cabecera de la solicitud.
                },
              });
            dispatch(slice.actions.getPresupuestoSuccess(response.data));
            // console.log('Get Presupuestos :', response.data);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getPresupuestosAmount(accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.amountDataFetch(accessToken); 
            dispatch(slice.actions.getPresupuestoAmountSuccess(response));
            // console.log('Get Presupuestos Amount:', response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getPresupuestosAmountDate(accessToken) {
    return async (dispatch) => {
        try {
            const response = await PresupuestoHelper.AmountDate(accessToken); 
            dispatch(slice.actions.getPresupuestoAmountDateSuccess(response));
            console.log('Get Presupuestos AmountDate:');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getOrders() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/inbound/order/list');
            dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

