import { createContext, useState, useContext } from 'react';

const EventsFilterContext = createContext(null);

export default function EventsFiltersProvider({ children }) {
	const [dateRange, setDateRange] = useState([null, null]);
	const [search, setSearch] = useState('');

	return (
		<EventsFilterContext.Provider
			value={{
				dateRange,
				setDateRange,
				search,
				setSearch,
			}}
		>
			{children}
		</EventsFilterContext.Provider>
	);
}

export const useEventsFilters = () => {
	const context = useContext(EventsFilterContext);
	if (context === undefined) {
		throw new Error(
			'useEventsFilters must be used within a EventsFiltersProvider'
		);
	}
	return context;
};
