import { Stack, Typography } from '@mui/material';
import CreateCampaign from './CreateCampaign';
import SearchBar from './SearchBar';
import DateRangeFilter from './DateRangeFilter';

export default function Toolbar({ campaignsQuantity }) {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			flexWrap="wrap"
			gap={2}
		>
			<Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
				<Typography variant="body2" component="p">
					Total de campañas creadas: {campaignsQuantity}
				</Typography>

				<SearchBar />

				<DateRangeFilter />
			</Stack>

			<CreateCampaign />
		</Stack>
	);
}
