import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useForecastHistoryFilters } from 'contexts/ForecastHistoryFiltersContext';

export default function HistorySearchBar() {
	const { setSearch, setPaginationModel } = useForecastHistoryFilters();
	const [searchField, setSearchField] = useState('');
	const debouncedSearchField = useDebounce(searchField, 500);

	useEffect(() => {
		setSearch(debouncedSearchField);
		setPaginationModel((prev) => ({ ...prev, page: 0 }));
	}, [debouncedSearchField, setSearch, setPaginationModel]);

	return (
		<TextField
			placeholder="SKU"
			label="Buscar"
			value={searchField}
			onChange={(e) => setSearchField(e.target.value)}
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					borderRadius: '5px',

					border: '1px solid #0000003B',
				},
				'& .MuiInputBase-input': {
					backgroundColor: '#FFFFFF',
				},
				'& .MuiInputBase-root': {
					backgroundColor: '#FFFFFF',
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" />
					</InputAdornment>
				),
			}}
		/>
	);
}
