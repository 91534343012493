import {
	createContext,
	useState,
	useContext,
	useEffect,
	useCallback,
} from 'react';
import { events } from 'views/Components/Settings/Events/utils';

const EventsContext = createContext();

export default function EventsProvider({ children }) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchEventsTableData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await new Promise((resolve) => {
				setTimeout(() => {
					resolve({ data: events });
				}, 1000);
			});

			console.log('res', res);

			setData(res.data);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchEventsTableData();
	}, [fetchEventsTableData]);

	return (
		<EventsContext.Provider
			value={{
				data,
				loading,
				error,
				fetchEventsTableData,
			}}
		>
			{children}
		</EventsContext.Provider>
	);
}

export const useEvents = () => {
	const context = useContext(EventsContext);
	if (context === undefined) {
		throw new Error('useEvents must be used within a EventsProvider');
	}
	return context;
};
