import { Button } from '@mui/material';
import CreateEditCampaignModal from './CreateEditCampaignModal';
import { useState } from 'react';

export default function CreateCampaign() {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleOpen} variant="contained">
				Crear Campaña
			</Button>
			<CreateEditCampaignModal open={open} onClose={handleClose} />
		</>
	);
}
