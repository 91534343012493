// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
//implementados COSTOS
import presupuestoReducer from './slices/presupuestoFetch';
import inboundReducer from './slices/InboundFetch';
import sourcingReducer from './slices/sourcingFetch';
import utilidadesReducer from './slices/utilidadesFetch';
import virtualSellerReducer from './slices/VirtualSellerFetch';
import productosReducer from './slices/Productos';
import permisosReducer from './slices/Permisos';
import tarifasFetch from './slices/tarifasfetch';
import CargaLogica from './slices/CargaLogica';
import DatosFormulario from './slices/DatosFormulario';

//implementados P&P
import PandPreducer from './slices/picking_and_packing';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    //implementados
    presupuesto: presupuestoReducer,
    inbound: inboundReducer,
    sourcing: sourcingReducer,
    utilidades: utilidadesReducer,
    virtualSeller: virtualSellerReducer,
    productos: productosReducer,
    permisos: permisosReducer,
    tarifas: tarifasFetch,
    carga: CargaLogica,
    DatosForm: DatosFormulario,
    pickingAndPaking: PandPreducer,
    
});

export default reducer;
