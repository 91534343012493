import {
	Box,
	ButtonBase,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from 'react';

export default function CategoriesDrawer({ categories }) {
	const [open, setOpen] = useState(false);

	console.log('categories', categories);

	return (
		<>
			<ButtonBase
				onClick={() => setOpen(true)}
				disabled={
					categories.length === 1 && categories[0] === 'Todos los productos'
				}
			>
				<VisibilityIcon
					sx={{
						color:
							categories.length === 1 && categories[0] === 'Todos los productos'
								? '#6c6c6c'
								: '#354887',
					}}
				/>
			</ButtonBase>
			<Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
				<Stack
					sx={{
						width: '250px',
						backgroundColor: '#eef2f6',
						height: '100%',
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						sx={{
							padding: '10px',
							paddingTop: '30px',
							backgroundColor: '#fff',
						}}
					>
						<ButtonBase onClick={() => setOpen(false)}>
							<ArrowBackIosNewIcon
								sx={{
									width: '15px',
									height: '15px',
								}}
							/>
						</ButtonBase>
						<Typography
							variant="h2"
							sx={{
								fontSize: '20px',
								fontWeight: 'bold',
							}}
						>
							Categorías
						</Typography>
					</Stack>

					<Box
						sx={{
							padding: '10px',
						}}
					>
						<List
							sx={{
								backgroundColor: '#fff',
								borderRadius: '5px',
							}}
						>
							{categories.map((category) => (
								<ListItem key={category}>
									<ListItemText primary={category} />
								</ListItem>
							))}
						</List>
					</Box>
				</Stack>
			</Drawer>
		</>
	);
}
